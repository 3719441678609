import { Feature } from "ol";
import { FileModel } from "../../file/file.model";
import { Annotation } from "../../file/sidebar/annotations/annotation.model";

export class Inspection {
  _id?: string;
  orderId: string;
  temperatures?: {
    min?: number;
    max?: number;
    avg?: number;
    delta?: number;
  };
  relatedMedia?: {
    fileId?: string;
    file?: FileModel;
    geometry?: {
      type: string;
      coordinates: any;
    };
  }[];
  annotations?: Annotation[];
}

export class Location {
  panelNumber?: string;
  arrayId?: string;
  stringNumber?: string;
  geometry?: {
    type?: string;
    coordinates?: any;
  };
  tiltAngle?: number;
  azimuthAngle?: number;
  shadingInfo?: 'partial' | 'full' | 'none';
}

export class Identification {
  serialNumber?: string;
  fId?: string;
  moduleType?: string;
  manufacturer?: string;
  modelNumber?: string;
  installationDate?: Date;
  removalDate?: Date | null;
  warrantyExpiryDate?: Date;
}

export class PVModule {
  _id?: string;
  siteId?: string;
  isOperational?: boolean;
  identification?: Identification;
  location?: Location;
  inspections?: Inspection[];
  precedentModuleId?: string | null;
  modifiedAt?: Date;
  createdAt?: Date;

  // Computed field
  _feature: Feature
}

export const THERMAL_PALETTES = [
  { icon:'Rainbow1', label: 'Rainbow HC', value: ['#0000FF', '#00FFFF', '#00FF00', '#FFFF00', '#FF8000', '#FF0000', '#800000'] }, // High contrast rainbow
  { icon:'blackHot', label: 'Black Hot', value: ['#000000', '#222222', '#555555', '#AAAAAA', '#FFFFFF'] }, // Black for cold, white for hot
  { icon:'', label: 'Outdoor Alert', value: ['#000000', '#550000', '#FF0000', '#FFA500', '#FFFF00', '#FFFFFF'] }, // Fire and emergency detection
  { icon:'ironRed', label: 'Ironbow', value: ['#000000', '#32006E', '#6600CC', '#FF1493', '#FF4500', '#FFA500', '#FFFF00'] }, // FLIR-like iron heatmap
  { icon:'arctic', label: 'Arctic', value: ['#000080', '#0000FF', '#00FFFF', '#FFFFFF'] }, // Cold spectrum from blue to white
  { icon:'whiteHot', label: 'White Hot', value: ['#000000', '#555555', '#AAAAAA', '#FFFFFF'] }, // Warmer objects in white, cooler in black
  { icon:'', label: 'Sepia', value: ['#704214', '#A0522D', '#C08060', '#FFDAB9'] }, // Warm golden hues for reduced eye strain
  { icon:'', label: 'Red Hot', value: ['#000000', '#550000', '#AA0000', '#FF0000', '#FF5555'] }, // Highlights hottest areas in red tones
  { icon:'', label: 'Fire and Ice', value: ['#0000FF', '#00FFFF', '#FFFFFF', '#FF4500', '#FF0000'] }, // Contrasts hot and cold regions
  { icon:'', label: 'Violet', value: ['#2E0854', '#4B0082', '#8A2BE2', '#D8BFD8'] }, // Deep purples indicating varying heat levels
  { icon:'', label: 'Ultramarine', value: ['#000080', '#0000CD', '#4169E1', '#87CEFA'] }, // Shades of blue for cooler temperature visualization
];

