import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Order } from "../../shared/orders/orders.model";
import { Column} from "../../shared/interfaces/column.interface";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { statusGroups, OrdersService, OrderGroup } from "../../shared/orders/orders.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatSort} from "@angular/material/sort";
import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/overlay";
import { ActiveHeader} from "../../shared/header/header.interface";
import { BehaviorSubject, Subject, throwError, Observable } from "rxjs";
import { FilesService } from '../files/files.service';
import { LoginStateService } from '../login/login-state.service';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { AccountType, User } from '../users/models/user.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements AfterViewInit, OnDestroy, ActiveHeader, OnInit {

  @Output() reloadEvent = new EventEmitter<void>();
  @Output() filterEvent = new EventEmitter<void>();
  @Output() nextPage = new EventEmitter<void>();
  @ViewChild('table') table: MatTable<Element>;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  columns: Array<Column> = [
    { label: 'ID', value: '_id' },
    { label: 'NAME', value: 'name' },
  ];
  selectedId?: number;
  contextMenuPosition = { x: '0px', y: '0px' };
  stats: {[_: string]: number} = {};
  groupedStats = statusGroups;
  filterStatus?: OrderGroup;
  @ViewChildren(CdkScrollable) cdkScrollable;
  showNewOrderButton = true;
  ngDestroy$ = new Subject();
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject(null);
  searchAutocomplete = [
    { icon: 'shop', label: 'ORDER.ID', value: 'id:' },
    { icon: 'location_on', label: 'ADDRESS', value: 'address:' },
    { icon: 'description', label: 'ORDER.PROJECT_NAME', value: 'name:' },
  ];
  theme: string;
  loggedUser: User;
  selectedStatusGroup: string = '';
  accountType = AccountType

  public query: string = '';

  public fetching = true

  public isSmall: boolean = false;
  public isXSmall: boolean = false;
  
  // Table att
  public currentCursor = 0;
  public displayedColumns = [];
  public totalItems = 0;
  public allData = new MatTableDataSource<Order & {selected: boolean}>([]);
  public sorting: { [key: string]: number } = { legacyId: -1 };
  
  constructor(
    public permissionsService: PermissionsService,
    public loginStateService: LoginStateService,
    public ordersService: OrdersService,
    private filesService: FilesService,
    private dialogService: DialogService,
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
           this.selectedId = Number(this.route.snapshot.root.children[1]?.params["orderID"]);
        }
    });
    
    this.themeService.changed$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(theme => {
        this.theme = theme;
    });

    this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium
    ])
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
        this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
        this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);
        this.updateDisplayedColumns();
    })
  }

  ngOnInit(): void {
    this.searchQuery$
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.ngDestroy$)
    )
    .subscribe(query => {
      if (query) {
        this.query = query;
        this.fetchFirstPage();
      }
    });

    this.sort.sortChange
    .pipe(
      tap((sortEvent) => {
        this.currentCursor = 0;
        this.allData.data = [];
        this.sorting = { [sortEvent.active]: sortEvent.direction === 'asc' ? 1 : -1 };
        this.cdr.detectChanges();
      }),
      takeUntil(this.ngDestroy$)
    )
    .subscribe(() => {
      this.fetchFirstPage();
    });

    this.filterEvent.pipe(takeUntil(this.ngDestroy$)).
    subscribe(() => {
      //this.loadNextPage();
    });

    this.nextPage.pipe(
    takeUntil(this.ngDestroy$)).subscribe(() => {
      this.loadNextPage();
    }); 
  }

  ngAfterViewInit(): void {
    setTimeout(() => { // TODO: This is a temp solution because when you login it takes sometime to fetch the info of the user 
      this.loginStateService.loggedUser$
      .pipe(take(1))
      .subscribe((loggedUser) => {
        if (loggedUser) {
          this.loggedUser = loggedUser
          this.setTableColumns(loggedUser.accountType)
          this.fetchFirstPage();
        }
      });
    }, 500);

    this.scrollDispatcher.scrolled()
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((scrollable: CdkScrollable) => {
      const bottom = scrollable ? scrollable.measureScrollOffset('bottom') : null;
      this.ngZone.run(() => {   // Fix https://github.com/angular/components/pull/8545
        if (this.totalItems > this.currentCursor) // If we still have items to load
          {
            this.scrolled(bottom);  // Run this in `NgZone.run`, or the table will not renderRows
          }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  private setTableColumns(accountType: AccountType) {
    if (accountType === AccountType.PILOT) {
      this.displayedColumns = ['legacyId','role', 'address', 'status'];
      if (this.isXSmall) {
        this.displayedColumns = ['legacyId', 'address', 'status'];
      }
    } else if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(accountType)) {
      this.displayedColumns = ['legacyId', 'expirationDate', 'projectName', 'address', 'status'];

      if (this.isXSmall) {
        this.displayedColumns = ['legacyId', 'address', 'status'];
      }
      if (this.isSmall) {
        this.displayedColumns = ['legacyId','expirationDate', 'address', 'status'];
      }
    } else {
      this.displayedColumns = ['legacyId', 'projectName', 'address', 'role', 'expirationDate', 'status'];

      if (this.isXSmall) {
        this.displayedColumns = ['projectName', 'address', 'status'];
      }
      if (this.isSmall) {
        this.displayedColumns = ['projectName', 'address', 'role', 'expirationDate', 'status'];
      }
    }
  }

  private loadNextPage() {
    if (this.currentCursor >= this.totalItems) return;
    const { filter, ownership } = this.constructFilter();
    this.ordersService.findManyOrders(filter, this.sorting, ownership, this.query, this.currentCursor, 50)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((resp: any) => {
        const newOrders = (resp.data.orders ?? []).map(o => ({ ...o, selected: false }));
        this.allData.data = [...this.allData.data, ...newOrders];
        this.updatePaginationParams(resp.pagination);
      });
  }

  scrolled(bottom?: number): void {    
    if (bottom !== null && bottom === 0) {
      this.loadNextPage();
    }
  }

  selectedRow(row) {
    console.log('selectedRow', row)
  }
    
  private emitNextPageEvent(): void {
    this.nextPage.emit();
  }

  public getOrderStatusLabel(row): string {
    if (this.ordersService.getOrderStatus(row.status) === 'done' && this.loggedUser?.accountType !== AccountType.PILOT) {
      return 'ORDERS.RESULTS';
    } else {
        return 'ORDERS.GROUP_' + this.ordersService.getOrderStatus(row.status).toUpperCase();
    }
  }

  disabled(row: any): boolean {
    return this.loginStateService.loggedUser$.value?.accountType === AccountType.PILOT && row.status !== 'inProgress';
  }

  private navigateToFolder(folderID: string): void {
    this.router.navigate([{
      outlets: {
        primary: ['files', folderID],
        detail: ['file', folderID]
      }
    }]);
  }

  public handleLinkClick(row: any, event: MouseEvent): void {
    event.preventDefault();  // Prevent the navigation
    event.stopImmediatePropagation(); // Stop further event handling

    if(this.permissionsService.permissions$.value?.canRead.files) { // Check if you have permissions to read files in general
      // Search for main folder: if it exists, it means the user has access
      const folderName = 'FF'+row.legacyId
      const filter = {"name": folderName};
      this.filesService.findMany(filter).subscribe(folder => {
        if (folder.data.length > 0) { // Folder exists --> user has access to see it 
            this.navigateToFolder(folder.data[0]._id);
        } else { // Folder does not exist --> user has no access to see it (there is no chance that the folder is not created because it gets created once we create the order)
          if (row.role === 'PILOT') { // The user is  the assigned pilot
            if (row.status === 'notStarted' || row.status === 'onHold') { // The order is in progress
              this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'You still do not have access to this order yet.', null, null, true);
            } else {
              this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_MAIN_PILOT', null, null, true);
            }
            this.showDetails(row)
          } else if (row.role === 'SECONDARY_PILOT' || row.role === 'COORDINATOR' || row.role === 'VIEWER' || row.role === 'EDITOR') { // The user is a collaborator
            this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_COLLABORATOR', null, null, true);
            this.showDetails(row)
          } else if (row.role === 'OWNER') { // The user is the owner client
            this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_MAIN_CLIENT', null, null, true);
            this.showDetails(row)
          }
        }
      });
    } else {
      this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'You have no permissions to see the data', null, null, true);
      this.showDetails(row)
    }
  }

  public handleRowClick(row) {
    this.showDetails(row)
    if (this.disabled(row)) {
      this.dialogService.showDialog('Error', null, 'ORDER.NO_ACCESS_MAIN_PILOT', null, null, true);
    }
  }

  private updatePaginationParams(newPaginationParam) {
    this.currentCursor = this.currentCursor + newPaginationParam.pageSize
    if (this.totalItems !== newPaginationParam.totalItems) {
      this.totalItems = newPaginationParam.totalItems;
    }    
  }

  constructFilter(): { filter: any; ownership?: string } {
    let filter: any = {};
    let ownership: string | undefined = "all";
    const accountType = this.loggedUser?.accountType;

    if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(accountType)) {
      ownership = undefined;
    }
    if (this.selectedStatusGroup) {
      const statusMappings = {
        done: ['to_verify', 'done', 'invoiced', 'paid'],
        inProgress: ['looking_pilots', 'in_progress', 'fq_looking_pilots', 'inProgress'],
        notStarted: ['requested_job', 'lost', 'notStarted'],
        checking: ['footage_review', 'post_production', 'external_processing', 'review_post_production', 'checking'],
        onHold: ['on_hold']
      };
  
      if (statusMappings[this.selectedStatusGroup]) {
        filter.statuses = statusMappings[this.selectedStatusGroup];
      }
    } 
    return { filter, ownership };
  }

  private async fetchFirstPage() {
    const { filter, ownership } = this.constructFilter();
      this.ordersService.findManyOrders(filter, this.sorting, this.query, ownership, 0, 50)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((resp: any) => {
      this.fetching = false
      this.updatePaginationParams(resp.pagination)
      this.setStats(resp.data)
      this.handleResponse(resp.data);
    });
  }

  private setStats(resp): void {
  
    if (Object.keys(this.stats).length > 0) {
      return; 
    }
      this.stats = {};
    for (const statGroup of statusGroups) {
      this.stats[statGroup] = 0;
    }
  
    for (const stat of Object.keys(resp.stats)) {
      const statGroup = this.ordersService.getOrderGroup(stat);
      this.stats[statGroup] += resp.stats[stat];
    }
  }
  
  private handleResponse(resp: any) {
    this.allData.data = (resp.orders ?? []).map(o => ({ ...o, selected: false }));
    this.allData.sort = this.sort;
  } 

  public selectStatusGroupForFilter(orderGroup?: OrderGroup) {
    if (orderGroup === this.selectedStatusGroup) {
      this.selectedStatusGroup = '';
    } else {
      this.selectedStatusGroup = orderGroup;
    }
    this.fetchFirstPage();
  }
  
  public applyQuery(event?: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.searchQuery$.next(filterValue);
  }

  public showDetails(row: any) {
    this.ordersService.setActiveOrder(row)
    this.selectedId = row.legacyId;
    this.router.navigate(
      [{ outlets: { detail: ['order', row.legacyId] } }]
    );

    if (this.loggedUser.accountType !== AccountType.ADMIN && this.loggedUser.accountType !== AccountType.SUPERADMIN) {
      this.permissionsService.setPermissions(row.accessLevel? row.accessLevel: 'owner' );
    }
  }

  private updateDisplayedColumns(): void {
    if (this.isXSmall) {
      this.displayedColumns = ['projectName', 'address', 'status'];
    } else if (this.isSmall) {
      this.displayedColumns = ['legacyId', 'projectName', 'address', 'status']    } 
      else {
        this.displayedColumns = ['legacyId', 'projectName', 'address', 'role', 'expirationDate', 'status'];
      }
  }

  trackByIndex = i => i; // https://angular.io/guide/template-syntax#ngfor-with-trackby
}
