<mat-sidenav-container class="map-sidenav-container">
  <mat-sidenav-content>
    <app-ol-image-mini-viewer
      *ngIf="showImageViewer"
      [fileId]="selectedImageId"
      [position]="viewerPosition"
      (closeViewer)="closeImageViewer()">
    </app-ol-image-mini-viewer>
    <app-mark-annotation-dialog
      *ngIf="annotationFormActive"
      (toggleAnnotationForm)="toggleAnnotationForm()"
      (annotationCreated)="onAnnotationCreated($event)">
    </app-mark-annotation-dialog>

    <div class="vertical-toolbar">
      <div
        matTooltip="Measure temperature" matTooltipPosition="left"
        [class.selected]="comparisonModeActive"
        (click)="toggleComparisonMode()">
        <mat-icon svgIcon="comparison"></mat-icon>
      </div>
      <div
        matTooltip="Measure temperature" matTooltipPosition="left"
        [class.selected]="measuringTemperature"
        (click)="toggleThermalMeasurementFeature()">
        <mat-icon>thermostat</mat-icon>
      </div>
      <!-- <div
        matTooltip="Captured images" matTooltipPosition="left"
        [class.selected]="false">
        <mat-icon>photo_camera</mat-icon>
      </div>
      <div
        matTooltip="Measure Lengths" matTooltipPosition="left"
        [class.selected]="false">
        <mat-icon>ads_click</mat-icon>
      </div> -->
    </div>
    <div class="bottom-right-options">
      <div class="reset-div" (click)="openSidenav('layers')">
        <mat-icon>layers</mat-icon>
        <span>Layers</span>
      </div>
      <div class="reset-div" (click)="toggleThermalSettings()">
        <mat-icon>device_thermostat</mat-icon>
        <span>Thermal settings</span>
      </div>
    </div>
    <div class="top-right-options">
      <div class="reset-div">
        <mat-icon>search</mat-icon>
        <span>Quick analysis</span>
      </div>
      <div class="reset-div"  (click)="toggleFilterBox()">
        <mat-icon>filter_list</mat-icon>
      </div>
      <app-pv-filter
        *ngIf="showFilterBox"
        [dynamicFilters]="dynamicFilterOptions"
        [selectedValues]="selectedFilterValues"
        [currentLang]="currentLang"
        (filtersChanged)="onFiltersChanged($event)"
        (closeFilterBox)="showFilterBox = false">
      </app-pv-filter>

    </div>
    <div id="map" class="map-container"></div>
    <!-- Swipe Line for Map Comparison -->
    <div id="swipe-line" class="swipe-line">
      <button class="circle" mat-fab>
        <mat-icon svgIcon="comparison"></mat-icon>
      </button>
    </div>
  </mat-sidenav-content>

  <!-- Side Navs -->
  <mat-sidenav #dynamicSidenav mode="side" position="end" opened="false" class="feature-sidenav">
    <ng-container *ngIf="activeSidenav === 'modules'">
      <!-- Multiple Modules Details Content -->
      <app-sidenav-pv-modules-details
        [pvModules]="selectedPvModules"
        [currentLang]="currentLang"
        (closeSidenav)="unselectModules()"
        (toggleAnnotationForm)="toggleAnnotationForm()">
      </app-sidenav-pv-modules-details>
    </ng-container>

    <ng-container *ngIf="activeSidenav === 'module'">
      <!-- Module Details Content -->
      <app-sidenav-pv-module-details
        [pvModule]="selectedPvModules[0]"
        [currentLang]="currentLang"
        (closeSidenav)="unselectModule()"
        (toggleAnnotationForm)="toggleAnnotationForm()"
        (annotationDeleted)="onAnnotationDeleted()">
      </app-sidenav-pv-module-details>
    </ng-container>

    <ng-container *ngIf="activeSidenav === 'layers'">
      <!-- Layers Management Content -->
      <app-sidenav-map-layers-details
        [layers]="layers"
        [map]="map"
        [pvInspectionService]="pvInspectionService"
        [siteId]="ordersService.activeOrder$.value.siteId"
        (closeSidenavEvent)="closeSidenav()"
        (toggleUploadLayerDialogEvent)="toggleUploadLayerDialog()">
      </app-sidenav-map-layers-details>
    </ng-container>

    <ng-container *ngIf="activeSidenav === 'thermal'">
      <!-- Thermal Settings Content -->
      <app-sidenav-thermal-details
      [thermalMetaData]="thermalMetaData"
      [selectedThermalPalette]="selectedThermalPalette"
      (closeSidenavEvent)="closeSidenav()"
      (updateLayerEvent)="updateLayer($event)"
      (paletteChangeEvent)="onPaletteChange($event)">
    </app-sidenav-thermal-details>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav #imagesSidenav mode="side" position="start" opened="false" class="feature-sidenav">
    <div class="sidenav-header">
      <div class="left">
        <span class="title">Nearby Images</span>
      </div>
      <div class="right">
        <mat-icon (click)="imagesSidenav.close()">close</mat-icon>
      </div>
    </div>

    <div class="image-list">
      <div *ngFor="let image of nearbyImagesToPvModule" class="image-item"
            (mouseenter)="highlightImagePin(image._id)"
            (mouseleave)="restoreImagePin(image._id)">
        <div class="header">
            <span class="box">{{image.name | shortName }}</span>
            <span class="box">{{image.taken | date:'HH:mm:ss'}}</span>
        </div>
        <img src="{{image.thumbnailLink}}" alt="Image Thumbnail">
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
