import { Observable, Subscription } from 'rxjs';

import { FileModel } from './../../pages/files/file/file.model';

export interface CompletedPart {
  ETag: string;
  PartNumber: number;
}

export class QueueItem {
  abortMultipartUpload?: Observable<any>;
  elapsedTime?: number;
  estimatedTime?: number;
  fileModel?: FileModel;
  getURL?: string;
  icon: string;
  inProgress = false;
  key: string;
  label: string;
  name: string;
  progress: number;
  loaded?: number;
  putURL?: string;
  putURLs?: Array<string>; // Multipart
  request?: Observable<any>;
  size?: number;
  startTime?: number;
  stopped = false;
  subscription?: Subscription;
  uploadID?: string;
  progressPercentage?: number;

  private intervalId?: NodeJS.Timeout;

  constructor(
    key: string,
    label: string,
    icon: string,
    name: string,
    progress?: number
  ) {
    this.key = key;
    this.label = label;
    this.icon = icon;
    this.name = name
    this.progress = progress
  }

  percentDone(): number {
    if (this.progress){ // only for generating a PDF
      return this.progress * 100
    }
    return this.loaded && this.size ? Math.round(this.loaded * 100 / this.size) : 0;
  }

  startProgress(): void {
    this.inProgress = true;
    this.startTime = new Date().getTime();
    this.intervalId = setInterval(() => {
      this.elapsedTime = new Date().getTime() - this.startTime;
      if (this.loaded && this.size) {
        this.estimatedTime = this.elapsedTime * (this.size - this.loaded) / this.loaded;
      }
    }, 1000);
  }

  stopProgress(): void {
    this.inProgress = false;
    this.estimatedTime = null;
    clearInterval(this.intervalId);
  }

  stopUpload(): void {
    this.subscription?.unsubscribe();
    this.stopped = true;
    this.stopProgress();

    if (this.uploadID) { // When multipart upload is stopped
      const subscription = this.abortMultipartUpload
      .subscribe(() => {
        subscription.unsubscribe();
      });
    }
  }
}
