import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Circle, Fill, Stroke, Style, Icon, Text } from 'ol/style';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { SidenavNavigationService } from 'src/app/shared/sidenav/sidenav-navigation/sidenav-navigation.service';

import { Inspection, PVModule, THERMAL_PALETTES } from './model/pv-module.model';
import { FileModel } from '../file/file.model';
import { UploadLayerDialogComponent } from './components/upload-layer-dialog/upload-layer-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { PvModulesService } from './services/pv-modules.service';
import { hexToRgb } from './utils/helpers';
import { DEFAULT_STYLE } from './utils/pv-module-styles';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { Feature, Map } from 'ol';
import { forkJoin, of, Subject } from 'rxjs';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { catchError, debounceTime, skip, switchMap, takeUntil } from 'rxjs/operators';
import { Point, Polygon } from 'ol/geom';
import { PvInspectionOlmapService } from './services/pv-inspection-olmap.service';
import { PvInspectionInteractionsService } from './services/pv-inspection-interactions.service';
import { TranslateService } from '@ngx-translate/core';
import { PvInspectionService } from './services/pv-inspection.service';
import CircleStyle from 'ol/style/Circle';
import { AnnotationsService } from '../file/sidebar/annotations/annotations.service';
import { PvFilterService } from './services/pv-filter.service';
import { Annotation } from '../file/sidebar/annotations/annotation.model';

@Component({
  selector: 'app-pv-inspection',
  templateUrl: './pv-inspection.component.html',
  styleUrls: ['./pv-inspection.component.scss']
})

export class PvInspectionComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicSidenav') dynamicSidenav?: MatSidenav;
  @ViewChild('layerSidenav') layerSidenav?: MatSidenav;
  @ViewChild('imagesSidenav') imagesSidenav?: MatSidenav;

  public selectedPvModules: PVModule[] = [];
  public thermalModeActive = false;
  public measuringTemperature = false;
  public annotationFormActive = false;
  public selectedImageId?: string;
  public showImageViewer = false;
  public viewerPosition = { x: 0, y: 0 };
  public comparisonModeActive = false;
  public selectedThermalPalette: string[] = THERMAL_PALETTES[0]?.value ?? [];
  public vectorSource: any;
  public nearbyImagesToPvModule: FileModel[] = [];
  public hoveredFeature: Feature | null = null;
  public selectedFeatures: Feature[] | null = undefined;
  public orthoInspectionData: any;
  public theme: string;
  public layers: any[] = [];
  public imagesLayer: any;
  public currentLang: string;
  public showFilterBox = false;
  public currentFilters = { operationalOnly: false, withAnomalies: false };
  public activeSidenav: 'module' | 'layers' | 'thermal' | 'modules' | null = null;
  public thermalMetaData: any = undefined;
  public map?: Map;
  public siteId: string = undefined;
  public dynamicFilterOptions: any[] = [];
  public selectedFilterValues: any[] = [];
  public allPvModules: PVModule[] = [];

  private imagePinStyles: Record<string, Style[]> = {};
  private ngDestroy$ = new Subject<void>();
  private isMapMoving = false;
  private swipeLineMouseDownListener: () => void;
  private mapElementMouseMoveListener: () => void;
  private documentMouseUpListener: () => void;
  private temperatureLayer: VectorLayer<VectorSource> = new VectorLayer({
    source: new VectorSource(),
    zIndex: 200,
  });

  constructor(
    private sidenavNavigationService: SidenavNavigationService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private pvInspectionOlMapService: PvInspectionOlmapService,
    private pvInspectionInteractionsService: PvInspectionInteractionsService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public pvInspectionService: PvInspectionService,
    public ordersService: OrdersService,
    public pvModulesService: PvModulesService,
    private annotationsService: AnnotationsService,
    private filterService: PvFilterService,

  ) {
    this.initializeLanguageListener();
    this.initializeThemeListener();
    this.initializeHoveredFeatureListener();
    this.initializeSelectedFeaturesListener();
    this.initializePvModulesUpdateListener();
    this.initializeImageClickListener();
    this.initializeNearbyImagesListener();
    this.initializeRouteParamsListener();
    
    this.filterService.filters$.pipe(takeUntil(this.ngDestroy$)).subscribe(filters => {
      this.dynamicFilterOptions = filters;
    });
  }

  ngOnInit(): void {
    this.sidenavNavigationService.close();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  
    if (this.swipeLineMouseDownListener) this.swipeLineMouseDownListener();
    if (this.mapElementMouseMoveListener) this.mapElementMouseMoveListener();
    if (this.documentMouseUpListener) this.documentMouseUpListener();
  }

  // =============== Listeners ===============
  private initializeLanguageListener(): void {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(event => this.currentLang = event.lang);
  }

  private initializeThemeListener(): void {
    this.themeService.changed$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(theme => {
        this.theme = theme;
        const root = document.documentElement;
        root.classList.toggle('dark-theme', theme.includes('dark'));
      });
  }

  private initializeHoveredFeatureListener(): void {
    this.pvInspectionOlMapService.hoveredFeature$
      .pipe(takeUntil(this.ngDestroy$), skip(1))
      .subscribe(feature => this.hoveredFeature = feature);
  }

  private getActiveInspection(module: PVModule): Inspection | undefined {
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
    return module.inspections?.find(i => i.orderId === activeOrderId);
  }

  public onAnnotationDeleted(): void {
    const module = this.selectedPvModules[0];
    const inspection = this.getActiveInspection(module);
    const feature = module._feature;
  
    if (inspection && feature) {
      const maxSeverity = Math.max(...(inspection.annotations ?? []).map(a => a.stateDimension || 0));
  
      const newStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, true);
      feature.setStyle(newStyle);
  
      const baseStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, false);
      feature.set('_baseStyle', baseStyle);
    }
  
    // Refresh sidebar if needed
    this.updateFiltersAndReapply(); // 🔁 Refresh filters
    this.PVModuleUIRefresh();
  }

  private initializeSelectedFeaturesListener(): void {
    this.pvInspectionOlMapService.selectedFeatures$
      .pipe(
        takeUntil(this.ngDestroy$),
        skip(1),
        debounceTime(10),
        switchMap(selected => {
          this.selectedPvModules = [];
          this.selectedFeatures = selected;
  
          if (!this.selectedFeatures || this.selectedFeatures.length === 0) {
            this.closeSidenav();
            this.closeImageViewer();
            return of([]);
          }
  
          const featuresWithId = this.selectedFeatures.filter(f => !!f.getId());
          const featuresWithoutId = this.selectedFeatures.filter(f => !f.getId());
  
          const ids = featuresWithId.map(f => f.getId() as string);
  
          const bulkRequest$ = ids.length
            ? this.pvModulesService.getPvModules({ ids }).pipe(
                catchError(() => of({ data: [] }))
              )
            : of({ data: [] });
  
          return forkJoin([bulkRequest$]).pipe(
            switchMap(([bulkResponse]) => {
              const modules = bulkResponse.data as PVModule[];
              const activeOrderId = this.ordersService.activeOrder$?.value._id;
  
              for (const module of modules) {
                const feature = featuresWithId.find(f => f.getId() === module._id);
  
                if (feature) {
                  const temps = this.pvInspectionOlMapService.calculateModuleTemperature(
                    feature.getGeometry() as Polygon,
                    this.layers,
                    this.map
                  );
  
                  // Get the active inspection from the inspections array
                  const activeInspection = this.getActiveInspection(module);
  
                  if (activeInspection) {
                    activeInspection.temperatures = temps;
                  } else {
                    // If no matching inspection, optionally create one (depends on your logic)
                    const newInspection: Inspection = {
                      orderId: activeOrderId,
                      temperatures: temps,
                      relatedMedia: [],
                      annotations: [],
                    };
                    console.log('module: ', module)
                    console.log('MODULE-INSPECTION: ', module.inspections)
                    module.inspections = [...(module.inspections || []), newInspection];
                  }
  
                  // Attach feature and inspection to feature
                  (module as any)._feature = feature;
                  const finalInspection = this.getActiveInspection(module);
                  feature.set('inspection', finalInspection);
                }
              }
  
              // Handle features without ID
              const extraModules = featuresWithoutId.map(f => {
                const props = f.getProperties();
                const pvModule = this.loadDataForPvModule(props, f, this.layers, this.map);
                (pvModule as any)._feature = f;
                return pvModule;
              });
  
              return of([...modules, ...extraModules]);
            })
          );
        })
      )
      .subscribe(modules => {
        this.selectedPvModules = modules;
        if (this.selectedPvModules.length === 1) {
          this.openSidenav('module');
        } else if (this.selectedPvModules.length > 1) {
          this.openSidenav('modules');
        }
      });
  }
  
  private initializePvModulesUpdateListener(): void {
    this.pvInspectionInteractionsService.pvModulesUpdates$
      .pipe(takeUntil(this.ngDestroy$), skip(1))
      .subscribe(updates => {
        if (!updates) return;
        const moduleIds = this.selectedPvModules.map(module => module._id);
        this.pvModulesService.updatePvModules(moduleIds, updates).subscribe();
        this.selectedPvModules.forEach(module => {
          this.deepMerge(module, updates);
          
          // Update the corresponding feature
          const feature = module._feature;          
          if (feature.getId()) {
            feature.setProperties({
              ...feature.getProperties(),
              ...updates,
            });
            if (updates.inspection) {
              const maxSeverity = Math.max(...(updates.inspection[0]?.annotations || []).map(a => a.stateDimension || 0));
              const newStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity);
              feature.setStyle(newStyle);
              feature.set('_baseStyle', newStyle);
            }
          }
        });
  
        this.PVModuleUIRefresh();
      });
  }

  private initializeImageClickListener(): void {
    this.pvInspectionInteractionsService.clickedImageId$
      .pipe(takeUntil(this.ngDestroy$), skip(1))
      .subscribe(data => {
        if (data) {
          this.selectedImageId = data.imageId;
          this.viewerPosition = { x: data.position.x, y: data.position.y };
          this.showImageViewer = true;
        } else {
          this.selectedImageId = undefined;
          this.showImageViewer = false;
          this.imagesSidenav?.close();
        }
      });
  }

  private initializeNearbyImagesListener(): void {
    this.pvInspectionInteractionsService.nearbyImages$
      .pipe(takeUntil(this.ngDestroy$), skip(1))
      .subscribe(images => this.nearbyImagesToPvModule = images);
  }

  private initializeRouteParamsListener(): void {
    this.route.params
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(params => {
        const folderId = params['folderId'];
        this.pvInspectionService.getOrthoInspectionData(folderId).subscribe({
          next: orthoData => {
            this.orthoInspectionData = orthoData;

            // Getting active order and sites PV Modules
            this.ordersService.getActiveOrder(this.orthoInspectionData.orderID).then(() => {
              this.siteId = this.ordersService.activeOrder$.value.siteId;
              this.pvModulesService.getPvModules({ siteId: this.ordersService.activeOrder$.value.siteId }).subscribe({
                next: (response) => { 
                  this.addPvModuleFeaturesFromMongo(response.data);
                  this.allPvModules = response.data;
                  this.initializeDynamicFilters(this.allPvModules);
                  this.PVModuleUIRefresh();
                }
              })
            });

            // Initializing map
            this.map = this.pvInspectionOlMapService.initMap();
            this.snackBar.open('Data loaded successfully!', 'Ok', { duration: 2000 });

            //Initializing layers
            this.initLayers(this.orthoInspectionData.orthoInspectionMode.mapLayers);

            // Initializing interactions
            this.pvInspectionInteractionsService.initializeInteractions(
              this.map,
              this.imagesSidenav,
              this.orthoInspectionData.orthoInspectionMode.imagesFolderID,
              this.layers,
              this.imagePinStyles
            );
          },
          error: err => {
            console.error('Error fetching Ortho Inspection Data:', err);
          }
        });
      });
  }

  private updateFiltersAndReapply(): void {
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
  
    const allAnnotations = this.allPvModules
      .map(m => m.inspections?.find(i => i.orderId === activeOrderId)?.annotations ?? [])
      .reduce((acc, curr) => acc.concat(curr), []);
  
    const uniqueSeverities = [...new Set(allAnnotations.map(a => a.stateDimension))];
    const uniqueTypes = [...new Set(allAnnotations.map(a => a.feature))];

    console.log()
  
    const newFilters = [
      {
        name: 'SEVERITY',
        values: uniqueSeverities.filter(Boolean),
      },
      {
        name: 'TYPE',
        values: uniqueTypes.filter(Boolean),
      },
    ];
  
    this.filterService.setFilters(newFilters);
    this.dynamicFilterOptions = newFilters;
  
    // Reapply previously selected filters (to preserve checked checkboxes)
    this.onFiltersChanged(this.selectedFilterValues);
  }

  public onFiltersChanged(filters: any[]): void {
    console.log('filters coming: ', filters);
    
    this.selectedFilterValues = filters; // 💾 Save selected filters
  
    this.showFilterBox = false;
  
    const severityFilter = filters.find(f => f.name === 'SEVERITY')?.values ?? [];
    const typeFilter = filters.find(f => f.name === 'TYPE')?.values ?? [];
  
    const layer = this.pvInspectionOlMapService.getPvModulesLayer(this.layers);
    if (!layer) return;
    const features = layer.getSource().getFeatures();
    features.forEach(feature => {
      const inspection = this.getActiveInspection(feature.getProperties() as PVModule);
      const annotations: Annotation[] = inspection?.annotations ?? [];
  
      const matchesSeverity = severityFilter.length === 0 || annotations.some(a => severityFilter.includes(a.stateDimension));
      const matchesType = typeFilter.length === 0 || annotations.some(a => typeFilter.includes(a.feature));
  
      const isVisible = matchesSeverity && matchesType;
      this.pvInspectionOlMapService.setFeatureVisibility(feature, isVisible);
    });
  }

  private initializeDynamicFilters(modules: PVModule[]): void {
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
    const allAnnotations = modules
    .map(m => m.inspections?.find(i => i.orderId === activeOrderId)?.annotations ?? [])
    .reduce((acc, curr) => acc.concat(curr), []);  
    const uniqueSeverities = [...new Set(allAnnotations.map(a => a.stateDimension))];
    const uniqueTypes = [...new Set(allAnnotations.map(a => a.feature))];
  
    const filterOptions = [
      {
        name: 'SEVERITY',
        values: uniqueSeverities.filter(Boolean),
      },
      {
        name: 'TYPE',
        values: uniqueTypes.filter(Boolean),
      },
    ];

    console.log('filterOptions: ',filterOptions)
  
    this.filterService.setFilters(filterOptions);
    this.dynamicFilterOptions = filterOptions;
  }

  // ================ Methods =================
  public closeImageViewer(): void {
    this.pvInspectionInteractionsService.clickedImageId$.next(undefined)
  }

  private loadDataForPvModule(properties, pvModuleFeature: Feature, layers, map: Map ): PVModule {
    const pvModule = new PVModule();

    pvModule.siteId = properties?.siteId ?? null;
    pvModule.isOperational = properties?.isOperational ?? true;
    pvModule.precedentModuleId = properties?.precedent_module ?? null;
    pvModule.modifiedAt = properties?.modified_at ? new Date(properties.modified_at) : new Date();
    pvModule.createdAt = properties?.created_at ? new Date(properties.created_at) : new Date();
  
    pvModule.identification = {
      serialNumber: properties?.serial_number ?? null,
      fId: properties?.FID ?? null,
      moduleType: properties?.module_type ?? null,
      manufacturer: properties?.manufacturer ?? null,
      modelNumber: properties?.model_number ?? properties?.ModuleNo ?? null,
      installationDate: properties?.installation_date ? new Date(properties.installation_date) : null,
      removalDate: properties?.removal_date ? new Date(properties.removal_date) : null,
      warrantyExpiryDate: properties?.warranty_expiry_date ? new Date(properties.warranty_expiry_date) : null,
    };
  
    pvModule.location = {
      panelNumber: properties?.ModuleNo ?? null,
      arrayId: properties?.array_id ?? null,
      stringNumber: properties?.string ?? null,
      geometry: properties?.geometry ?? { type: 'Polygon', coordinates: [] },
      tiltAngle: properties?.tilt_angle ?? null,
      azimuthAngle: properties?.azimuth_angle ?? null,
      shadingInfo: properties?.shading_info ?? null,
    };
  
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
  
    // Get the matching inspection (if any) from the raw properties
    const matchingInspection = properties?.inspection_history?.find(i => i.order_id === activeOrderId);
  
    const temps = this.pvInspectionOlMapService.calculateModuleTemperature(
      pvModuleFeature.getGeometry() as Polygon,
      layers,
      map
    );
  
    if (matchingInspection) {
      pvModule.inspections = [{

        orderId: matchingInspection.order_id,
        temperatures: temps ?? matchingInspection.temperatures ?? {},
        relatedMedia: [], // Start empty
        annotations: matchingInspection.annotations ?? [],
      }];
    } else {
      // No matching inspection, but we still include a placeholder
      pvModule.inspections = [{
        orderId: activeOrderId,
        temperatures: temps ?? {},
        relatedMedia: [],
        annotations: [],
      }];
    }
  
    return pvModule;
  }
  
  public toggleThermalSettings(): void {
    if (!this.orthoInspectionData) return;
  
    const thermalLayer = this.orthoInspectionData.orthoInspectionMode.mapLayers.find(layer => layer.type === 'tiff-thermal');
    if (!thermalLayer) {
      console.error('Thermal layer not found.');
      return;
    }
  
    if (this.thermalModeActive && this.activeSidenav === 'thermal') {
      this.thermalModeActive = false;
      this.thermalMetaData = undefined;
      this.closeSidenav();
      return;
    }
  
    this.thermalMetaData = thermalLayer.file.meta;
    this.thermalModeActive = true;
    this.openSidenav('thermal');
  }

  public toggleComparisonMode(): void {
    if (!this.orthoInspectionData) return;

    const thermalLayer = this.orthoInspectionData.orthoInspectionMode.mapLayers.find(layer => layer.type === 'tiff-thermal');
    if (!thermalLayer) {
        console.error('Thermal layer not found.');
        return;
    }

    this.thermalMetaData = thermalLayer.file.meta;
    const mainOrthoLayer = this.pvInspectionOlMapService.getMainOrthoFromLayers(this.layers);
    const mainThermalLayer = this.pvInspectionOlMapService.getMainThermalOrthoFromLayers(this.layers);
    const swipeLine = document.querySelector('.swipe-line') as HTMLElement;

    if (!mainOrthoLayer || !mainThermalLayer) {
        console.error('Main Ortho or Thermal Layer missing.');
        return;
    }

    if (!this.comparisonModeActive) {
        this.comparisonModeActive = true;
        this.thermalModeActive = true;
        swipeLine.style.display = 'block';
        swipeLine?.classList.add('visible');
        this.openSidenav('thermal');

        this.pvInspectionOlMapService.pushLayer(this.layers, mainThermalLayer, mainThermalLayer.getZIndex(), true, 'Comparison Layer');

        this.initializeSwipe();
        this.setSwiperUpdatesListener();
    } else {
        this.comparisonModeActive = false;
        this.thermalModeActive = false;

        swipeLine?.classList.remove('visible');
        swipeLine.style.display = 'none';
        swipeLine.style.left = '50%';

        this.closeSidenav();
        this.resetLayersToStack();

        if (this.swipeLineMouseDownListener) this.swipeLineMouseDownListener();
        if (this.mapElementMouseMoveListener) this.mapElementMouseMoveListener();
        if (this.documentMouseUpListener) this.documentMouseUpListener();
    }
  }

  private resetLayersToStack(): void {
    const mainOrthoLayer = this.pvInspectionOlMapService.getMainOrthoFromLayers(this.layers);
    const mainThermalLayer = this.pvInspectionOlMapService.getMainThermalOrthoFromLayers(this.layers);

    if (!mainOrthoLayer || !mainThermalLayer) return;

    // Remove the extent restrictions set during swiping
    mainOrthoLayer.setExtent(undefined);
    mainThermalLayer.setExtent(undefined);

    // Ensure thermal layer is above ortho
    mainThermalLayer.setZIndex(mainOrthoLayer.getZIndex() + 1);

    // Trigger map re-render to apply changes
    this.map.render();
  }
  
  public openSidenav(type: 'module' | 'layers' | 'thermal' | 'modules') {
    this.activeSidenav = type;
    this.dynamicSidenav.open();
  }

  public closeSidenav() {
    this.dynamicSidenav.close();
    this.activeSidenav = null;
  }

  private adjustMapViewToMainOrtho(geoTiffLayer: WebGLTileLayer):void {
    geoTiffLayer.getSource().getView().then((viewOptions) => this.map.getView().fit(viewOptions.extent));
  }

  public toggleFilterBox(): void {
    this.showFilterBox = !this.showFilterBox;
  }

  public toggleUploadLayerDialog(): void {  
    this.dialog.open(UploadLayerDialogComponent, {
      panelClass: 'custom-upload-dialog'
    });
  }

  private initLayers(mapLayers: any[] = []):void {
    if (mapLayers.length === 0) {
      this.dialog.open(UploadLayerDialogComponent, {
        panelClass: 'custom-upload-dialog'
      });
      return;
    }
    mapLayers.forEach((layer) => {
      switch (layer.type) {
        case 'tiff': {
          const geoTiffLayer = this.pvInspectionOlMapService.addTiffLayer(this.map, layer);
          this.pvInspectionOlMapService.pushLayer(this.layers, geoTiffLayer, layer.zIndex, !!layer.isMainTiff, layer.label);
          if (layer.isMainTiff) {
            this.adjustMapViewToMainOrtho(geoTiffLayer);
          }
          break;
        }

        case 'tiff-thermal': {
          const geoTiffLayer = this.pvInspectionOlMapService.addTiffThermalLayer(this.map, layer, this.selectedThermalPalette);
          this.pvInspectionOlMapService.pushLayer(this.layers, geoTiffLayer, layer.zIndex, !!layer.isMainTiff, layer.label);
          break;
        }

        case 'geojson':
          this.pvInspectionOlMapService.registerProjection(layer)
          const geojsonLayer = this.pvInspectionOlMapService.addGeojsonLayer(this.map, layer);
          this.pvInspectionOlMapService.pushLayer(this.layers, geojsonLayer, layer.zIndex, !!(layer.isMainTiff), layer.label)
          break;
  
        case 'kml':
          //this.addKmlLayer(this.map, layer.file);
          break;
  
        case 'pdf':
          //this.addPdfLayer(this.map, layer.file);
          break;
  
        default:
          console.error(`Unsupported fileType: ${layer.type} - Cannot display on map`);
      }
    });
  }

  private initializeSwipe(): void {
    const swipeLine = document.querySelector('.swipe-line') as HTMLElement;
    if (!swipeLine || !this.comparisonModeActive) return;

    swipeLine.style.display = 'block';
    const mapElement = document.getElementById('map');
    let isDragging = false;

    // Remove previous listeners if they exist
    if (this.swipeLineMouseDownListener) this.swipeLineMouseDownListener();
    if (this.mapElementMouseMoveListener) this.mapElementMouseMoveListener();
    if (this.documentMouseUpListener) this.documentMouseUpListener();

    this.swipeLineMouseDownListener = this.renderer.listen(swipeLine, 'mousedown', () => {
        isDragging = true;
    });

    this.mapElementMouseMoveListener = this.renderer.listen(mapElement, 'mousemove', (event: MouseEvent) => {
        if (isDragging) {
            const mapRect = mapElement.getBoundingClientRect();
            const x = event.clientX - mapRect.left;
            if (x >= 0 && x <= mapRect.width) {
                swipeLine.style.left = `${x}px`;
                this.updateSwipeLayers(x / mapRect.width);
            }
        }
    });

    this.documentMouseUpListener = this.renderer.listen(document, 'mouseup', () => {
        isDragging = false;
    });
  }

  private applySwipeAfterMove(): void {
    const swipeLine = document.querySelector('.swipe-line') as HTMLElement;
    const mapRect = document.getElementById('map').getBoundingClientRect();
    const swipePosition = swipeLine.offsetLeft;
    const swipeRatio = swipePosition / mapRect.width;
    this.updateSwipeLayers(swipeRatio);
  }

  private setSwiperUpdatesListener(): void {
    if (!this.map || !this.comparisonModeActive) return;

    this.applySwipeAfterMove();
    this.map.on('movestart', () => {
        this.isMapMoving = true;
    });

    this.map.on('pointermove', () => {
        if (this.isMapMoving) {
            this.applySwipeAfterMove();
        }
    });

    this.map.on('postrender', () => {
        if (this.isMapMoving) {
            this.applySwipeAfterMove();
        }
    });

    this.map.on('moveend', () => {
        this.isMapMoving = false;
        this.applySwipeAfterMove();
    });
  }

  private updateSwipeLayers(swipeRatio: number): void {
    if (!this.map || !this.comparisonModeActive) return;

    const mapSize = this.map.getSize();
    if (!mapSize) return;

    const mapExtent = this.map.getView().calculateExtent(mapSize);
    const leftExtent = [...mapExtent];
    const rightExtent = [...mapExtent];

    leftExtent[2] = leftExtent[0] + (mapExtent[2] - mapExtent[0]) * swipeRatio;
    rightExtent[0] = leftExtent[2];

    const mainOrtho = this.pvInspectionOlMapService.getMainOrthoFromLayers(this.layers);
    const mainThermal = this.pvInspectionOlMapService.getMainThermalOrthoFromLayers(this.layers);

    if (mainOrtho && mainThermal) {
        mainOrtho.setExtent(leftExtent);
        mainThermal.setExtent(rightExtent);
        this.map.render();
    }
  }

  public updateLayer(event: { palette:string[], minTemp: number, maxTemp: number }) {
    this.updateTiffThermalColorMap(event.minTemp, event.maxTemp);
  }

  private updateTiffThermalColorMap(newMin, newMax): void {
    const stepValue = (newMax - newMin) / (this.selectedThermalPalette.length - 1);
    const colorMap = [0, [0, 0, 0, 0]]; // Start with transparent black
    this.selectedThermalPalette.forEach((color, index) => {
      if (index !== (this.selectedThermalPalette.length - 1)) {
        colorMap.push(newMin + stepValue * index, hexToRgb(color));
      } else {
        colorMap.push(newMax, hexToRgb(color));
      }
    });
    const thermalLayer = this.pvInspectionOlMapService.getMainThermalOrthoFromLayers(this.layers);
    thermalLayer.setStyle({
      color: [
        'interpolate',
        ['linear'],
        ['band', 1], // Reference the first band
        ...colorMap,
      ],
    });
  }

  // Set the batch size to process modules in chunks, to avoid performance bottlenecks
  private addPvModuleFeaturesFromMongo(modules: PVModule[]): void {
    if (!modules || modules.length === 0) return;
  
    const totalModules = modules.length;
    let index = 0;
  
    const sharedSource = new VectorSource();
    const vectorLayer = new VectorLayer({
      source: sharedSource,
      style: (feature) => feature.get('_baseStyle') ?? DEFAULT_STYLE,
      zIndex: 120,
      className: 'pv-module',
    });
  
    this.map.addLayer(vectorLayer);
    this.pvInspectionOlMapService.pushLayer(this.layers, vectorLayer, 120, false, 'Modules Plan');
  
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
  
    const processBatch = () => {
      const features: Feature[] = [];
      const end = Math.min(index + 5000, totalModules);
  
      for (; index < end; index++) {
        const module = modules[index];
  
        if (!module?.location?.geometry?.coordinates?.length) {
          console.warn('Invalid module geometry', module);
          continue;
        }
  
        const feature = new Feature({
          _id: module._id,
          geometry: new Polygon(module.location.geometry.coordinates),
          siteId: module.siteId,
          inspections: module.inspections,
          createdAt: module.createdAt,
          modifiedAt: module.modifiedAt
        });
  
        feature.setId(module._id);
        feature.set('type', 'pvModule');
  
        // ✅ Get active inspection for current order
        const inspection = this.getActiveInspection(module);
        feature.set('type', 'pvModule');
        // ✅ Style based on that inspection's annotations
        const annotations = inspection?.annotations ?? [];
        const maxSeverity = Math.max(...annotations.map(a => a.stateDimension || 0), 0);
  
        const baseStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, false);
        feature.set('_baseStyle', baseStyle);
        feature.setStyle(baseStyle);
  
        features.push(feature);
      }
  
      if (features.length > 0) {
        sharedSource.addFeatures(features);
      }
  
      if (index < totalModules) {
        setTimeout(processBatch, 0); // Let UI thread breathe
      }
    };
  
    processBatch();
  }
  
  public toggleAnnotationForm():void {
    this.annotationFormActive = !this.annotationFormActive
  }

  public unselectModule() {
    this.closeSidenav();
    this.selectedPvModules[0] = undefined;
    this.nearbyImagesToPvModule = [];
  }

  public unselectModules() {
    this.closeSidenav();
    this.selectedPvModules = undefined;
  }

  public onPaletteChange(event: { palette: string[], minTemp: number, maxTemp: number }) {  
    this.selectedThermalPalette = event.palette;
    this.updateTiffThermalColorMap(event.minTemp, event.maxTemp);
  }

  public resetView(): void {
  }

  // ------------- Images selection layers ---------------
  // public toggleImageSelection(image: FileModel): void {
  //   if (!this.selectedPvModules[0]?.inspection) return;
  
  //   const inspection = this.selectedPvModules[0].inspection;
  
  //   const exists = inspection.relatedMedia.find((media) => media.file._id === image._id);
  
  //   if (exists) {
  //     // Remove image if already selected
  //     inspection.relatedMedia = inspection.relatedMedia.filter((media) => media.file._id !== image._id);
  //   } else {
  //     // Add new image
  //     inspection.relatedMedia.push({
  //       file: image,
  //       geometry: undefined, // Optional: Add geometry if needed
  //     });
  //   }
  // }


  // Extracted method to handle local state update
  private appendAnnotationLocally(module: PVModule, annotation: any): void {
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
    let inspection = module.inspections?.find(i => i.orderId === activeOrderId);
  
    if (!inspection) {
      // Create a new inspection if one doesn't exist
      inspection = {
        orderId: activeOrderId,
        temperatures: {},
        relatedMedia: [],
        annotations: []
      };
      module.inspections = [...(module.inspections || []), inspection];
    }
  
    if (!inspection.annotations) {
      inspection.annotations = [];
    }
  
    inspection.annotations.push(annotation);
  
    // Trigger UI change detection
    this.selectedPvModules[0] = {
      ...module,
      inspections: module.inspections
    };
  
    // Update feature style based on severity
    const maxSeverity = Math.max(...inspection.annotations.map(a => a.stateDimension || 0));
    const selectedFeature = this.selectedFeatures?.find(f => f.getId() === module._id);
  
    if (selectedFeature) {
      const selectedStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, true);
      selectedFeature.setStyle(selectedStyle);
  
      const baseStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, false);
      selectedFeature.set('_baseStyle', baseStyle);
    }
  }  

  public onAnnotationCreated(annotation: any): void {
    const module = this.selectedPvModules[0];
    const orderId = this.ordersService.activeOrder$?.value._id;
  
    // 1. Check if the module already has a local inspection for the active order
    let localInspection = this.getActiveInspection(module)
  
    console.log('localInspection: ',localInspection)
    if (!localInspection?._id) {
      // 2. If no local inspection, create one in DB and then locally
      const inspectionPayload = {
        moduleID: module._id,
        orderID: orderId,
      };
  
      this.pvModulesService.insertPVModuleInspections(inspectionPayload)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(insertResponse => {
          const newInspectionId = insertResponse.data._id;
  
          // Add new inspection locally
          const newInspection = {
            _id: newInspectionId,
            orderId: orderId,
            temperatures: {},
            relatedMedia: [],
            annotations: [] 
          };

          module.inspections = [...(module.inspections || [])];
  
          // Create annotation
          this.annotationsService.insertOne({
            inspectionID: newInspectionId,
            ...annotation
          }).subscribe(() => {
            this.appendAnnotationLocally(module, annotation);
            this.updateFiltersAndReapply(); // 🔁 Refresh filters
          });

          console.log('newModule: ',module)
        });
    } else {
      // 3. Inspection already exists — add annotation using inspection ID from DB
      // Need to fetch it first (in case inspection._id is not available locally)
      this.annotationsService.insertOne({
        inspectionID: localInspection._id,
        ...annotation
      }).subscribe(() => {
        this.appendAnnotationLocally(module, annotation);
        this.updateFiltersAndReapply(); // 🔁 Refresh filters
      });
    }
  }
  
  public PVModuleUIRefresh(): void {
    this.selectedPvModules = [...this.selectedPvModules];
  }

  public highlightImagePin(imageId: string): void {
    const feature = this.imagesLayer?.getSource()?.getFeatureById(imageId);
    if (feature) {
      // Store the original style if it's not already saved
      if (!this.imagePinStyles[imageId]) {
        this.imagePinStyles[imageId] = feature.getStyle() as Style[];
      }
  
      // Clone the existing styles and modify only the fill color for CircleStyle & Icon
      const updatedStyles = this.imagePinStyles[imageId].map(style => {
        if (style.getImage() instanceof CircleStyle) {
          // Clone the CircleStyle and change only the fill color to blue
          return new Style({
            image: new CircleStyle({
              radius: (style.getImage() as CircleStyle).getRadius(),
              fill: new Fill({ color: '#2979ff' }), // Change fill color to blue
              stroke: (style.getImage() as CircleStyle).getStroke(), // Keep the original stroke
            }),
          });
        } else if (style.getImage() instanceof Icon) {
          // Clone the Icon and modify its color
          return new Style({
            image: new Icon({
              anchor: (style.getImage() as Icon).getAnchor(),
              anchorXUnits: 'pixels',
              anchorYUnits: 'pixels',
              imgSize: (style.getImage() as Icon).getSize(),
              color: '#2979ff', // Change icon color to blue
              src: (style.getImage() as Icon).getSrc(), // Keep the original icon
              scale: (style.getImage() as Icon).getScale(),
              opacity: (style.getImage() as Icon).getOpacity(),
              rotation: (style.getImage() as Icon).getRotation(),
            }),
          });
        }
        return style;
      });
  
      // Apply the updated styles to the feature
      feature.setStyle(updatedStyles);
    }
  }
  
  public restoreImagePin(imageId: string): void {
    const feature = this.imagesLayer?.getSource()?.getFeatureById(imageId);
    if (feature && this.imagePinStyles[imageId]) {
      // Restore the original style
      feature.setStyle(this.imagePinStyles[imageId]);
    }
  }
  
  // -------------- Thermal Measurements ----------------
  public toggleThermalMeasurementFeature(): void {
    this.measuringTemperature = !this.measuringTemperature;
    if (this.measuringTemperature) {
      this.map.on('click', this.onThermalMeasurementClick.bind(this));
    } else {
      this.map.un('click', this.onThermalMeasurementClick.bind(this));
    }
  }

  public onThermalMeasurementClick(event: any): void {
    const thermalLayer = this.pvInspectionOlMapService.getMainThermalOrthoFromLayers(this.layers)
    const pixel = event.pixel;
    const temp = thermalLayer.getData(pixel)[0]
    if (temp !== undefined) {
      this.addTemperaturePoint(event.coordinate, temp);
    }
  }

  private addTemperaturePoint(coordinates: number[], temperature: number): void {
    const feature = new Feature({
      geometry: new Point(coordinates),
      temperature: temperature, // Store temperature value in feature properties
    });
  
    feature.setStyle(this.getTemperatureStyle(temperature));
  
    this.temperatureLayer.getSource().addFeature(feature);
  }

  private getTemperatureStyle(temperature: number): Style {
    let color = '#2979ff'; // Default low temp
    if (temperature > 60) {
      color = 'red'; // High temperature
    } else if (temperature > 30) {
      color = 'orange'; // Medium temperature
    } else if (temperature > 10) {
      color = 'yellow'; // Warm
    }
  
    return new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({ color }),
        stroke: new Stroke({ color: 'white', width: 1 }),
      }),
      text: new Text({
        text: `${temperature.toFixed(1)}°C`,
        font: '12px Arial',
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetY: -12,
      }),
    });
  }

  private deepMerge(target: any, source: any) {
    Object.keys(source).forEach((key) => {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (!target[key] || typeof target[key] !== 'object') {
          target[key] = {};
        }
        this.deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    });
  }
}
