<div class="feature-details">
  <!-- Header -->
  <div class="sidenav-header">
    <div class="left">
      <span class="title">Module Details</span>
    </div>
    <div class="right">
      <mat-icon class="material-icons-outlined" (click)="onCloseSidenav()">close</mat-icon>
    </div>
  </div>

  <!-- Content -->
  <div class="sidenav-content">

    <!-- === Inspection Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('inspection')">
        <span class="section-title">Inspection</span>
        <mat-icon>{{ showInspection ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>
      <div class="section-container" [@expandCollapse]="showInspection ? 'expanded' : 'collapsed'">

          <div *ngIf="activeInspection" class="inspection-card">

            <!-- Inspection Date -->
            <div class="row-info">
              <span class="info-title">Inspected on:</span>
              <span class="info-value">12.02.2025</span>
            </div>

            <!-- Annotations -->
            <div class="sub-section annotations" *ngIf="activeInspection?.annotations?.length > 0">
              <span class="info-title">Annotations:</span>
              <ng-container *ngFor="let annotation of activeInspection?.annotations">
                <div class="annotation-container">

                  <!-- Annotation Header -->
                  <div class="annotation-header">
                    <span class="annotation-type">
                      {{ getAnnotationTypeLabel(annotation.feature) }}
                    </span>
                    <mat-icon
                      class="severity"
                      [ngClass]="{
                        'advisory': annotation.stateDimension === 20,
                        'low': annotation.stateDimension === 40,
                        'middle': annotation.stateDimension === 60,
                        'high': annotation.stateDimension === 80,
                        'urgent': annotation.stateDimension === 100
                      }">
                      crop_square
                    </mat-icon>
                  </div>

                  <!-- Annotation Info -->
                  <div class="annotation-subtitle">
                    <span>Anis Ben Ghanem</span>
                    <span> | </span>
                    <span>21.03.2025 13:17:43</span>
                  </div>

                  <!-- Annotation Comment -->
                  <div class="annotation-comment">
                    <p>{{ annotation.comment }}</p>
                  </div>

                  <!-- Annotation Menu -->
                  <button mat-icon-button class="annotation-menu" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditAnnotation(annotation)">
                      <mat-icon>edit</mat-icon>
                      <span>{{ 'SIDEBAR.ANNOTATIONS.MODIFY' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="onDeleteAnnotation(annotation)">
                      <mat-icon color="warn">delete</mat-icon>
                      <span>{{ 'SIDEBAR.ANNOTATIONS.DELETE' | translate }}</span>
                    </button>
                  </mat-menu>

                </div>
              </ng-container>
            </div>

            <!-- Temperature Table -->
            <div class="sub-section">
              <span class="info-title">Temperatures °C:</span>
              <table class="temperature-table">
                <thead>
                  <tr class="temperature-header">
                    <th class="temperature-cell">Min</th>
                    <th class="temperature-cell">Max</th>
                    <th class="temperature-cell">Avg</th>
                    <th class="temperature-cell">Delta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="temperature-values">
                    <td class="temperature-cell">{{ activeInspection?.temperatures?.min?.toFixed(2) ?? 'N/A' }}</td>
                    <td class="temperature-cell">{{ activeInspection?.temperatures?.max?.toFixed(2) ?? 'N/A' }}</td>
                    <td class="temperature-cell">{{ activeInspection?.temperatures?.avg?.toFixed(2) ?? 'N/A' }}</td>
                    <td class="temperature-cell">{{ activeInspection?.temperatures?.delta?.toFixed(2) ?? 'N/A' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </section>

    <!-- === Identification Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('identification')">
        <span class="section-title">Identification</span>
        <mat-icon>{{ showIdentification ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>
      <div class="section-container" [@expandCollapse]="showIdentification ? 'expanded' : 'collapsed'">
        <ng-container *ngFor="let field of identificationFields">
          <div class="row-info">
            <span class="info-title">{{ field.label }}:</span>

            <!-- Display Mode -->
            <span
              *ngIf="editingKey !== field.path + '.' + field.key"
              class="info-value"
              [ngClass]="{
                'undefined-info': !(pvModule?.[field.path]?.[field.key])
              }"
              (click)="startEditing(field)"
            >
              {{ formatField(pvModule?.[field.path]?.[field.key], field.key) || 'N/A' }}
            </span>

            <!-- Edit Mode -->
            <input
              *ngIf="editingKey === field.path + '.' + field.key"
              class="info-input"
              [(ngModel)]="editingValue"
              (blur)="finishEditing(field)"
              (keydown.enter)="finishEditing(field)"
              [ngClass]="{ 'input-error': isInvalid(field, editingValue) }"
              autofocus
            />
          </div>
        </ng-container>
      </div>
    </section>

    <!-- === Location Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('location')">
        <span class="section-title">Location</span>
        <mat-icon>{{ showLocation ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>
      <div class="section-container" [@expandCollapse]="showLocation ? 'expanded' : 'collapsed'">
        <ng-container *ngFor="let field of locationFields">
          <div class="row-info">
            <span class="info-title">{{ field.label }}:</span>

            <!-- Display Mode -->
            <span
              *ngIf="editingKey !== field.path + '.' + field.key"
              class="info-value"
              [ngClass]="{ 'undefined-info': !(pvModule?.[field.path]?.[field.key]) }"
              (click)="startEditing(field)"
            >
              {{ formatField(pvModule?.[field.path]?.[field.key], field.key) || 'N/A' }}
            </span>

            <!-- Edit Mode -->
            <input
              *ngIf="editingKey === field.path + '.' + field.key"
              class="info-input"
              [(ngModel)]="editingValue"
              (blur)="finishEditing(field)"
              (keydown.enter)="finishEditing(field)"
              [ngClass]="{ 'input-error': isInvalid(field, editingValue) }"
              autofocus
            />
          </div>
        </ng-container>
      </div>
    </section>
  </div>

  <!-- Footer -->
  <div class="sidenav-footer">
    <button
      color="accent"
      mat-raised-button
      type="button"
      (click)="onToggleAnnotationForm()">
      <mat-icon>warning</mat-icon>
      <span>Add annotation</span>
    </button>
  </div>
</div>
