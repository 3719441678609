import { Component, ElementRef,ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-link-device',
  templateUrl: './link-device.component.html',
  styleUrls: ['./link-device.component.scss']
})
export class LinkDeviceComponent {

  @ViewChild('tagsInputField') tagsInputField: ElementRef;

  public tags : string[] = []
  public removable: boolean = true;
  public selectable: boolean = true;
  public tagsInput = new FormControl('', Validators.pattern(/^FF\d+$|^\d+$/));

  constructor(
    private readonly modal: MatDialogRef<LinkDeviceComponent>,
  ) { }


  public tagsRemove(index: number): void {
    if (index >= 0) {
      this.tags = this.tags?.filter((s, oIndex) =>oIndex !== index);
    }
  }

  public tagsSelected(event: Event): void {
    if (this.tagsInput.invalid) return;

    const value = (event.target as HTMLInputElement).value;
    if (value && !this.tags?.some(tag => tag=== value)) {
      this.tags = this.tags || [];
      this.tags.push(value);    
    }
    this.tagsInputField.nativeElement.value = '';
    this.tagsInput.setValue('');
  }

  public submit(): void {
    this.modal.close(this.tags);
  }

  public close(): void {
    this.modal.close();
  }

}
