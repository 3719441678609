<div class="container">
  <div class="first-section">
    <div class="left">
      <mat-button-toggle-group
        [ngStyle]="{
          'border-radius': '4px',
          'font-size': '12px',
          'height': '41px',
          'align-items': 'center',
          'font-weight': '500'
        }"
        name="fontStyle"
        [value]="selectedTypeOfTeams"
        (change)="onSelectionChange($event)"
      >
        <mat-button-toggle value="owned">
          <mat-icon *ngIf="selectedTypeOfTeams === 'owned'">check</mat-icon>
          {{ 'TEAMS.OWNED' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="invited">
          <mat-icon *ngIf="selectedTypeOfTeams === 'invited'">check</mat-icon>
          {{ 'TEAMS.INVITED' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field class="search-input" appearance="outline">
      <mat-label>{{ 'TEAMS.SEARCH' | translate }}</mat-label>
      <mat-icon matSuffix>search</mat-icon>
      <input matInput (keyup)="selectedTypeOfTeams === 'owned' ? applySearchInOwnedTeams($event) : applySearchInInvitedTeams($event)"
        placeholder="{{ 'TEAMS.SEARCH_HELP' | translate }}">
    </mat-form-field>
    <ng-container *ngIf="!isXSmall">
      <button
        *ngIf="selectedTypeOfTeams === 'owned'"
        mat-raised-button
        color="accent"
        class="primary-button add-button"
        (click)="teams.createTeam()">
        {{ 'TEAMS.CREATE_TEAM' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="isXSmall">
      <button
          *ngIf="selectedTypeOfTeams === 'owned'"
          color="accent"
          mat-mini-fab>
        <mat-icon>add</mat-icon>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="selectedTypeOfTeams === 'owned'">
    <div *ngIf="createdTeams && createdTeams?.length > 0" class="table-container">
      <table mat-table [dataSource]="createdTeams" class="mat-table">

        <!-- Team Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.NAME' | translate }} </th>
          <td mat-cell *matCellDef="let team"> {{ team.name || '-' }} </td>
          <td mat-footer-cell *matFooterCellDef> Total: {{createdTeams.length}} </td>
        </ng-container>

        <!-- Portfolios Column -->
        <ng-container matColumnDef="portfolios">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.PORTFOLIOS' | translate }} </th>
          <td mat-cell *matCellDef="let team" class="portfolios-column"> {{ team.resources | resourceCount: 'portfolio' }} </td>
          <td mat-footer-cell *matFooterCellDef class="portfolios-column"></td>
        </ng-container>

        <!-- Members Column -->
        <ng-container matColumnDef="members">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.MEMBERS' | translate }} </th>
          <td mat-cell *matCellDef="let team" class="members-column">
            <div class="members-container">
              <ng-container *ngIf="team.members && team.members.length > 0">
                <div *ngFor="let member of team.members | slice:0:3" class="avatar">
                  <img *ngIf="member.avatarIconURL" src="member.avatarIconURL" alt="Member Avatar" />
                  <mat-icon class="account-circle-icon" *ngIf="!member.avatarIconURL">account_circle</mat-icon>
                </div>
                <div *ngIf="team.members?.length > 3" [class.dark-background]="theme && theme.includes('dark')" class="more-members">
                  <span>
                    +{{ team.members?.length - 3 }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!team.members || team.members.length === 0">
                <div [class.dark-background]="theme && theme.includes('dark')" class="more-members no-margin-left">
                  <span>
                    0
                  </span>
                </div>
              </ng-container>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="members-column"></td>
        </ng-container>

        <!-- Created At Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.CREATED_AT' | translate }} </th>
          <td mat-cell *matCellDef="let team"> {{ team.createdAt | date }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- stats Column -->
        <ng-container matColumnDef="stats">
          <th mat-header-cell *matHeaderCellDef class="stats-column"></th>
          <td mat-cell *matCellDef="let team" class="stats-column">
            <div class="stats-container">
              <div class="stat-content">
                <mat-icon>person</mat-icon>
                <span> {{team.members?.length}}</span>
              </div>
              <div class="stat-content">
                <mat-icon svgIcon="portfolio"></mat-icon>
                <span>{{ team.resources | resourceCount: 'portfolio' }}</span>
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="stats-column"></td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="action-column"></th>
          <td mat-cell *matCellDef="let team" class="action-column">
            <button
                mat-icon-button
                class="menu"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                  mat-menu-item
                  (click)="teams.editTeam(team)">
                <mat-icon>edit</mat-icon>
                <span>{{ 'EDIT' | translate }}</span>
              </button>
              <button
                  mat-menu-item
                  (click)="teams.deleteTeam(team._id)">
                <mat-icon color="warn">delete</mat-icon>
                <span>{{ 'DELETE' | translate }}</span>
              </button>
            </mat-menu>
          </td>
          <td mat-footer-cell *matFooterCellDef class="action-column"></td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row class="table-header"  *matHeaderRowDef="ownedDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let team; columns: ownedDisplayedColumns;" (click)="teams.viewTeam(team)"></tr>
        <tr mat-footer-row class="small-height" *matFooterRowDef="ownedDisplayedColumns; sticky: true"></tr>

      </table>
    </div>
    <div *ngIf="!createdTeams || createdTeams?.length === 0" class="empty-team">
      <section
        class="empty-state-grid">
        <div class="thumbnail">
          <i></i>
          <div class="content">
            <ng-container>
              <mat-icon disabled>group_off</mat-icon>
              <p>{{ 'TEAMS.NO_OWNED_TEAMS' | translate }}</p>
            </ng-container>
          </div>
        </div>
      </section>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedTypeOfTeams === 'invited'">
    <div *ngIf="invitedTeams && invitedTeams?.length > 0" class="table-container">
      <table mat-table [dataSource]="invitedTeams" class="mat-table">

        <!-- Team Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.NAME' | translate }} </th>
          <td mat-cell *matCellDef="let team"> {{ team.name }} </td>
          <td mat-footer-cell *matFooterCellDef> Total: {{invitedTeams.length}} </td>
        </ng-container>

        <!-- Owner Column (Double Width) -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.OWNER' | translate }} </th>
          <td mat-cell *matCellDef="let team" class="double-width">
            <div class="manager-container">
              <div class="avatar">
                <img *ngIf="team.manager.avatarIconURL" src="team.manager.avatarIconURL" alt="Member Avatar" />
                <mat-icon class="account-circle-icon" *ngIf="!team.manager.avatarIconURL">account_circle</mat-icon>
              </div>
              <div class="manager-details">
                <span class="manager-full-name">
                  {{team.manager.name}} {{team.manager.surname}}
                </span>
                <span class="manager-email">
                  {{team.manager.email}}
                </span>
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="double-width"></td>
        </ng-container>

        <!-- AccessLevel Column -->
        <ng-container matColumnDef="accessLevel">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.ACCESS_LEVEL' | translate }} </th>
          <td mat-cell *matCellDef="let team" class="function-column">
            <ng-container *ngIf="!isSmall && !isXSmall">
              {{ team.accessLevel }}
            </ng-container>
            <ng-container *ngIf="isSmall || isXSmall">
              <mat-icon *ngIf="team.accessLevel === 'viewer'" class="accessLevel">visibility</mat-icon>
              <mat-icon *ngIf="team.accessLevel === 'editor'" class="accessLevel">edit</mat-icon>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef class="function-column"></td>
        </ng-container>

        <!-- Portfolios Column -->
        <ng-container matColumnDef="portfolios">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.PORTFOLIOS' | translate }} </th>
          <td mat-cell *matCellDef="let team"> {{ team.resources | resourceCount: 'portfolio' }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Members Column -->
        <ng-container matColumnDef="members">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.MEMBERS' | translate }} </th>
          <td mat-cell *matCellDef="let team">
            <div class="members-container">

              <!-- Show avatars only when NOT isSmall or isXSmall -->
              <ng-container *ngIf="!isSmall && !isXSmall">
                <ng-container *ngIf="team.members && team.members.length > 0">
                  <div *ngFor="let member of team.members | slice:0:3" class="avatar">
                    <img *ngIf="member.avatarIconURL" src="member.avatarIconURL" alt="Member Avatar" />
                    <mat-icon class="account-circle-icon" *ngIf="!member.avatarIconURL">account_circle</mat-icon>
                  </div>
                  <div *ngIf="team.members?.length > 3" [class.dark-background]="theme && theme.includes('dark')" class="more-members">
                    <span>
                      +{{ team.members?.length - 3 }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="!team.members || team.members.length === 0">
                  <div [class.dark-background]="theme && theme.includes('dark')" class="more-members no-margin-left">
                    <span>
                      0
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- stats Column -->
        <ng-container matColumnDef="stats">
          <th mat-header-cell *matHeaderCellDef class="stats-column"></th>
          <td mat-cell *matCellDef="let team" class="stats-column">
            <div class="stats-container">
              <div class="stat-content">
                <mat-icon *ngIf="team.accessLevel === 'viewer'" class="accessLevel" color="accent">visibility</mat-icon>
                <mat-icon *ngIf="team.accessLevel === 'editor'" class="accessLevel" color="accent">edit</mat-icon>
              </div>
              <div class="stat-content">
                <mat-icon>person</mat-icon>
                <span> {{team.members?.length}}</span>
              </div>
              <div class="stat-content">
                <mat-icon svgIcon="portfolio"></mat-icon>
                <span>{{ team.resources | resourceCount: 'portfolio' }}</span>
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="stats-column"></td>
        </ng-container>

        <!-- Created At Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.CREATED_AT' | translate }} </th>
          <td mat-cell *matCellDef="let team"> {{ team.createdAt | date }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row class="table-header" *matHeaderRowDef="invitedDisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let team; columns: invitedDisplayedColumns;" (click)="teams.viewTeam(team)"></tr>
        <tr mat-footer-row class="small-height" *matFooterRowDef="invitedDisplayedColumns; sticky: true"></tr>

      </table>
    </div>
    <div *ngIf="!invitedTeams || invitedTeams?.length === 0" class="empty-team">
      <section
      class="empty-state-grid">
      <div class="thumbnail">
        <i></i>
        <div class="content">
          <ng-container>
            <mat-icon disabled>group_off</mat-icon>
            <p>{{ 'TEAMS.NO_INVITATIONS' | translate }}</p>
          </ng-container>
        </div>
      </div>
    </section>
    </div>
  </ng-container>
</div>
