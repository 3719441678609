import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Team } from '../../models/team.model';
import { TeamsComponent } from '../../teams.component';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent implements OnChanges {
  @Input() createdTeams: Team[] = [];
  @Input() invitedTeams: Team[] = [];
  @Input() theme: string;
  @Input() isSmall: boolean = false;
  @Input() isXSmall: boolean = false;

  public selectedTypeOfTeams = 'owned'
  public ownedDisplayedColumns: string[] = ['name', 'members' ,'portfolios' , 'actions'];
  public invitedDisplayedColumns: string[] = ['name', 'owner', 'accessLevel', 'portfolios', 'members', 'stats'];

  constructor(
    public teams: TeamsComponent
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSmall'] || changes['isXSmall']) {
      this.updateDisplayedColumns();
    }
  }

  private updateDisplayedColumns():void {
    if (!this.isSmall && !this.isXSmall) {
      this.ownedDisplayedColumns = ['name', 'members' ,'portfolios' , 'actions'];
      this.invitedDisplayedColumns = ['name', 'owner', 'accessLevel', 'portfolios', 'members'];
    } else {
      this.ownedDisplayedColumns = ['name', 'stats', 'actions'];
      this.invitedDisplayedColumns = ['name', 'stats'];
    }
  }

  public applySearchInInvitedTeams(event?: Event):void {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('Invited Teams search: ',filterValue)
  }

  public applySearchInOwnedTeams(event?: Event):void {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('Invited Teams search: ',filterValue)
  }

  public onSelectionChange(event: any):void {
    this.selectedTypeOfTeams = event.value;
  }
}
