import { Fill, Stroke, Style } from 'ol/style';

export const PV_MODULE_COLORS = {
  NONE: '#ffffff',         // White (used for stroke only)
  AD: '#1b4964',           // Advisory
  LOW: '#38d23c',          // Low
  IMPORTANT: '#fece2f',    // Important
  CRITICAL: '#fd261c',     // Critical
  URGENT: '#8837b4',       // Urgent
};

export const SELECTED_STYLE = new Style({
  stroke: new Stroke({
    color: '#2979ff',
    width: 2,
    lineDash: [8, 12],
  }),
  fill: new Fill({
    color: '#2979ff33', // Active blue with ~20% opacity
  }),
});

export const DEFAULT_STYLE = new Style({
  stroke: new Stroke({
    color: `${PV_MODULE_COLORS.NONE}70`,
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0)' // white, 0% opacity
  })
});

export const HIDDEN_STYLE = new Style({}); // Empty style = invisible but still "there"

