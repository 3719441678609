import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { DrawType } from '../../../file/sidebar/annotations/annotation.model';
import { OlImageService } from 'src/app/shared/ol-image/ol-image.service';
import { TranslateService } from '@ngx-translate/core';
import { AnnotationTypesObj, SortArray } from 'src/app/shared/annotations/annotation-types';

@Component({
  selector: 'app-mark-annotation-dialog',
  templateUrl: './mark-annotation-dialog.component.html',
  styleUrls: ['./mark-annotation-dialog.component.scss']
})
export class MarkAnnotationDialogComponent implements OnInit {
  @Output() toggleAnnotationForm = new EventEmitter<void>();
  @Output() annotationCreated = new EventEmitter<any>();  // New Output EventEmitter

  public annotationForm: FormGroup;
  public annotationTypes: { [key: string]: any[] } = { general: [] };
  public currentLang: string;
  public annotationTypesGeneral: any[] = [];

  get stateDimension(): AbstractControl {
    return this.annotationForm.get('stateDimension');
  }

  constructor(
    private fb: FormBuilder,
    private olImageService: OlImageService,
    private translate: TranslateService
  ) {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: any) => {
      this.currentLang = event.lang;
    });
    this.setAnnotationsTypes()
  }

  ngOnInit(): void {
    this.annotationForm = this.fb.group({
      feature: [null],
      stateDimension: [20],
      comment: [''],
    });
  }

  close(): void {
    this.annotationForm.reset({
      feature: null,
      stateDimension: 20,
      comment: '',
    });
    this.annotationTypes = { general: [] };
    this.annotationTypesGeneral = [];
    this.toggleAnnotationForm.emit()
  }

  selectDimension(dimension: number): void {
    this.stateDimension.setValue(dimension);
  }

  selectForm(form: DrawType): void {
    this.olImageService.setType(form);
  }

  sortAnnotationTypes(): void {
    for (const [key, value] of Object.entries(this.annotationTypes)) {
      this.annotationTypes[key] = SortArray(value, this.currentLang);
    }
    if (this.annotationTypes['general']) {
      this.annotationTypesGeneral = this.annotationTypes['general'];
      delete this.annotationTypes['general'];
    } else {
      this.annotationTypesGeneral = SortArray(this.annotationTypesGeneral, this.currentLang);
    }
  }

  createNewAnnotation(): void {
    const annotationData = this.annotationForm.value;
    this.annotationCreated.emit(annotationData);
    this.close();
  }

  updateAnnotation(): void {
    console.log('Updating annotation:', this.annotationForm.value);
  }

  setAnnotationsTypes(): void {
    const inspectionTypes = ['general','pv']
    for (const [key, value] of Object.entries(AnnotationTypesObj)) {
      if (inspectionTypes.includes(key)) {
        this.annotationTypes[key] = []
        for (const [key2, value2] of Object.entries(value)) {
          const annotationType = {  ... value2  };
          annotationType["id"] = key2;
          annotationType['completed'] = false;
          this.annotationTypes[key].push(annotationType);
        }
      }
    }
    this.sortAnnotationTypes()
  }
}