<div class="filter-container">
  <section *ngFor="let filter of filters">
    <mat-checkbox
      class="group-title"
      [checked]="filter.completed"
      [indeterminate]="someComplete(filter)"
      (change)="setAll(filter, $event.checked)">
      {{ 'ANNOTATION.'+filter.name | translate }}
    </mat-checkbox>

    <div class="filter-options" *ngIf="filter.subtasks?.length">
      <ul>
        <li *ngFor="let subtask of filter.subtasks">
          <mat-checkbox
            class="option"
            [(ngModel)]="subtask.completed"
            (ngModelChange)="updateAllComplete(filter)">
            <!-- {{ subtask.name }} -->
            {{ filter.name === 'TYPE'
              ? getAnnotationTypeLabel(subtask.name)
              : (subtask.name | severityLabel | translate) }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </section>

  <div class="filter-actions">
    <button mat-button (click)="cancelFilters()">{{ 'CANCEL'| translate }}</button>
    <button mat-raised-button color="accent" (click)="applyFilters()">{{ 'APPLY'| translate }}</button>
  </div>
</div>
