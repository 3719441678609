import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PortfoliosService } from './portfolios.service';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
import { AddModifyCollaboratorsDialogComponent } from 'src/app/shared/add-modify-collaborators-dialog/add-modify-collaborators-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { Portfolio } from 'src/app/shared/portfolio/portfolio.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddModifyPortfolioDialogComponent } from 'src/app/shared/add-modify-portfolio-dialog/add-modify-portfolio-dialog.component';
import { AccountType } from '../users/models/user.model';
import { LoginStateService } from '../login/login-state.service';

@Component({
  selector: 'app-portfolios',
  templateUrl: './portfolios.component.html',
  styleUrls: ['./portfolios.component.scss']
})
export class PortfoliosComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'owner', 'averageScore', 'total', 'stats', 'actions'];
  langTranslations: any = undefined;
  ngDestroy$ = new Subject();
  accountType = AccountType

  
  ownedPortfolios: Portfolio[] = [];
  sharedPortfolios: Portfolio[] = [];
  dataSource = new MatTableDataSource(this.ownedPortfolios); // Ensure ownedPortfolios is assigned
  
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private portfoliosService: PortfoliosService,
    private router: Router,
    public permissionsService: PermissionsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private dialogService: DialogService,
    public loginStateService: LoginStateService,
  ) {
    this.translate.get(['COLLABORATION']).subscribe((translation) => {
      this.langTranslations = translation
    })
   }

  ngOnInit(): void {
  this.portfoliosService.getPortfolios().subscribe(
    (response) => {
      if (response.data && response.data.portfolios && response.data.stats) {
        const portfolios = response.data.portfolios.map((portfolio, index) => ({
          ...portfolio,
          stats: response.data.stats[index]
        }));

        // Initialize MatTableDataSource with the portfolios data
        this.dataSource = new MatTableDataSource(portfolios);
        this.activateFiltering()
        this.activateSorting()

        // Separate portfolios into Owned and Shared
        this.ownedPortfolios = portfolios.filter(p => !p.accessLevel);
        this.sharedPortfolios = portfolios.filter(p => p.accessLevel);
        
      }
    },
    (error) => {
      console.error('Error fetching portfolios:', error);
    }
  );
  }

  activateSorting() {
    this.dataSource.sort = this.sort;
    // Handle sorting for nested properties
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'averageScore':
          return item.stats?.averageScore ?? 0;
        case 'total':
          return item.stats?.sitesStats?.total ?? 0;
        default:
          return item[property];
      }
    };
  }

  activateFiltering() {
    this.dataSource.filterPredicate = (data: Portfolio, filter: string) => {
      const name = data.user?.name?.toLowerCase() || "";
      const surname = data.user?.surname?.toLowerCase() || "";
      const email = data.user?.email?.toLowerCase() || "";
      return (
        name.includes(filter) ||
        surname.includes(filter) ||
        email.includes(filter)
      );
    };
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  onDelete(portfolio: Portfolio): void {
    console.log('portfolio: ',portfolio)
    if (portfolio.stats.sitesStats.total > 0) {
      this.dialogService.showDialog(
        'Deletion Not Allowed',
        null,
        'This portfolio cannot be deleted because it contains sites.',
        null,
        false,
        false
      );
    } else {
      this.deletePortfolio(portfolio._id)
    } 
  }

  deletePortfolio(portfolioId: string): void {
    const dialogRef = this.dialogService.showDialog(
          'Delete Confirmation',
          null,
          'Are you sure you want to delete this portfolio?',
          null,
          true,
          false) as Observable<boolean>;

    dialogRef.subscribe((confirm) => {
      if (confirm) {
            this.portfoliosService
              .deleteOne(portfolioId.toString())
              .pipe(takeUntil(this.ngDestroy$))
              .subscribe(
                () => {
                  // Remove the portfolio from the data source
                  const index = this.dataSource.data.findIndex(
                    (portfolio) => portfolio._id === portfolioId
                  );
                  if (index !== -1) {
                    this.dataSource.data.splice(index, 1);
                  }

                  // Remove the portfolio from owned or shared arrays (optional)
                  this.ownedPortfolios = this.ownedPortfolios.filter(
                    (portfolio) => portfolio._id !== portfolioId
                  );
                  this.sharedPortfolios = this.sharedPortfolios.filter(
                    (portfolio) => portfolio._id !== portfolioId
                  );
                },
                (error) => {
                  this.dialogService.showDialog(
                    "FILE.DELETE",
                    error.status,
                    error.url,
                    error.error
                  );
                }
              );
        }
    });
  }


  public openCreatePortfolioDialog(): void {
    const modal = this.dialog.open<AddModifyPortfolioDialogComponent, { portfolio?: Portfolio; isAdmin: boolean }>(
      AddModifyPortfolioDialogComponent,
      {
        width: "55vw",
        maxWidth: "700px",
        minWidth: "350px",
        closeOnNavigation: true,
        data: {
          portfolio: undefined,
          isAdmin: [AccountType.ADMIN, AccountType.SUPERADMIN].includes(
            this.loginStateService.loggedUser$.value?.accountType
          ),
        },
      }
    );
    modal.afterClosed().subscribe((newPortfolio: Portfolio | null) => {
      if (newPortfolio) {
        forkJoin({
            portfolio: this.portfoliosService.getPortfolioById(newPortfolio._id),
            stats: this.portfoliosService.getPortfolioStats(newPortfolio._id)
        }).pipe(takeUntil(this.ngDestroy$))
        .subscribe(({portfolio, stats }) => {
          portfolio.data.stats = stats.data
          this.dataSource.data = [...this.dataSource.data, portfolio.data];
          this.ownedPortfolios.push(portfolio.data);
          this.dataSource._updateChangeSubscription();
        })    
      }
    });
  }

  toggleEditPortfolioDialog(portfolio: Portfolio) {
    this.dialog.open<AddModifyPortfolioDialogComponent, { portfolio?: Portfolio; isAdmin: boolean }>(AddModifyPortfolioDialogComponent, 
      {
        width: "55vw",
        maxWidth: "700px",
        minWidth: "350px",
        closeOnNavigation: true,
        data: {
          portfolio: portfolio,
          isAdmin: [AccountType.ADMIN, AccountType.SUPERADMIN].includes(
            this.loginStateService.loggedUser$.value?.accountType
          ),
        },
      }
    );
  }

  toggleCollaborationDialog(portfolio, portfolioOwnerId): void {
    const dialogRef = this.dialog.open(AddModifyCollaboratorsDialogComponent, {
      width: '600px', // Set the width of the dialog
      data: { portfolio: portfolio, ownerId: portfolioOwnerId }, // Optional: if you need to pass data to the dialog
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.translate.get("COLLABORATION.RESPONSE").subscribe((translation) => {
        if (!result) return;
        if (result.status) {
          if (result.requestInput === result.requestOutput) {
            this.dialogService.showDialog("Collaborators Added Successfully",null,null,null,null,true);
          } else if ( result.requestOutput === 0 && result.requestInput === 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['ONE_NO_USER'], null, null, true);
          } else if ( result.requestOutput === 0 && result.requestInput > 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['ALL_NO_USERS'], null, null, true);
          } else if ( result.requestOutput > 0 && result.requestInput > 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['MULTIPLE_NO_USERS'], null, null, true);
          }
        } else {
          this.dialogService.showDialog("Error", null, translation['ERROR'][result.errorMessage], null, null, true);
        }
      });
    });
  }

  // Apply filter on name
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  navigateToPortfolio(portfolioId: string): void {
    this.router.navigate([{ outlets: {
      primary: ['portfolios', portfolioId],
      detail: null
    }}]);
  }
}
