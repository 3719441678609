<div class="wrapper">
  <div class="container-content"  *ngIf="viewMode === 'client'">
    <app-client-view
      [createdTeams]="createdTeams"
      [invitedTeams]="invitedTeams"
      [theme]="theme"
      [isSmall]="isSmall"
      [isXSmall]="isXSmall"
    ></app-client-view>
  </div>

  <div class="container-content"
    *ngIf="viewMode === 'admin'">
    <app-admin-view
      [allTeams]="allTeams"
      [theme]="theme"
      [isSmall]="isSmall"
      [isXSmall]="isXSmall"
    ></app-admin-view>
  </div>
</div>
