<div class="sidenav-header">
  <div class="left">
    <span class="title">Thermal Settings</span>
  </div>
  <div class="right">
    <mat-icon class="material-icons-outlined" (click)="closeSidenav()">close</mat-icon>
  </div>
</div>

<!-- Histogram Range Slider -->
<app-histogram-range-slider
      [histogramData]="thermalMetaData?.temperature_histogram"
      [selectedColorGradient]="selectedThermalPalette"
      (paletteChangeEvent)="onPaletteChange($event)"
      (rangeChange)="onHistogramRangeChange($event)">
</app-histogram-range-slider>

