/* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match, import/export */
import { Coordinate } from 'ol/coordinate';
import { FileModel } from '../../file.model';
import { Task } from 'src/app/pages/tasks/task/task.model';

const SeverityToColor = {
  advisory: '#d5e5fe',
  high: '#fd261c',
  middle: '#fece2f',
  low: '#38d23c',
  own: '#9b9b9b',
  not_assessed: '#9b9b9b',
  selected: '#2979ff',
  urgent: '#8837B4'
};
export interface Annotation {
  _id?: string | object;
  annoID?: string;
  comment?: string;
  coordinates: Array<Array<Coordinate>>;
  created?: string;
  feature?: string;
  fileID?: string;
  inspectionID?: string;
  index?: number;
  modified?: string;
  stateDimension?: AnnotationsStateDimension;
  stateForm?: DrawType;
  userName?: string;
  averageTemperature?: number;
  maximumTemperature?: number;
  minimumTemperature?: number;
  deltaTemperature?: number;
  file?: FileModel;
  tasks?: Task[]
}

export enum AnnotationsStateDimension {
  urgent = 100,
  high = 80,
  middle = 60,
  low = 40,
  advisory = 20,
  // Minimum = 0
}
export namespace AnnotationsStateDimension {
  export function getColor(stateDimension: AnnotationsStateDimension): string {
    const color = SeverityToColor[AnnotationsStateDimension[stateDimension]];
    if (color === undefined) {
      return 'blue'
    }
    return color
  }
}

export type DrawType = 'Polygon' | 'Box' | 'Circle' | 'Arrow' | 'Point';
