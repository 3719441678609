<div class="container">
  <div class="first-section">
    <mat-form-field class="search-input" appearance="outline">
      <mat-label>{{ 'TEAMS.SEARCH' | translate }}</mat-label>
      <mat-icon matSuffix>search</mat-icon>
      <input matInput (keyup)="applySearch($event)" placeholder="{{ 'TEAMS.SEARCH_HELP' | translate }}">
    </mat-form-field>
    <ng-container *ngIf="!isXSmall">
      <button
        mat-raised-button
        color="accent"
        class="primary-button add-button"
        (click)="teams.createTeam()">
        {{ 'TEAMS.CREATE_TEAM' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="isXSmall">
      <button
        color="accent"
        mat-mini-fab>
        <mat-icon>add</mat-icon>
      </button>
    </ng-container>
  </div>

  <div *ngIf="allTeams && allTeams?.length > 0" class="table-container">
    <table mat-table [dataSource]="allTeams" class="mat-table">

      <!-- Team Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.NAME' | translate }} </th>
        <td mat-cell *matCellDef="let team"> {{ team.name || '-' }} </td>
        <td mat-footer-cell *matFooterCellDef> {{ 'TEAMS.TOTAL' | translate: { count: allTeams.length } }} </td>
      </ng-container>

      <!-- Owner Column -->
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef class="double-width"> {{ 'TEAMS.OWNER' | translate }} </th>
        <td mat-cell *matCellDef="let team" class="double-width">
          <div class="manager-container">
            <div class="avatar">
              <img *ngIf="team.manager.avatarIconURL" src="team.manager.avatarIconURL" alt="{{ 'TEAMS.MEMBER_AVATAR' | translate }}" />
              <mat-icon class="account-circle-icon" *ngIf="!team.manager.avatarIconURL">account_circle</mat-icon>
            </div>
            <div class="manager-details">
              <span class="manager-full-name">
                {{team.manager.name}} {{team.manager.surname}}
              </span>
              <span class="manager-email">
                {{team.manager.email}}
              </span>
            </div>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="double-width"> {{ isXSmall ? ('TEAMS.TOTAL' | translate: { count: allTeams.length }) : '' }} </td>
      </ng-container>

      <!-- Portfolios Column -->
      <ng-container matColumnDef="portfolios">
        <th mat-header-cell *matHeaderCellDef class="portfolios-column"> {{ 'TEAMS.PORTFOLIOS' | translate }} </th>
        <td mat-cell *matCellDef="let team" class="portfolios-column"> {{ team.resources | resourceCount: 'portfolio' }} </td>
        <td mat-footer-cell *matFooterCellDef class="portfolios-column"></td>
      </ng-container>

      <!-- Members Column -->
      <ng-container matColumnDef="members">
        <th mat-header-cell *matHeaderCellDef class="members-column"> {{ 'TEAMS.MEMBERS' | translate }} </th>
        <td mat-cell *matCellDef="let team" class="members-column">
          <div class="members-container">

            <ng-container *ngIf="!isSmall && !isXSmall; else showTotalMembers">
              <ng-container *ngIf="team.members && team.members.length > 0">
                <div *ngFor="let member of team.members | slice:0:3" class="avatar">
                  <img *ngIf="member.avatarIconURL" src="member.avatarIconURL" alt="Member Avatar" />
                  <mat-icon class="account-circle-icon" *ngIf="!member.avatarIconURL">account_circle</mat-icon>
                </div>
                <div *ngIf="team.members?.length > 3" [class.dark-background]="theme && theme.includes('dark')" class="more-members">
                  <span>
                    +{{ team.members?.length - 3 }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!team.members || team.members.length === 0">
                <div [class.dark-background]="theme && theme.includes('dark')" class="more-members no-margin-left">
                  <span>
                    0
                  </span>
                </div>
              </ng-container>
            </ng-container>

            <ng-template #showTotalMembers>
              <div class="total-members">
                <span>{{ team.members.length }}</span>
              </div>
            </ng-template>

          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="members-column"></td>
      </ng-container>

      <!-- Created At Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> {{ 'TEAMS.CREATED_AT' | translate }} </th>
        <td mat-cell *matCellDef="let team"> {{ team.createdAt | date }} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action-column"></th>
        <td mat-cell *matCellDef="let team" class="action-column">
          <button mat-icon-button class="menu" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="teams.editTeam(team)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'TEAMS.EDIT' | translate }}</span>
            </button>
            <button mat-menu-item (click)="teams.deleteTeam(team._id)">
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'TEAMS.DELETE' | translate }}</span>
            </button>
          </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef class="action-column"></td>
      </ng-container>

      <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let team; columns: displayedColumns;" (click)="teams.viewTeam(team)"></tr>
      <tr mat-footer-row class="small-height" *matFooterRowDef="displayedColumns; sticky: true"></tr>

    </table>
  </div>
  <div *ngIf="!allTeams || allTeams?.length === 0" class="empty-team">
    <section
      class="empty-state-grid">
      <div class="thumbnail">
        <i></i>
        <div class="content">
          <ng-container>
            <mat-icon disabled>group_off</mat-icon>
            <p>{{ 'TEAMS.NO_TEAMS_CREATED' | translate }}</p>
          </ng-container>
        </div>
      </div>
    </section>
  </div>
</div>
