<div
    #annotationsBody
    class="annotationsBody"
    [class.dark]="this.theme && this.theme.includes('dark')">
  <div
      *ngIf="permissionsService.permissions$.value?.canCreate.annotations && stateNewComment"
      class="header">
    <!--<button
        *ngIf="!stateNewComment"
        mat-raised-button
        color="accent"
        class="add"
        [disabled]="stateNewComment"
        (click)="setNewComment(true)">
      <mat-icon class="rtl-mirror">playlist_add</mat-icon>
      {{ 'SIDEBAR.ANNOTATIONS.NEW' | translate }}
    </button>-->
    <div class="newComment" *ngIf="stateNewComment">
      <ng-form [formGroup]="formComments">
        <div class="selection three" *ngIf ="selectionStatus">
          <div [class.selected]="stateForm === 'Box'">
            <mat-icon class="rtl-mirror" (click)="selectForm('Box')">crop_square</mat-icon>
            <div (click)="selectForm('Box')">{{ 'SIDEBAR.ANNOTATIONS.FORM.RECTANGLE' | translate }}</div>
          </div>
          <div [class.selected]="stateForm === 'Circle'">
            <mat-icon class="rtl-mirror" (click)="selectForm('Circle')">circle</mat-icon>
            <div (click)="selectForm('Circle')">{{ 'SIDEBAR.ANNOTATIONS.FORM.CIRCLE' | translate }}</div>
          </div><!--
          <div [class.selected]="stateForm === 'Arrow'">
            <mat-icon class="rtl-mirror" (click)="selectForm('Arrow')">arrow_forward</mat-icon>
            <div (click)="selectForm('Arrow')">{{ 'SIDEBAR.ANNOTATIONS.FORM.ARROW' | translate }}</div>
          </div>-->
          <div [class.selected]="stateForm === 'Polygon'">
            <mat-icon class="rtl-mirror" (click)="selectForm('Polygon')">gesture</mat-icon>
            <div (click)="selectForm('Polygon')">{{ 'SIDEBAR.ANNOTATIONS.FORM.FREE' | translate }}</div>
          </div>
        </div>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'SIDEBAR.ANNOTATIONS.FEATURE_LABEL' | translate }}</mat-label>
          <mat-select formControlName="feature">
            <mat-optgroup *ngFor="let type of annotationTypes | keyvalue"
                [label]="'ANNOTATION.INSPECTION.ASSET_TYPE.' + type.key | translate">
              <mat-option *ngFor="let annotationType of type.value"
                  [value]="type.key+'-'+annotationType.id">
                {{ annotationType[currentLang] }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup [label]="'ANNOTATION.INSPECTION.ASSET_TYPE.general' | translate">
              <mat-option  *ngFor="let annotationType of annotationTypesGeneral"
                  [value]="'general-'+annotationType.id">
                {{ annotationType[currentLang] }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <div class="selection">
          <div
              [class.selected]="stateDimension.value === 20"
              (click)="selectDimension(20)">
            <span class="circle advisory"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.ADVISORY' | translate }}</div>
          </div>
          <div
              [class.selected]="stateDimension.value === 40"
              (click)="selectDimension(40)">
            <span class="circle low"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.LOW' | translate }}</div>
          </div>
          <div
              [class.selected]="stateDimension.value === 60"
              (click)="selectDimension(60)">
            <span class="circle middle"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.MIDDLE' | translate }}</div>
          </div>
          <div
              [class.selected]="stateDimension.value === 80"
              (click)="selectDimension(80)">
            <span class="circle high"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.HIGH' | translate }}</div>
          </div>
          <div
              [class.selected]="stateDimension.value === 100"
              (click)="selectDimension(100)">
            <span class="circle urgent"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.URGENT' | translate }}</div>
          </div>
          <!--
          <div
              [class.selected]="stateDimension.value === 'own'"
              (click)="selectDimension('own')">
            <span class="circle own"></span>
            <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.CUSTOM' | translate }}</div>
          </div>-->
        </div>

        <mat-form-field>
          <mat-label>{{ 'SIDEBAR.ANNOTATIONS.COMMENT' | translate }}</mat-label>
          <textarea cdkTextareaAutosize formControlName="comment" matInput></textarea>
        </mat-form-field>

        <h4 class="subheader" *ngIf ="!selectionStatus">Temperature:</h4>
        <div class="Temperature four" *ngIf ="!selectionStatus" [ngStyle]="{
          'grid-template-columns': (formComments.get('average').value && formComments.get('minimum').value)? '48% 48%' : '100%',
          'gap': (formComments.get('average').value && formComments.get('minimum').value )? '4%' : '0%'}">
          <div *ngIf="formComments.get('average').value">
            <mat-form-field>
              <mat-label>Average</mat-label>
              <textarea cdkTextareaAutosize formControlName="average" matInput readonly></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="formComments.get('minimum').value">
            <mat-form-field>
              <mat-label>Minimum</mat-label>
              <textarea cdkTextareaAutosize formControlName="minimum" matInput readonly></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="formComments.get('maximum').value">
            <mat-form-field>
              <mat-label>Maximum</mat-label>
              <textarea cdkTextareaAutosize formControlName="maximum" matInput readonly></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="formComments.get('delta').value">
            <mat-form-field>
              <mat-label>Delta</mat-label>
              <textarea cdkTextareaAutosize formControlName="delta" matInput readonly></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="actions">
          <button mat-button (click)="sendComment()" [disabled]="checkSend">
            <mat-icon class="rtl-mirror">send</mat-icon> {{ 'SAVE' | translate }}
          </button>
          <button mat-button *ngIf ="selectionStatus || this.editing >= 0" (click)="setNewComment(false)">{{ 'CANCEL' | translate }}</button>
          <button mat-button *ngIf ="!selectionStatus && (this.editing === undefined || this.editing < 0)" (click)="combinedActions()">Remove</button>
        </div>
      </ng-form>
    </div>
  </div>
  <div class="header" *ngIf="!stateNewComment">
    <button
      mat-stroked-button
      color="accent"
      class="show-all"
      [disabled]="stateNewComment"
      (click)="toggleAnnotationDialog()">
      <mat-icon class="rtl-mirror">launch</mat-icon>
      {{ 'SIDEBAR.ANNOTATIONS.VIEW_ALL_ANNOTATIONS' | translate }}
    </button>
  </div>
  <div class="body" *ngIf="fileModel && fileModel.annotations">
    <div
        *ngFor="let annotation of fileModel.annotations; let i = index; first as isFirst"
        class="comment"
        [class.border]="!isFirst">
      <div class="meta">
        <div class="annotation-title">
          <span *ngIf="annotation.annoID">{{ annotation.annoID }} - </span>
          <span>{{ inspectionService.getFeature(annotation.feature, currentLang) }}</span>
          <mat-icon
          [ngStyle]="{ 'color': getColor(annotation.stateDimension), 'height': 'auto', 'width': 'auto', 'font-size': '20px', 'font-weight': '500' }"
          [class.box]="annotation.stateForm === 'Box'"
          [class.circle]="annotation.stateForm === 'Circle'"
          [class.polygon]="annotation.stateForm === 'Polygon'"
          class="rtl-mirror">{{ formToIcon(annotation.stateForm) }}</mat-icon>
        </div>
        <div class="annotation-subtitle">
          <span *ngIf="annotation.modified"> <span *ngIf="annotation.userName">{{ annotation.userName }} | </span>{{ annotation.modified | date:'dd.MM.yyyy' }}</span>
          <span *ngIf="annotation.modified && !annotation.modified.endsWith('T00:00:00Z')"> {{ annotation.modified | date:'HH:mm:ss' }}</span>
        </div>
      </div>
      <div class="content" *ngIf="annotation.comment">
        {{ annotation.comment }}
      </div>
      <table class="content" *ngIf="annotation.averageTemperature">
        <tr *ngIf="annotation.averageTemperature">
          <td class="tableLine">Average Temperature:</td>
          <td>{{ annotation.averageTemperature }} °C</td>
        </tr>
        <tr *ngIf="annotation.deltaTemperature">
          <td class="tableLine">Delta Temperature:</td>
          <td>{{ annotation.deltaTemperature }} °C</td>
        </tr>
        <tr *ngIf="annotation.minimumTemperature">
          <td class="tableLine">Minimum Temperature:</td>
          <td>{{ annotation.minimumTemperature }} °C</td>
        </tr>
        <tr *ngIf="annotation.maximumTemperature">
          <td class="tableLine">Maximum Temperature:</td>
          <td>{{ annotation.maximumTemperature }} °C</td>
        </tr>
      </table>
      <div class="tasks">
        <div class="tasks-headline">
          <div class="left">
            <span>Tasks: </span>
            <mat-icon fontSet="material-icons-outlined">info</mat-icon>
          </div>
          <button mat-icon-button color="accent" (click)="CreateTask(annotation)"><mat-icon>add</mat-icon></button>
        </div>
        <div class="tasks-list">
          <div class="task" *ngFor="let task of annotation.tasks" (click)="openTaskPopup(task)">
            <div class="left">
              <div class="avatar" matTooltip="{{ task.assigneeEmail ??  task.assignee?.email }}">
                {{
                  (task.assignee?.name && task.assignee?.surname
                    ? task.assignee?.name[0] + task.assignee?.surname[0]
                    : task.assignee?.email?.slice(0, 2) ?? task.assigneeEmail?.slice(0, 2))
                  | uppercase
                }}
              </div>
              <span class="task-title mat-header-cell">{{ task.title?? 'Untitled' }}</span>
            </div>
            <span class="task-status mat-header-cell">{{ ('TASKS.STATUS.'+(task.status | uppercase)) | translate }}</span>
          </div>
        </div>
      </div>

      <button
          *ngIf="!stateNewComment && (permissionsService.permissions$.value?.canUpdate.annotations || permissionsService.permissions$.value?.canDelete.annotations)"
          mat-icon-button
          class = "menu"
          [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="permissionsService.permissions$.value?.canUpdate.annotations"
            mat-menu-item
            (click)="edit(i)">
          <mat-icon>edit</mat-icon>
          <span>{{ 'SIDEBAR.ANNOTATIONS.MODIFY' | translate }}</span>
        </button>
        <button *ngIf="permissionsService.permissions$.value?.canDelete.annotations"
            mat-menu-item
            (click)="delete(i)">
          <mat-icon color="warn">delete</mat-icon>
          <span>{{ 'SIDEBAR.ANNOTATIONS.DELETE' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="footer">
    <button
      color="accent"
      [disabled]="!fileModel?.folderID"
      (click)="generatePDF()"
      mat-raised-button
      title="Generate PDF"
      type="button">
      <mat-icon>mode_edit_outline</mat-icon>
      {{ 'SIDEBAR.ANNOTATIONS.GENERATE-PDF-REPORT' | translate | uppercase }}
    </button>
  </div>
</div>
