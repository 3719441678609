<div class="mat-dialog-header">
  <h2>{{ 'SOLAR_SITE.UPLOAD_DIALOG.ADD_FILE_TO_DISPLAY' | translate }}</h2>
  <p class="subtitle">
    {{ 'SOLAR_SITE.UPLOAD_DIALOG.SELECT_FILE_TYPE_DESCRIPTION' | translate }}
  </p>
</div>

<mat-dialog-content class="dialog-content">
  <section>
    <h5>{{ 'SOLAR_SITE.UPLOAD_DIALOG.STEP_SELECT_FILE_TYPE' | translate }}</h5>
    <div class="file-type-options">
      <div class="file-type-box" [class.selected]="selectedType === 'design'" (click)="selectFileType('design')">
        <div>{{ 'SOLAR_SITE.UPLOAD_DIALOG.DESIGN_SURFACE' | translate }}</div>
        <small>{{ 'SOLAR_SITE.UPLOAD_DIALOG.GEOTIFF_FILES' | translate }}</small>
      </div>
      <div class="file-type-box" [class.selected]="selectedType === 'thermal'" (click)="selectFileType('thermal')">
        <div>{{ 'SOLAR_SITE.UPLOAD_DIALOG.THERMAL_DESIGN_SURFACE' | translate }}</div>
        <small>{{ 'SOLAR_SITE.UPLOAD_DIALOG.THERMAL_GEOTIFF_FILES' | translate }}</small>
      </div>
      <div class="file-type-box" [class.selected]="selectedType === 'geojson'" (click)="selectFileType('geojson')">
        <div>{{ 'SOLAR_SITE.UPLOAD_DIALOG.GEO_REFERENCED' | translate }}</div>
        <small>{{ 'SOLAR_SITE.UPLOAD_DIALOG.GEOJSON_FILES' | translate }}</small>
      </div>
    </div>
  </section>

  <section>
    <h5>{{ 'SOLAR_SITE.UPLOAD_DIALOG.STEP_SELECT_A_FILE' | translate }}</h5>
    <div class="file-drop-area"
      (click)="!selectedFile && fileInput.click()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.dragging]="isDragging">
      <ng-container *ngIf="!selectedFile; else filePreview">
        <p>{{ 'SOLAR_SITE.UPLOAD_DIALOG.DROP_OR_SELECT_FILE' | translate }}</p>
        <small *ngIf="selectedType === 'geojson'">{{ 'SOLAR_SITE.UPLOAD_DIALOG.UPLOAD_GEOJSON_FILE' | translate }}</small>
        <small *ngIf="selectedType !== 'geojson'">{{ 'SOLAR_SITE.UPLOAD_DIALOG.UPLOAD_TIFF_FILE' | translate }}</small>
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
        <button mat-raised-button color="primary" (click)="fileInput.click(); $event.stopPropagation()">Select file</button>
      </ng-container>

      <ng-template #filePreview>
        <p>{{ selectedFile?.name }}</p>
        <small>{{ 'SOLAR_SITE.UPLOAD_DIALOG.READY_TO_UPLOAD' | translate }}</small>
        <button mat-button color="warn" (click)="removeFile($event)">
          {{ 'REMOVE' | translate }}
        </button>
      </ng-template>

      <input type="file"
        #fileInput
        hidden
        [accept]="getAcceptTypes()"
        (change)="onFileSelected($event)" />
    </div>
  </section>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="onCancel()">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-raised-button color="primary" [disabled]="!selectedFile || !selectedType" (click)="onUpload()">
    {{ 'UPLOAD' | translate }}
  </button>
</mat-dialog-actions>
