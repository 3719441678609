<div [class.dark]="this.theme && this.theme.includes('dark')">
  <h2 mat-dialog-title>
    {{ inputData.selectFolder ? 'Select Folder' : (inputData.selectFile ? 'Select File' : 'Select Item') }}
  </h2>

  <mat-dialog-content>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last">
      <button
          draggable="false"
          mat-button
          (click)="navigateToFolder(breadcrumb.id)"
          [disabled]="breadcrumb.id === undefined"
          type="button">{{ i ? breadcrumb.label : (breadcrumb.label | translate) }}</button>
      <mat-icon
            *ngIf="!last"
            class="rtl-mirror separator">chevron_right</mat-icon>
    </ng-container>

    <mat-table
        #table
        [dataSource]="dataSource"
        matSort
        matSortActive="modified"
        matSortDirection="desc"
        matSortDisableClear>

      <ng-container matColumnDef="avatar-checkbox">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>

        <mat-cell *matCellDef="let row">
          <mat-icon
              *ngIf="!filesService.canViewImage(row)"
              class="avatar-icon">{{ filesService.getFileIcon(row) }}</mat-icon>

          <img
              *ngIf="filesService.canViewImage(row)"
              alt="{{ row.name }}"
              class="mat-thumbnail-img checkerboard-background"
              draggable="false"
              src="{{ row.iconLink }}">
        </mat-cell>

        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell
            *matHeaderCellDef
            class="x5"
            dir="auto">{{ 'NAME' | translate }}</mat-header-cell>

        <mat-cell
            *matCellDef="let row"
            class="x5"
            dir="auto">
            <a class="pointer" *ngIf="row.isFolder" (click)="handleFolderClick(row)">
              {{ row.name }}
            </a>
            <a class="pointer" *ngIf="!row.isFolder" (click)="handleFileClick(row)">
              {{ row.name }}
            </a>
        </mat-cell>

        <mat-footer-cell
            *matFooterCellDef
            class="x5"
            dir="auto"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="taken">
        <mat-header-cell
            *matHeaderCellDef
            class="x2"
            dir="auto"
            start="desc">{{ 'FILE.TAKEN' | translate }}</mat-header-cell>

        <mat-cell
            *matCellDef="let row"
            class="x2"
            dir="auto">{{ row.taken | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

        <mat-footer-cell
            *matFooterCellDef
            class="x2"
            dir="auto"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell
            *matHeaderCellDef
            class="x2"
            dir="auto"
            start="desc">{{ 'CREATED' | translate }}</mat-header-cell>

        <mat-cell
            *matCellDef="let row"
            class="x2"
            dir="auto">{{ row.created | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

        <mat-footer-cell
            *matFooterCellDef
            class="x2"
            dir="auto"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="modified">
        <mat-header-cell
            *matHeaderCellDef
            class="x2"
            dir="auto"
            start="desc">{{ 'MODIFIED' | translate }}</mat-header-cell>

        <mat-cell
            *matCellDef="let row"
            class="x2"
            dir="auto">{{ row.modified | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

        <mat-footer-cell
            *matFooterCellDef
            class="x2"
            dir="auto"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="select">
        <mat-header-cell
            *matHeaderCellDef
            class="x1"
            dir="auto"
            start="desc"></mat-header-cell>

        <mat-cell
            *matCellDef="let row"
            class="x1 center"
            dir="auto">
          <a class="pointer" *ngIf="!row.isFolder" (click)="handleFileClick(row)">
            <mat-icon>file_open</mat-icon>
          </a>
        </mat-cell>

        <mat-footer-cell
            *matFooterCellDef
            class="x1"
            dir="auto"></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="getDisplayedColumns; sticky: true"></mat-header-row>

      <mat-row
          *matRowDef="let row; let i = index; columns: getDisplayedColumns;"
          #matRow
          [id]="row._id"></mat-row>
    </mat-table>
  </mat-dialog-content>


  <div mat-dialog-actions>
    <button
        mat-button
        mat-dialog-close
        type="button">{{ 'CANCEL' | translate | uppercase }}</button>
    <button
        *ngIf="inputData.allowUpload"
        class="upload"
        mat-button
        (click)="filesInput.value = ''; filesInput.click()"
        type="button">{{ 'UPLOAD.FILE' | translate | uppercase }}</button>
  </div>

  <!-- hidden input field for upload -->
  <input
      #filesInput
      [accept]="uploadAcceptType"
      (change)="handleFilesInput($event.target.files)"
      class="files-input"
      type="file">

  <!-- Spinner overlay -->
  <app-spinner [fetching]="fetching"></app-spinner>
</div>
