
import { Component, Input, OnDestroy } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';

import { ComponentCanDeactivateDirective } from './../can-deactivate/component-can-deactivate.directive';
import { LanguageService } from './../i18n/language.service';
import { QueueItem } from './upload.model';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends ComponentCanDeactivateDirective implements OnDestroy {

  @Input() dir: Direction;
  queueItems?: Array<QueueItem> = [];

  constructor(
      private languageService: LanguageService,
      private uploadService: UploadService
  ) {
    super(); // Constructors for derived classes must contain a 'super' call

    this.languageService.dir$.subscribe(dir => this.dir = dir);

    this.uploadService.queueItems$.subscribe(queueItems => {
      this.queueItems = queueItems;
    });
  }

  canDeactivate(): boolean {
    return !this.queueItems.some((item: QueueItem) => item.inProgress);
  }

  handleFilesInput(files: FileList, folderID: string): void {
    this.uploadService.handleFilesInput(files, folderID);
  }

  // Start next queued upload
  nextUpload(): void {
    this.uploadService.nextUpload();
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    for (const item of this.queueItems) {
      if (item.subscription && !item.subscription.closed) {
        item.subscription.unsubscribe(); // cancel all requests
      }
    }
    this.queueItems = [];
    this.uploadService.queueItems$.next([]);
  }

  removeQueueItem(queueItem: QueueItem): void {
    this.uploadService.removeQueueItem(queueItem);
    this.nextUpload();
  }

  handleStop(item: any): void {
    if (item.name === 'generate-pdf' || item.name === 'zip') {
      this.removeQueueItem(item); // Remove the queue item since we do not have a stop feature for them
    } else {
      item.stopUpload();
      this.nextUpload();
    }
  }
}
