/* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match, import/export */
import { E_USER_DOCUMENT_TYPES } from './interfaces/user.interfaces';

export class User {
  _id?: string | object;
  provider?: string;
  active?: boolean;
  address?: string;
  created?: Date | any;
  email?: string;
  modified?: Date | any | boolean;
  name?: string;
  surname?: string;
  organization?: string;
  password?: string;
  subscription?: string;
  phone?: string;
  role?: UserRole;
  avatarURL?: string;
  avatarIconURL?: string;
  avatarThumbnailURL?: string;
  accountType?: AccountType;

  // Computed read-only fields
  avatarLink?: string;
  avatarIconLink?: string;
  avatarThumbnailLink?: string;
  accessLevel?: string;

  // Helper fields
  selected?: boolean;
  language?: USER_LANGUAGE.ARABIC | USER_LANGUAGE.SPANISH | USER_LANGUAGE.GERMAN | USER_LANGUAGE.ITALIAN | USER_LANGUAGE.FRENCH;
}

export interface UsersFilter {
  ids?: string[];
	emails?: string[];
	id?: string;
	phone?: string;
	address?: string;
	accountType?: AccountType;
  query?: string;
}

export enum UserRole {
  // Maximum = 100
  SUPERADMIN = 90, // IT Admin, can delete Files/Folders
  ADMIN = 80, // IT Admin
  MANAGER = 60, // Team manager
  CONTRIBUTOR = 40, // Contributor
  VIEWER = 20, // Read-Only user
  UNKNOWN = 0 // unknown
  // Minimum = 0
}

export enum AccountType {
  CLIENT = 'client',
  PILOT = 'pilot',
  ADMIN = 'admin',
  EDITOR = 'editor',
  SUPERADMIN = 'superAdmin'
}

export enum USER_LANGUAGE {
  ENGLISH = 'en',
  FRENCH = 'fr',
  ARABIC = 'ar',
  SPANISH = 'es',
  GERMAN = 'de',
  ITALIAN = 'it'
}

export enum Subscription {
  NO_SUBSCRIPTION = '',
  FF_PLUS = 'FFPlus',
  FF_PLUS_BUSINESS = 'FFPlusBusiness',
  FF_INSPECT_PRO = 'FFInspectPro',
}

export interface IdFields {
  issuingCountry?: string;
  number?: string;
  type?: string;
}

export interface InsuranceFields {
  company: string;
  policyNumber: string;
  totalSumInsured: string;
}

export interface OperatorFields {
  comment: string;
  issuingAuthority: string;
}

export interface LicenseFields {
  category: string;
  comment: string;
  country: string;
  number: string;
}

export type DocumentRequestFields<T extends E_USER_DOCUMENT_TYPES>  = T extends E_USER_DOCUMENT_TYPES.ID_DOCUMENT ?
  IdFields : T extends E_USER_DOCUMENT_TYPES.INSURANCE ?
    InsuranceFields : T extends E_USER_DOCUMENT_TYPES.OPERATOR ?
      OperatorFields : LicenseFields

export interface CreateUserDocumentRequest<T extends E_USER_DOCUMENT_TYPES> {
  _id?: string;
  type: T;
  description: {
    expirationDate?: string;
    ownerId?: string;
    attachmentFileId?: string | object;
  };
  fields: DocumentRequestFields<T>
  ;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserRole {
  export function getIcon(role: UserRole): string {
      switch (role) {
        case UserRole.SUPERADMIN:
          return 'person';
          case UserRole.ADMIN:
            return 'person_outline';
        case UserRole.MANAGER:
          return 'supervisor_account';
        case UserRole.CONTRIBUTOR:
          return 'edit';
        case UserRole.VIEWER:
          return 'visibility';
        default:
          return 'error';
      }
  }

  export function getLabel(role: UserRole): string {
    switch (role) {
      case UserRole.SUPERADMIN:
        return 'USER.ROLE.SUPERADMIN';
        case UserRole.ADMIN:
          return 'USER.ROLE.ADMIN';
      case UserRole.MANAGER:
        return 'USER.ROLE.MANAGER';
      case UserRole.CONTRIBUTOR:
        return 'USER.ROLE.CONTRIBUTOR';
      case UserRole.VIEWER:
        return 'USER.ROLE.VIEWER';
      default:
        return String(role);
    }
  }

  export function getValue(role: string): UserRole {
    switch (role.toUpperCase()) {
      case 'ADMIN':
        return UserRole.ADMIN;
      case 'MANAGER':
        return UserRole.MANAGER;
      case 'CONTRIBUTOR':
        return UserRole.CONTRIBUTOR;
      case 'VIEWER':
        return UserRole.VIEWER;
      default:
        return UserRole.UNKNOWN;
    }
  }
}

export class Update {
  $currentDate?: User;
  $set?: User;
  $unset?: User;
}


// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AccountType {

  export function getLabel(type: AccountType): string {
    switch (type) {
      case AccountType.PILOT:
        return 'USER.PILOT';
      case AccountType.CLIENT:
        return 'USER.CLIENT';
      case AccountType.ADMIN:
        return 'USER.ADMIN';
      case AccountType.EDITOR:
          return 'USER.EDITOR';
      case AccountType.SUPERADMIN:
          return 'USER.SUPERADMIN';
      default:
        return '';
    }
  }

}

export namespace Subscription {
  export function getLabel(subscription: Subscription): string {
    switch (subscription) {
      case Subscription.FF_PLUS:
        return 'USER.SUBSCRIPTION.FF_PLUS';
      case Subscription.FF_PLUS_BUSINESS:
        return 'USER.SUBSCRIPTION.FF_PLUS_BUSINESS';
      case Subscription.FF_INSPECT_PRO:
        return 'USER.SUBSCRIPTION.FF_INSPECT_PRO';
      default:
        return 'USER.SUBSCRIPTION.NO_SUBSCRIPTION';
    }
  }
}