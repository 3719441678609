import { Injectable } from '@angular/core';
import { PvInspectionOlmapService } from './pv-inspection-olmap.service';
import { Feature, Map } from 'ol';
import { getCenter } from 'ol/extent';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PvInspectionService } from './pv-inspection.service';
import { MatSidenav } from '@angular/material/sidenav';
import { FileModel } from '../../file/file.model';
import { Geometry, Point } from 'ol/geom';
import { Style, Circle, Fill, Stroke, Text } from 'ol/style';
import { PVModule } from '../model/pv-module.model';
import { DragBox } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root'
})

export class PvInspectionInteractionsService {
  private ngDestroy$ = new Subject();
  public pvModulesUpdates$: BehaviorSubject<any> = new BehaviorSubject({});
  public nearbyImages$: BehaviorSubject<FileModel[]> = new BehaviorSubject([]);
  public clickedImageId$: BehaviorSubject<{ imageId: string; position: { x: number; y: number } } | undefined> = new BehaviorSubject(undefined);
  private pvClickHandler: any; // ⬅️ stored handler
  private thermalClickHandler: any;

  constructor(
    private pvInspectionOlMapService: PvInspectionOlmapService,
    private pvInspectionService: PvInspectionService
  ){}

  destroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public initializeInteractions(
    map: Map,
    imagesSidenav: MatSidenav,
    imagesFolderId: string,
    layers: any[],
    imagePinStyles: Record<string, Style[]>
  ): void {
    this.pvClickHandler = (event: any) =>
      this.onMapClick(map, event, imagesSidenav, imagesFolderId, layers, imagePinStyles);
  
    map.on('click', this.pvClickHandler);
  
    map.on('pointermove', (event) => this.onPointerMove(map, event));
  
    const dragBox = new DragBox({ condition: platformModifierKeyOnly });
    map.addInteraction(dragBox);
    dragBox.on('boxend', () => this.onBoxSelect(imagesSidenav, map, dragBox, layers));
  }
  

  private deselectAllPvModules(): void {
      const previouslySelectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue()
      if (previouslySelectedFeatures || previouslySelectedFeatures?.length > 0) {
        this.pvInspectionOlMapService.clearHighlights(previouslySelectedFeatures);
      }
      this.pvInspectionOlMapService.setSelectedFeatures([]);
  }

  private onNearByImageClick(clickedNearByImageFeature: Feature, event): void {
    const imageId = clickedNearByImageFeature.getId() as string;
    this.clickedImageId$.next({ 
      imageId, 
      position: { x: event.pixel[0], y: event.pixel[1] } // Convert OpenLayers coordinates to pixel positions
    }); 
  }

  private onPvModuleClickWithCtrl(alreadySelectedFeatures: Feature[], clickedPvModuleFeature: Feature):void {
    const isSelected = alreadySelectedFeatures?.includes(clickedPvModuleFeature);
    if (isSelected) {
      this.pvInspectionOlMapService.clearHighlights([clickedPvModuleFeature]);
      this.pvInspectionOlMapService.setSelectedFeatures(alreadySelectedFeatures.filter(f => f !== clickedPvModuleFeature));
    } else {
      this.pvInspectionOlMapService.highlightFeatures([clickedPvModuleFeature]);
      this.pvInspectionOlMapService.setSelectedFeatures([...(alreadySelectedFeatures ?? []), clickedPvModuleFeature]);
    }
  }

  private onPvModuleClickWithoutCtrl(clickedPvModuleFeature: Feature): void {
    this.deselectAllPvModules();
    this.pvInspectionOlMapService.setSelectedFeatures([clickedPvModuleFeature]);
    this.pvInspectionOlMapService.highlightFeatures([clickedPvModuleFeature]);
  }

  private onMapClick(
    map: Map,
    event: any,
    imagesSidenav: MatSidenav,
    imagesFolderId: string,
    layers: any[],
    imagePinStyles: Record<string, Style[]>,
  ): void {

    const features = map.getFeaturesAtPixel(event.pixel);
    if (!features || features.length === 0) {
      this.deselectAllPvModules()
      this.removeDroneImagesRelatedUIs(imagesSidenav, map, layers)
      return;
    }
  
    // Check for nearby image click
    const clickedNearByImageFeature = features.find(feature => feature.get('type') === 'image') as Feature<Geometry>;
    if (clickedNearByImageFeature) {
      this.onNearByImageClick(clickedNearByImageFeature, event)     
      return;
    }
  
    // Check for PV module click
    const clickedPvModuleFeature = features.find(feature => feature.get('type') === 'pvModule') as Feature<Geometry>;
    if (clickedPvModuleFeature) {
      const alreadySelectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue();
      const isCtrlPressed = event.originalEvent.ctrlKey || event.originalEvent.metaKey; 

      if (isCtrlPressed) {
        this.onPvModuleClickWithCtrl(alreadySelectedFeatures, clickedPvModuleFeature)
      } else {
        this.onPvModuleClickWithoutCtrl(clickedPvModuleFeature)
      }

      const newSelectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue();
      if (newSelectedFeatures.length === 1) {
        const center = getCenter(clickedPvModuleFeature.getGeometry().getExtent())
        this.pvInspectionService.fetchNearByImages(imagesFolderId,center,1).pipe(takeUntil(this.ngDestroy$))
        .subscribe(images => {
          if (images.length > 0) {
            this.nearbyImages$.next(images)
            imagesSidenav.open();
            this.pvInspectionOlMapService.placeImagesPinsOnMap(images, layers, map, imagePinStyles)
          }
        })
        setTimeout(() => {          
          this.pvInspectionOlMapService.centerOnFeature(map, clickedPvModuleFeature);
        }, 100);
      } else {
        this.removeDroneImagesRelatedUIs(imagesSidenav,map,layers)
      }
    }
  }

  private removeDroneImagesRelatedUIs(imagesSidenav: MatSidenav ,map: Map,layers: any[]): void {
    this.nearbyImages$.next(undefined)
    this.pvInspectionOlMapService.clearAndRemoveDroneCapturesLayer(map,layers)
    imagesSidenav.close();
  }

  private handlePVModuleClick(
    pvModuleFeature: Feature, 
    pvModule: PVModule, 
    layers: any[], 
    map: Map, 
    imagesFolderId: string, 
    imagePinStyles: any,
    openSidenav: (type: 'module' | 'layers' | 'thermal' | 'modules') => void,
    imagesSidenav,
    hoveredFeature,
    alreadySelectedFeatures: Feature[]
  ): void {
    const newClickedFeature = pvModuleFeature as Feature;

    if ( this.isFeatureSelected(newClickedFeature) ) {
      return
    } else {
      if (hoveredFeature && hoveredFeature !== newClickedFeature) {
        this.pvInspectionOlMapService.clearHighlights([hoveredFeature]);
        this.pvInspectionOlMapService.setHoveredFeature(undefined);
      }

      if (alreadySelectedFeatures) {
        this.pvInspectionOlMapService.clearHighlights(alreadySelectedFeatures);
      }

      this.pvInspectionOlMapService.setSelectedFeatures([newClickedFeature]);
      this.pvInspectionOlMapService.highlightFeatures([newClickedFeature]);
      setTimeout(() => {
        this.pvInspectionOlMapService.zoomOnFeature(map, newClickedFeature)        
      }, 10);

    }

    // Set nearby images
    const center = getCenter(pvModuleFeature.getGeometry().getExtent())
    this.pvInspectionService.fetchNearByImages(imagesFolderId,center,1).pipe(takeUntil(this.ngDestroy$))
    .subscribe(images => {
      if (images.length > 0) {
        this.nearbyImages$.next(images)
        imagesSidenav.open();
        this.pvInspectionOlMapService.placeImagesPinsOnMap(images, layers, map, imagePinStyles)
      }
    })

    openSidenav('module')          
  }

  private onBoxSelect(
    imagesSidenav: MatSidenav,
    map: Map,
    dragBox: DragBox,
    layers: any[],
  ): void {
    const extent = dragBox.getGeometry().getExtent();
    const pvModulesOlLayer = this.pvInspectionOlMapService.getPvModulesLayer(layers);
    if (!pvModulesOlLayer) return;
  
    const source = pvModulesOlLayer.getSource();
    if (!source) return;
  
    const selectedFeatures = source.getFeaturesInExtent(extent).filter(
      feature => feature.get('type') === 'pvModule'
    );
  
    if (selectedFeatures.length > 0) {
      this.removeDroneImagesRelatedUIs(imagesSidenav,map,layers)
      const previouslySelectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue()
      if (previouslySelectedFeatures?.length > 0) {
        this.pvInspectionOlMapService.clearHighlights(this.pvInspectionOlMapService.getSelectedFeatures$().getValue());
      }
      this.pvInspectionOlMapService.highlightFeatures(selectedFeatures);
      this.pvInspectionOlMapService.setSelectedFeatures(selectedFeatures);
    }
  }

  private onPointerMove(map: Map, event: any): void {
    // if (event.dragging) return;
  
    // const pixel = map.getEventPixel(event.originalEvent);
    // const features = map.getFeaturesAtPixel(pixel);
    // const selectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue();
    // let hoveredFeature = this.pvInspectionOlMapService.getHoveredFeature$().getValue();
  
    // const pvModuleFeature = features?.find(feature => feature.get('type') === 'pvModule') as Feature<Geometry>;
  
    // if (pvModuleFeature) {
    //   map.getTargetElement().style.cursor = 'pointer';
  
    //   if (this.isFeatureSelected(pvModuleFeature)) {
    //     // If hovering over a new module, clear the previous one
    //     if (hoveredFeature && hoveredFeature !== clickedFeature && hoveredFeature !== pvModuleFeature) {
    //       this.pvInspectionOlMapService.clearHighlights([hoveredFeature]);
    //     }
  
    //     if (hoveredFeature !== pvModuleFeature) {
    //       this.pvInspectionOlMapService.setHoveredFeature(pvModuleFeature);
    //       this.applyPVModuleHoverStyle(pvModuleFeature, clickedFeature);
    //     }
    //   } else {
    //     // If moving from a hovered module to the clicked module, reset the hovered one
    //     if (hoveredFeature && hoveredFeature !== clickedFeature) {
    //       this.pvInspectionOlMapService.clearHighlight(hoveredFeature);
    //       this.pvInspectionOlMapService.setHoveredFeature(undefined);
    //     }
    //   }
    // } else {
    //   // Reset cursor and unhighlight if no pvModule is hovered
    //   if (hoveredFeature && hoveredFeature !== clickedFeature) {
    //     this.pvInspectionOlMapService.clearHighlight(hoveredFeature);
    //     this.pvInspectionOlMapService.setHoveredFeature(undefined);
    //   }
    //   map.getTargetElement().style.cursor = '';
    // }
  }

  public applyPVModuleHoverStyle(feature: Feature, clickedFeature: Feature): void {
    if (feature !== clickedFeature) {
      this.pvInspectionOlMapService.highlightFeatures([feature])
    }
  }


  // ---------------------------- Thermal Interactions ------------------------
  public handleThermalMeasurementToggle(
    map: Map,
    measuring: boolean,
    clickHandler: (e: any) => void
  ): void {
    if (measuring) {
      if (this.pvClickHandler) {
        map.un('click', this.pvClickHandler);
      }
      this.thermalClickHandler = clickHandler;
      map.on('click', this.thermalClickHandler);
    } else {
      if (this.thermalClickHandler) {
        map.un('click', this.thermalClickHandler);
      }
      if (this.pvClickHandler) {
        map.on('click', this.pvClickHandler);
      }
    }
  }

  public handleThermalMeasurementClick(
    event: any,
    thermalLayer: any,
    temperatureLayer: VectorLayer<VectorSource>
  ): void {
    const pixel = event.pixel;
    const temp = thermalLayer?.getData?.(pixel)?.[0];

    if (temp !== undefined) {
      const pointFeature = new Feature({
        geometry: new Point(event.coordinate),
        temperature: temp,
      });

      pointFeature.setStyle(this.getTemperatureStyle(temp));
      temperatureLayer.getSource().addFeature(pointFeature);
    }
  }

  private getTemperatureStyle(temp: number): Style {
    let color = '#2979ff';
    if (temp > 60) color = 'red';
    else if (temp > 30) color = 'orange';
    else if (temp > 10) color = 'yellow';

    return new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({ color }),
        stroke: new Stroke({ color: 'white', width: 1 }),
      }),
      text: new Text({
        text: `${temp.toFixed(1)}°C`,
        font: '12px Arial',
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 2 }),
        offsetY: -12,
      }),
    });
  }

  // ---------------------------- Helpers ------------------------
  private isFeatureSelected(feature: Feature): boolean {
    const alreadySelectedFeatures = this.pvInspectionOlMapService.getSelectedFeatures$().getValue();
    return alreadySelectedFeatures.some(alreadySelectedFeature => alreadySelectedFeature === feature);
  }
}
