<mat-sidenav-container class="map-container">
  <!-- Sidebar (Sidenav) for Feature Details -->
  <mat-sidenav #sidenav mode="side" position="end"   class="custom-sidenav" fixedInViewport="false" [opened]="false">
    <div class="sidenav-header">
      <div class="left">
        <ng-container [ngSwitch]="clickedFeature?.get('measurement')?.type">
          <mat-icon *ngSwitchCase="'length'" svgIcon="length"></mat-icon>
          <mat-icon *ngSwitchCase="'volume'" svgIcon="volume"></mat-icon>
          <mat-icon *ngSwitchDefault svgIcon="measure_area"></mat-icon>
        </ng-container>
        <span *ngIf="clickedFeature?.get('measurement')?.type">{{ ( 'MEASURE.TYPE.'+( clickedFeature?.get('measurement')?.type | uppercase )+ "_DETAILS" ) | translate }}</span>
      </div>
      <div class="right">
        <mat-icon matTooltip="{{ 'DELETE' | translate}}" class="material-icons-outlined" (click)="deleteMeasurement(clickedFeature)">delete</mat-icon>
        <mat-icon *ngIf="!clickedFeature?.get('saved')" matTooltip="{{ 'SAVE' | translate}}" class="material-icons-outlined" (click)="saveMeasurement(clickedFeature)">save</mat-icon>
        <mat-divider vertical></mat-divider>
        <mat-icon class="material-icons-outlined" (click)="unselectMeasurement()">close</mat-icon>
      </div>
    </div>
    <div>

      <mat-form-field appearance="outline" class="input-title">
        <mat-label>Title here</mat-label>
        <input matInput placeholder="Placeholder" [(ngModel)]="clickedFeature?.get('measurement').title" (ngModelChange)="onTitleChange()">
      </mat-form-field>

      <div class="details" *ngIf="clickedFeature?.get('measurement').type === 'volume'">
        <div class="input">
          <mat-form-field class="selection" [ngStyle]="clickedFeature?.get('measurement').selectedVolumeApproach === 'volumeCustomElevation' ? { 'width': '63%' } : { 'width': '100%' }">
            <mat-label>{{ 'MEASURE.TOOLTIP.VOLUME.TITLE' | translate}}</mat-label>
            <mat-select [(value)]="clickedFeature?.get('measurement').selectedVolumeApproach" (selectionChange)="onVolumeApproachChange($event)">
              <!--<mat-option value="volumeAverageLowestPoint">Lowest Point (Avg.)</mat-option>-->
              <mat-option value="volumeRealLowestPoint">{{ 'MEASURE.TOOLTIP.VOLUME.LOWEST_POINT' | translate}}</mat-option>
              <mat-option value="volumeAverageInterpolation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_AVERAGE_INTERPOLATION' | translate}}</mat-option>
              <mat-option value="volumeLinearInterpolation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_LINEAR_INTERPOLATION' | translate}}</mat-option>
              <!-- <mat-option value="volumeCustomElevation">{{ 'MEASURE.TOOLTIP.VOLUME.CUSTOM_ELEVATION' | translate}}</mat-option> -->
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="clickedFeature?.get('measurement').selectedVolumeApproach === 'volumeCustomElevation'" class="elevation">
            <mat-label>{{ 'MEASURE.HEIGHT' | translate}}</mat-label>
            <input matInput type="number"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="clickedFeature?.get('measurement').customElevation" (ngModelChange)="onElevationChange($event)"
              min="0" max="10000" step="0.01">
          </mat-form-field>
        </div>
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.BASE_AREA' | translate }}:</td>
            <td class="info-left"></td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.value2d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.value2d">
                {{ clickedFeature.get('measurement').value2d?.toFixed(2) }} m<sup class="superscript">2</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">Capture Dates:</td>
            <td class="info-left">
              <ng-container *ngIf="selectedLeftOrder?.flightDateEnd">
                <strong>{{ selectedLeftOrder?.flightDateEnd | date:'dd.MM.yyyy' }}</strong>
              </ng-container>
            </td>
            <td class="info-right">
              <ng-container *ngIf="selectedRightOrder?.flightDateEnd">
                <strong>{{ selectedRightOrder?.flightDateEnd | date:'dd.MM.yyyy' }}</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.CUT' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.volumeCut"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.volumeCut">
                {{ clickedFeature.get('measurement').left?.volumeCut?.toFixed(2) }} m<sup class="superscript">3</sup>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.volumeCut"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.volumeCut">
                <strong>{{ clickedFeature.get('measurement').right?.volumeCut?.toFixed(2) }} m<sup class="superscript">3</sup></strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.FILL' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.volumeFill" count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.volumeFill">
                {{ clickedFeature.get('measurement').left?.volumeFill?.toFixed(2) }} m<sup class="superscript">3</sup>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.volumeFill"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }"></ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.volumeFill">
                <strong>{{ clickedFeature.get('measurement').right?.volumeFill?.toFixed(2) }} m<sup class="superscript">3</sup></strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.HEIGHT' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin">
                {{ (clickedFeature.get('measurement').left?.elevationsMax - clickedFeature.get('measurement').left?.elevationsMin)?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin">
                <strong>{{ (clickedFeature.get('measurement').right?.elevationsMax - clickedFeature.get('measurement').right?.elevationsMin)?.toFixed(2) }} m</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.TYPE.VOLUME' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.[clickedFeature.get('measurement').selectedVolumeApproach]"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.[clickedFeature.get('measurement').selectedVolumeApproach]">
                {{ clickedFeature.get('measurement').left?.[clickedFeature.get('measurement').selectedVolumeApproach]?.toFixed(2)}} m<sup class="superscript">3</sup>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.[clickedFeature.get('measurement').selectedVolumeApproach]"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.[clickedFeature.get('measurement').selectedVolumeApproach]">
                <strong>{{ clickedFeature.get('measurement').right?.[clickedFeature.get('measurement').selectedVolumeApproach]?.toFixed(2)}} m<sup class="superscript">3</sup></strong>
              </ng-container>
            </td>
          </tr>
        </table>
      </div>
      <div class="details" *ngIf="clickedFeature?.get('measurement').type === 'area'">
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.BASE_AREA' | translate }}:</td>
            <td class="info-left"></td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.value2d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.value2d">
                {{ clickedFeature.get('measurement').value2d?.toFixed(2)}} m<sup class="superscript">2</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'PDF-EDITOR.CAPTURE_DATES' | translate }}</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!selectedLeftOrder?.flightDateEnd"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="selectedLeftOrder?.flightDateEnd">
                <strong>{{ selectedLeftOrder?.flightDateEnd | date:'dd.MM.yyyy'}}</strong>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!selectedRightOrder?.flightDateEnd"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="selectedRightOrder?.flightDateEnd">
                <strong>{{ selectedRightOrder?.flightDateEnd | date:'dd.MM.yyyy'}}</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.SURFACE_AREA' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.value3d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.value3d">
                {{ clickedFeature.get('measurement').left?.value3d?.toFixed(2) }} m<sup class="superscript">2</sup>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.value3d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.value3d">
                <strong>{{ clickedFeature.get('measurement').right?.value3d?.toFixed(2) }} m<sup class="superscript">2</sup></strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_DIFFERENCE' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin">
                {{ (clickedFeature.get('measurement').left?.elevationsMax - clickedFeature.get('measurement').left?.elevationsMin)?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin">
                <strong>{{ (clickedFeature.get('measurement').right?.elevationsMax - clickedFeature.get('measurement').right?.elevationsMin)?.toFixed(2) }} m</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MIN' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.elevationsMin"
                count="1"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMin">
                {{ clickedFeature.get('measurement').left?.elevationsMin?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.elevationsMin"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMin">
                <strong>{{ clickedFeature.get('measurement').right?.elevationsMin?.toFixed(2) }} m</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MAX' | translate }}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.elevationsMax"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMax">
                {{ clickedFeature.get('measurement').left?.elevationsMax?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.elevationsMax"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMax">
                <strong>{{ clickedFeature.get('measurement').right?.elevationsMax?.toFixed(2) }} m</strong>
              </ng-container>
            </td>
          </tr>
        </table>
      </div>
      <div class="details" *ngIf="clickedFeature?.get('measurement').type === 'length'">
        <div class="info-header-container" *ngIf="clickedFeature?.get('measurement').pixels  !== undefined">
          <div class="text">
            <span class="info-header">{{ 'MEASURE.LENGTH_PROFILE' | translate}}:</span>
            <mat-icon class="info-icon-simple" aria-label="info" fontSet="material-icons-outlined" matTooltip="{{ 'MEASURE.TOOLTIP.PROFILE' | translate}}" [matTooltipPosition]="'right'">info</mat-icon>
          </div>
        </div>
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.2D_LENGTH' | translate}}:</td>
            <td class="info-left"></td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.value2d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.value2d">
                {{ clickedFeature.get('measurement').value2d?.toFixed(2)}} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'PDF-EDITOR.CAPTURE_DATES' | translate }}</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!selectedLeftOrder?.flightDateEnd"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="selectedLeftOrder?.flightDateEnd">
                <strong>{{ selectedLeftOrder?.flightDateEnd | date:'dd.MM.yyyy' }}</strong>
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!selectedRightOrder?.flightDateEnd"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="selectedRightOrder?.flightDateEnd">
                <strong>{{ selectedRightOrder?.flightDateEnd | date:'dd.MM.yyyy' }}</strong>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.3D_LENGTH' | translate}}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.value3d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.value3d">
                {{ clickedFeature.get('measurement').left?.value3d?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.value3d"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.value3d">
                {{ clickedFeature.get('measurement').right?.value3d?.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.SLOPE' | translate}}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.slope"
                count="1"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.slope">
                {{ clickedFeature.get('measurement').left?.slope?.toFixed(2) }} °
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.slope"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.slope">
                {{ clickedFeature.get('measurement').right?.slope?.toFixed(2) }} °
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_DIFFERENCE' | translate}}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin)"
                count="1" appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMax && clickedFeature?.get('measurement')?.left?.elevationsMin">
                {{ (clickedFeature.get('measurement').left?.elevationsMax - clickedFeature.get('measurement').left?.elevationsMin)?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!(clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMax && clickedFeature?.get('measurement')?.right?.elevationsMin">
                {{ (clickedFeature.get('measurement').right?.elevationsMax - clickedFeature.get('measurement').right?.elevationsMin)?.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MIN' | translate}}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.elevationsMin"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMin">
                {{ clickedFeature.get('measurement').left?.elevationsMin?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.elevationsMin"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMin">
                {{ clickedFeature.get('measurement').right?.elevationsMin?.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MAX' | translate}}:</td>
            <td class="info-left">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.left?.elevationsMax"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.left?.elevationsMax">
                {{ clickedFeature.get('measurement').left?.elevationsMax?.toFixed(2) }} m
              </ng-container>
            </td>
            <td class="info-right">
              <ngx-skeleton-loader
                *ngIf="!clickedFeature?.get('measurement')?.right?.elevationsMax"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="clickedFeature?.get('measurement')?.right?.elevationsMax">
                {{ clickedFeature.get('measurement').right?.elevationsMax?.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
        </table>
        <div class="chart">
          <ngx-skeleton-loader
            *ngIf="!myLineChart"
            count="1"
            appearance="line"
            [theme]="{ 'height': '130px' }"
            [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
          ></ngx-skeleton-loader>
          <canvas #chartCanvas [attr.id]="'canvas-container'"></canvas>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- Main Content Area -->

  <mat-sidenav-content>
    <div class="vertical-toolbar">
      <div
        matTooltip="{{'MEASURE.ACTION.MEASURE_SURFACE' | translate}}" matTooltipPosition="left"
        [class.selected]="isDrawAreaActive"
        (click)="measureArea()">
        <mat-icon svgIcon="measure_area"></mat-icon>
      </div>
      <div
        matTooltip="{{'MEASURE.ACTION.MEASURE_LENGTH' | translate}}" matTooltipPosition="left"
        [class.selected]="isDrawLengthActive"
        (click)="measureLength()">
        <mat-icon svgIcon="length"></mat-icon>
      </div>
      <div
        matTooltip="{{'MEASURE.ACTION.MEASURE_VOLUME' | translate}}" matTooltipPosition="left"
        [class.selected]="isDrawVolumeActive"
        (click)="measureVolume()">
        <mat-icon svgIcon="volume"></mat-icon>
      </div>
    </div>

    <div class="reset-div" (click)="resetView()">
      <mat-icon>refresh</mat-icon>
      <span>Reset</span>
    </div>

    <div class="map-toolbar">
      <!-- Left Order Selector -->
      <mat-form-field>
        <mat-label>{{ "ORDERS.SELECT_LEFT_ORDER" | translate }}
          <span *ngIf="selectedLeftOrder?.legacyId">FF{{selectedLeftOrder?.legacyId}}</span>
        </mat-label>
        <mat-select disableOptionCentering panelClass="myPanelClass" class="map-input" [value]="selectedLeftOrder" (selectionChange)="onLeftOrderChange($event.value)">
          <!-- Custom display for the selected value -->
          <mat-select-trigger>
            <div class="option-container">
              <span class="secondary-option-text">
                {{ selectedLeftOrder?.flightDateBegin | date:'d MMM. yyyy' }}
                <span *ngIf="!(selectedLeftOrder?.flightDateBegin | isSameDay: selectedLeftOrder?.flightDateEnd)">
                  - {{ selectedLeftOrder?.flightDateEnd | date:'d MMM. yyyy' }}
                </span>
              </span>
            </div>
          </mat-select-trigger>
          <!-- Dropdown options -->
          <mat-option *ngFor="let order of orders" [value]="order" [disabled]="selectedRightOrder?.legacyId === order.legacyId || !order.orthos ">
            <div class="option-container">
              <div class="primary-option-text">FF{{ order.legacyId }} <span *ngIf="!order.orthos" class="secondary-option-text">Processing</span></div>
              <span class="secondary-option-text">
                {{ order.flightDateBegin | date:'d MMM. yyyy' }}
                <span *ngIf="!(order.flightDateBegin | isSameDay: order.flightDateEnd)">
                  - {{ order.flightDateEnd | date:'d MMM. yyyy' }}
                </span>
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Right Order Selector -->
      <mat-form-field>
        <mat-label>{{ "ORDERS.SELECT_RIGHT_ORDER" | translate }}
          <span *ngIf="selectedRightOrder?.legacyId">FF{{selectedRightOrder?.legacyId}}</span>
        </mat-label>
        <mat-select disableOptionCentering panelClass="myPanelClass" class="map-input" [value]="selectedRightOrder" (selectionChange)="onRightOrderChange($event.value)">
          <!-- Custom display for the selected value -->
          <mat-select-trigger>
            <div class="option-container">
              <span class="secondary-option-text">
                {{ selectedRightOrder?.flightDateBegin | date:'d MMM. yyyy' }}
                <span *ngIf="!(selectedRightOrder?.flightDateBegin | isSameDay: selectedRightOrder?.flightDateEnd)">
                  - {{ selectedRightOrder?.flightDateEnd | date:'d MMM. yyyy' }}
                </span>
              </span>
            </div>
          </mat-select-trigger>
          <!-- Dropdown options -->
          <mat-option *ngFor="let order of orders" [value]="order" [disabled]="selectedLeftOrder?.legacyId === order.legacyId || !order.orthos">
            <div class="option-container">
              <div class="primary-option-text">FF{{ order.legacyId }} <span *ngIf="!order.orthos" class="secondary-option-text">Processing</span></div>
              <span class="secondary-option-text">
                {{ order.flightDateBegin | date:'d MMM. yyyy' }}
                <span *ngIf="!(order.flightDateBegin | isSameDay: order.flightDateEnd)">
                  - {{ order.flightDateEnd | date:'d MMM. yyyy' }}
                </span>
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Map Container -->
    <div id="map" class="map"></div>

    <!-- Swipe Line for Map Comparison -->
    <div id="swipe-line" class="swipe-line">
      <button class="circle" mat-fab>
        <mat-icon svgIcon="comparison"></mat-icon>
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
