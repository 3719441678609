import { MatDialog } from '@angular/material/dialog';
import { FilesSelectDialogComponent } from './../../../../../shared/files-select-dialog/files-select-dialog.component';
import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { DialogService } from '../../../../../shared/dialog/dialog.service';
import { FileModel } from '../../file.model';
import { FilesService } from '../../../files.service';
import { folderCategory, folderCategoryFilterGroup } from '../../../../../shared/folder-dialog/folder-categories';
import { KrpanoService } from '../../../../../shared/pano/krpano.service';
import { Login } from '../../../../login/login.model';
import { PermissionRole } from '../../permission.model';
import { prefix } from '../../../../../config';
import { SidebarService } from '../sidebar.service';
import { ThemeService } from '../../../../../shared/theme/theme.service'
import { OlImageService } from '../../../../../shared/ol-image/ol-image.service'
import { AccountType, UserRole } from '../../../../users/models/user.model';
import { InspectionService } from '../../../inspection/inspection.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

@Component({
  selector: 'app-sidebar-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnChanges, OnDestroy {

  @Input() fileModel: FileModel;
  @Input() login: Login;
  @ViewChild('tagsInputField') tagsInputField: ElementRef;

  addOnBlur: boolean = false;
  edit: string;
  expandMetaData = false;
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  newfileModel: FileModel;
  userRole = UserRole;
  accountType = AccountType
  filteredTags: Observable<any[]>;
  formSettings: FormGroup;
  groupOptionsCardName: Observable<folderCategory[]>;
  groupOptionsName: Observable<folderCategory[]>;
  isPano: boolean;
  permissionRole = PermissionRole;
  prefix = prefix;
  private ngDestroy$ = new Subject();
  oldxTrans:number;
  oldyTrans:number;
  removable: boolean = true;
  selectable: boolean = true;
  separatorKeysCodes = [ENTER, COMMA];
  singleFile: boolean;
  tagsInput = new FormControl();
  tagsSelectable = [
    '360_stitched',
    '3D_mesh',
    'antenna',
    'antennaInventory',
    'card',
    'dem',
    'inspection',
    'inspection3d',
    'orthomosaic',
    'other',
    'point_cloud',
    'powerline',
    'property',
    'pv',
    'serialNumber'
  ];
  theme: string;
  visible: boolean = true;

  constructor(
    private dialog: MatDialog,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public filesService: FilesService,
    private dialogService: DialogService,
    private krpanoService: KrpanoService,
    private sidebarService: SidebarService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private olImageService: OlImageService,
    private inspectionService: InspectionService,
    public permissionsService: PermissionsService
  ) {

      // filtertags is responsible for autocomplete in Tags' Input
      this.filteredTags = this.tagsInput.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this.tagsFilter(tag) : this.tagsSelectable.slice()));

    this.formSettings = this.formBuilder.group({
      panoCompass: new FormControl({ value: false }),
      panoLittlePlanet: new FormControl({ value: false }),
      name: new FormControl({ value: '' }, [
        Validators.maxLength(256),
        Validators.required
      ]),
      xTrans: new FormControl({value: 0}),
      yTrans: new FormControl({value: 0}),
      tags: this.formBuilder.array([]),
      tagsChipList: new FormControl({ value: '' }),
      test: new FormControl({ value: '' }),
      panoTitle: new FormControl({ value: '' }, [
        Validators.maxLength(256)
      ]),
      orthomosaic: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      dem: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      imagesFolderId: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      cardThumbnail: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      cardName: new FormControl({ value: '' }),
      demValueMin: new FormControl({ value: '' }),
      demValueMax: new FormControl({ value: '' }),
      pattern: new FormControl({ value: '' }, [
        Validators.maxLength(256)
      ]),
      panoView: this.formBuilder.group({
        fov: new FormControl({ value: ''}),
        h: new FormControl({ value: ''}),
        v: new FormControl({ value: '' })
      }),
      panoXML: new FormControl({ value: '' }),
      orderID: new FormControl({ value: '' }, [
        Validators.maxLength(24)
      ])
    });

    // get language for autocomplete
    this.translate.onLangChange.subscribe((event: any) => {
      this.setAutocomplete();
    });
    this.setAutocomplete();

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.inspectionService.selectedFile$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((newFile)=> {
      this.newfileModel=newFile
    })
  }

  get cardThumbnail(): AbstractControl { return this.formSettings.get('cardThumbnail'); }
  get cardName(): AbstractControl { return this.formSettings.get('cardName'); }
  get demValueMin(): AbstractControl { return this.formSettings.get('demValueMin'); }
  get demValueMax(): AbstractControl { return this.formSettings.get('demValueMax'); }
  get name(): AbstractControl { return this.formSettings.get('name'); }
  get xTrans(): AbstractControl { return this.formSettings.get('xTrans'); }
  get yTrans(): AbstractControl { return this.formSettings.get('yTrans'); }
  get orderID(): AbstractControl { return this.formSettings.get('orderID'); }
  get orthomosaic(): AbstractControl { return this.formSettings.get('orthomosaic'); }
  get panoCompass(): AbstractControl { return this.formSettings.get('panoCompass'); }
  get panoLittlePlanet(): AbstractControl { return this.formSettings.get('panoLittlePlanet'); }
  get panoTitle(): AbstractControl { return this.formSettings.get('panoTitle'); }
  get panoView(): AbstractControl { return this.formSettings.get('panoView'); }
  get panoXML(): AbstractControl { return this.formSettings.get('panoXML'); }
  get pattern(): AbstractControl { return this.formSettings.get('pattern'); }
  get tags(): FormArray { return this.formSettings.get('tags') as FormArray; }
  get test(): AbstractControl { return this.formSettings.get('test'); }
  get tagsChipList(): AbstractControl { return this.formSettings.get('tagsChipList'); }
  get dem(): AbstractControl { return this.formSettings.get('dem'); }
  get imagesFolderId(): AbstractControl { return this.formSettings.get('imagesFolderId'); }

  checkIsPano(): void {
    this.isPano = (this.fileModel.tags &&
      this.fileModel.tags.some(x => x === '360_stitched') &&
      this.fileModel.width / 2 === this.fileModel.height
    );
  }

  resetAlignment(): void{
    this.fileModel.thermalSubFileModel.xTrans=this.oldxTrans;
    this.fileModel.thermalSubFileModel.yTrans=this.oldyTrans;
  }

  checkName(): void {
    // When the file name was changed
    const folder = this.fileModel.mimeType !== 'application/vnd.folder';
    const noExtension = !/.\.[^\.]+$/.test(this.name.value);
    const bigFile = this.fileModel.size > 1024 * 1024 * 1024 * 5;
    // Check if file has no extension or if bigFile > 5 GiB
    if (folder && (noExtension || bigFile)) {
      this.translate.get([
        'FILE.NAME_WITHOUT_EXTENSION',
        'FILE.RENAMING_BIG_FILES_CAN_TAKE_A_LONG_TIME',
        'ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'
      ]).subscribe(translations => {
        const dialogRef = this.dialogService.showDialog(null, null,
          (noExtension ? translations['FILE.NAME_WITHOUT_EXTENSION'] : '') +
          (noExtension && bigFile ? '\n' : '') +
          (bigFile ? translations['FILE.RENAMING_BIG_FILES_CAN_TAKE_A_LONG_TIME'] : ''),
          translations['ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'], true) as Observable<boolean>;

        dialogRef.subscribe(confirm => {
          if (confirm) {
            this.setName();
          }
        });
      });
    } else {
      this.setName();
    }
  }

  checkSubscription(): boolean {
    return this.fileModel && this.login && this.login.checkSubscription();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.singleFile = !this.fileModel._id.toString().includes(',');

    this.setFile();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  resetForm(): void {
    let littlePlanet = false;
    if (this.singleFile){
      if (this.fileModel.pano) {
        if (this.fileModel.pano.littlePlanet === undefined) {
          littlePlanet = true;
        }
      } else {
        littlePlanet = true;
      }
      this.formSettings.reset({
        name: this.fileModel.name,
        xTrans: this.fileModel.thermalSubFileModel?.xTrans,
        yTrans: this.fileModel.thermalSubFileModel?.yTrans,
        orderID: this.fileModel.orderID,
        demValueMax: this.fileModel.dem?.max,
        demValueMin: this.fileModel.dem?.min,
        panoCompass: this.fileModel.pano?.compass,
        panoLittlePlanet: littlePlanet,
        panoTitle: this.fileModel.pano?.title,
        panoXML: this.fileModel.pano?.customXML,
        pattern: this.fileModel.pattern,
        orthomosaic: this.fileModel.inspection?.orthomosaic,
        dem: this.fileModel?.dsmFileModel?._id,
        cardThumbnail: this.fileModel.card?.thumbnail,
        cardName: this.fileModel.card?.name
      });
      if(this.tagsInputField) {
        this.tagsInputField.nativeElement.value = '';
      }

      this.tags.controls = [];
      if (this.fileModel.tags) {
        this.fileModel.tags.forEach(value => {
          this.tags.push(this.formBuilder.control(value));
        });
      }
    } else {
      this.formSettings.reset({
        orderID: '',
      });

      this.tags.controls = [];
    }
  }

  selectOrthomosaic(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.fileModel._id,
        'acceptTag': ['orthomosaic'],
        'acceptMimeType': ['image/tiff', 'application/vnd.folder']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.orthomosaic.setValue(dialog.id);
        this.setOrthomosaic();
      }
    });
  }

  selectDEM(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'legacyId': this.fileModel.orderID,
        'acceptTag': ['dem'],
        'acceptMimeType': ['image/tiff']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.dem.setValue(dialog.id);
        this.setDEM();
      }
    });
  }

  selectImagesFolder(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'legacyId': this.fileModel.orderID,
        'acceptTag': ['images-folder'],
        'selectFolder': true
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.imagesFolderId.setValue(dialog.id);
        this.setImagesFolderId();
      }
    });
  }

  selectThumbnail(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.fileModel._id,
        'acceptMimeType': ['image/jpeg', 'image/png', 'application/vnd.folder']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.cardThumbnail.setValue(dialog.id);
        this.setCardThumbnail();
      }
    });
  }

  setAutocomplete(): void {
    if (this.login && [AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType)) {
      this.groupOptionsCardName = this.formSettings.get('cardName')!.valueChanges.pipe(
        startWith(''),
        map(value => folderCategoryFilterGroup(value, this.translate.currentLang)),
      );
      if (this.fileModel && this.fileModel.isFolder) {
        this.groupOptionsName = this.formSettings.get('name')!.valueChanges.pipe(
          startWith(''),
          map(value => folderCategoryFilterGroup(value, this.translate.currentLang)),
        );
      } else {
        this.groupOptionsName = of([]);
      }
    }
  }

  setCardName(): void {
    this.makeCardObj();
    this.fileModel.card.name = this.cardName.value;
    this.setUpdate({ card: this.getCardObj() });
    this.setState('cardThumbnail', false);
  }

  setCardThumbnail(): void {
    this.makeCardObj();
    this.fileModel.card.thumbnail = this.cardThumbnail.value;
    this.setUpdate({ card: this.getCardObj() });
    this.setState('cardThumbnail', false);
  }

  setDemValues(): void {
    this.fileModel.dem = {max: Number(this.demValueMax.value), min: Number(this.demValueMin.value) };
    this.setUpdate({ dem: this.fileModel.dem });
    this.setState('dem', false);
  }

  setFile(): void {
    if (this.singleFile) {
      this.setAutocomplete();
      this.checkIsPano();
      this.edit = '';
      if(this.permissionsService.permissions$.value?.canUpdate.files) {
        if (this.fileModel?.isFolder) {
          this.name.enable();
        } else {
          this.name.disable();
        }
        this.panoTitle.enable();
      } else {
        this.name.disable();
        this.panoTitle.disable();
      }
    }
    if([AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType)) {
      this.xTrans.enable();
      this.yTrans.enable();
      this.name.enable();
      this.orderID.enable();
      this.pattern.enable();
      this.tagsChipList.enable();
    } else {
      this.xTrans.disable();
      this.yTrans.disable();
      this.orderID.disable();
      this.pattern.disable();
      this.tagsChipList.disable();
      if(this.fileModel?.parents === undefined) {
        this.name.disable();
      }
    }
    this.resetForm();
  }

  setName(): void {
    this.fileModel.name = this.name.value;
    this.setUpdate({ name: this.name.value });
    this.setState('name', false);
  }

  decreaseX() {
    const currentValue = this.formSettings.get('xTrans').value;
    const newValue = currentValue - 1;
    this.formSettings.get('xTrans').patchValue(newValue);
    this.olImageService.setxTransNew(newValue)
    this.fileModel.thermalSubFileModel.xTrans= newValue;

  }

  decreaseY() {
    const currentValue = this.formSettings.get('yTrans').value;
    const newValue = currentValue - 1;
    this.formSettings.get('yTrans').patchValue(newValue);
    this.olImageService.setyTransNew(newValue)
    this.fileModel.thermalSubFileModel.yTrans= newValue
  }

  increaseX() {
    const currentValue = this.fileModel.thermalSubFileModel.xTrans;
    const newValue = currentValue + 1;
    this.formSettings.get('xTrans').patchValue(newValue);
    this.olImageService.setxTransNew(newValue)
    this.fileModel.thermalSubFileModel.xTrans= newValue
  }

  increaseY() {
    const currentValue = this.formSettings.get('yTrans').value;
    const newValue = currentValue + 1;
    this.formSettings.get('yTrans').patchValue(newValue);
    this.olImageService.setyTransNew(newValue)
    this.fileModel.thermalSubFileModel.yTrans= newValue
  }

  setOrthomosaic(): void {
    this.makeInspectionObj();
    this.fileModel.inspection.orthomosaic = this.orthomosaic.value;
    this.setUpdate({ inspection: this.getInspectionObj() });
    this.setState('orthomosaic', false);
  }

  setDEM() {
    this.fileModel.dsmFileModelId = this.dem.value;
    this.setUpdate({ dsmFileModelId: {"$oid": this.dem.value}});
    this.setState('dem', false);
  }

  setImagesFolderId() {
    if (this.fileModel.orthoInspection) {
      this.fileModel.orthoInspection.imagesFolderId = this.imagesFolderId.value;
    } else {
      this.fileModel.orthoInspection = { imagesFolderId: this.imagesFolderId.value };
    }
    this.setUpdate({ orthoInspection: this.fileModel.orthoInspection });
    this.setState('imagesFolder', false);
  }

  setOrderID(): void {
    if (this.singleFile) {
      this.filesService.setFetchingChange(true)
      this.fileModel.orderID = Number(this.orderID.value);
      this.setUpdate({ orderID: Number(this.orderID.value) });
      this.setState('orderID', false);
    } else {
      this.updateMany({ orderID: Number(this.orderID.value), _id: this.fileModel._id});
    }
  }

  setPanoCompass(): void {
    this.makePanoObj();
    this.fileModel.pano.compass = this.panoCompass.value;
    this.setUpdate({ pano: this.getPanoObj() });
    this.filesService.forceReload();
  }

  setPanoLittlePlanet(): void {
    this.makePanoObj();
    this.fileModel.pano.littlePlanet = this.panoLittlePlanet.value;
    this.setUpdate({ pano: this.getPanoObj() });
  }

  setPanoNorth(): void {
    const panoView = this.krpanoService.viewGet();

    this.makePanoObj();
    this.fileModel.pano.compassCorrection = Number(panoView.ath) as number;
    this.setUpdate({ pano: this.getPanoObj() });
    this.filesService.forceReload();
  }

  setPanoTitle(): void {
    this.makePanoObj();
    this.fileModel.pano.title = this.panoTitle.value
    this.setUpdate({ pano: this.getPanoObj() });
    this.setState('panoTitle', false);
    this.filesService.forceReload();
  }

  setPanoView(): void {
    const panoView = this.krpanoService.viewGet();

    this.makePanoObj();
    this.fileModel.pano.view = {
      fov: Number(panoView.fov) as number,
      hlookat: Number(panoView.ath) as number,
      vlookat: Number(panoView.atv) as number
    };
    this.setUpdate({ pano: this.getPanoObj() });
  }

  setPanoXML(): void {
    this.makePanoObj();
    this.fileModel.pano.customXML = this.panoXML.value
    this.setUpdate({ pano: this.getPanoObj() });
    this.setState('panoTitle', false);
    this.filesService.forceReload();
  }

  setPattern(): void {
    this.filesService.setFetchingChange(true)
    this.fileModel.pattern = this.pattern.value
    this.setUpdate({ pattern: this.fileModel.pattern });
    this.setState('pattern', false);
  }

  setState(state: string, value: boolean): void {
    if (this.edit !== state || !value) {
      this.resetForm();
    }
    if (value) {
      this.edit = state;
    } else {
      this.edit = '';
    }
  }

  setTranslationsForOne(): void{
    this.edit = '';
    this.setUpdateThermalForOne({ xTrans: this.fileModel.thermalSubFileModel.xTrans, yTrans: this.fileModel.thermalSubFileModel.yTrans})
  }

  setTranslationsForAll(): void{
    this.edit = '';
    this.setUpdateThermalForAll({ xTrans: this.fileModel.thermalSubFileModel.xTrans, yTrans: this.fileModel.thermalSubFileModel.yTrans, _id:this.filesService.getThermalIds()})
  }

  setTags(): void {
    if (this.singleFile) {
      this.filesService.setFetchingChange(true)
      this.fileModel.tags = this.tags.controls.map(tagControl => tagControl.value);
      this.setUpdate({ tags: this.tags.controls.map(tagControl => tagControl.value) });
      this.setState('tags', false);
    } else {
      this.updateMany({ tags: this.tags.controls.map(tagControl => tagControl.value), _id: this.fileModel._id});
    }
  }

  setUpdateThermalForAll(values): void {

    this.filesService.updateMany(values)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
        this.filesService.refreshed.emit('refreshed');
        this.filesService.previousFileModel = this.fileModel;
      },
      error => {
        this.fetchingChange$.next(false);
        this.dialogService.showDialog('FILE.SAVING_FAILED', error.status, error.url, error.error);

      }
    );
  }

  setUpdateThermalForOne(values): void {
    const fileUpdates: FileModel = {};
    Object.assign(fileUpdates, { _id: this.fileModel.thermalSubFileModel._id });
    this.fileModel.modified = new Date();
    Object.assign(fileUpdates, values);
    this.sidebarService.updateOne(fileUpdates, this.fileModel);
  }

  setUpdate(values): void {
    const fileUpdates: FileModel = {};
    Object.assign(fileUpdates, { _id: this.fileModel._id });
    this.fileModel.modified = new Date();
    Object.assign(fileUpdates, values);

    this.sidebarService.updateOne(fileUpdates, this.fileModel);
  }

  tagsAdd(): void {
    const value = (this.tagsInput.value).trim();
    // Add new tag
    if (value && !this.tags.controls.some(tag => tag.value === value)) {
      this.tags.push(this.formBuilder.control(value));
      // Reset the input value
      if (this.tagsInputField.nativeElement.value) {
        this.tagsInputField.nativeElement.value = '';
        this.tagsInput.setValue('');
      }
    }
  }

  tagsFilter(name: string) {
    return this.tagsSelectable.filter(tag =>
        tag.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  tagsIcon(tag: string): string {
    switch (tag) {
      case '360_stitched':
        return '360';
      case '3D_mesh':
        return '3d_rotation';
      case 'card':
        return 'badge';
      case 'point_cloud':
        return 'grain';
      case 'dem':
        return 'landscape';
      case 'other':
        return 'more_horiz';
      case 'orthomosaic':
        return 'satellite';
      default:
        return 'summarize';
    }
  }

  tagsRemove(index: number): void {
    this.setState('tags',true)
    if (index >= 0) {
      this.tags.removeAt(index);
    }
  }

  tagsSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (value && !this.tags.controls.some(tag => tag.value === value)) {
      this.tags.push(this.formBuilder.control(value));
    }
    this.tagsInputField.nativeElement.value = '';
    this.tagsInput.setValue('');
  }

  toggleMetaData(): void {
    this.expandMetaData = !this.expandMetaData;
  }

  updateMany(fileUpdates: FileModel): void {
    this.fetchingChange$.next(true);
    this.filesService.updateMany(fileUpdates)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
        this.filesService.refreshed.emit('refreshed');
      },
      error => {
        this.fetchingChange$.next(false);
        this.dialogService.showDialog('FILE.SAVING_FAILED', error.status, error.url, error.error);
      }
    );
  }

  makeCardObj(): void {
    if (!this.fileModel.card) {
      this.fileModel.card = {};
    }
  }

  makeInspectionObj(): void {
    if (!this.fileModel.inspection) {
      this.fileModel.inspection = {};
    }
  }

  makePanoObj(): void {
    if (!this.fileModel.pano) {
      this.fileModel.pano = {};
    }
  }

  takePanoView(): void {
    this.krpanoService.lookTo(
      (this.fileModel.pano.view) ? this.fileModel.pano.view.hlookat : 0,
      (this.fileModel.pano.view) ? this.fileModel.pano.view.vlookat : 0,
      (this.fileModel.pano.view) ? this.fileModel.pano.view.fov : 0
    );
  }

  getCardObj(): any {
    const inspection = {};
    if (this.fileModel.card.thumbnail) { Object.assign(inspection, { thumbnail: this.fileModel.card.thumbnail }); }
    if (this.fileModel.card.name) { Object.assign(inspection, { name: this.fileModel.card.name }); }
    return inspection
  }

  getInspectionObj(): any {
    const inspection = {};
    if (this.fileModel.inspection.summary) { Object.assign(inspection, { summary: this.fileModel.inspection.summary }); }
    if (this.fileModel.inspection.orthomosaic) { Object.assign(inspection, { orthomosaic: this.fileModel.inspection.orthomosaic }); }
    return inspection
  }

  getPanoObj(): any {
    const pano = {};
    if (
      this.fileModel.pano.hotspots?.length > 0 ||
      this.fileModel.pano.view ||
      this.fileModel.pano.customXML ||
      this.fileModel.pano.title ||
      this.fileModel.pano.compass ||
      this.fileModel.pano.compassCorrection ||
      this.fileModel.pano.littlePlanet !== undefined
    ) {
      if (this.fileModel.pano.customXML) { Object.assign(pano, { customXML: this.fileModel.pano.customXML }); }
      if (this.fileModel.pano.littlePlanet !== undefined) {
        if (!this.fileModel.pano.littlePlanet) {
          Object.assign(pano, { littlePlanet: false });
        }
      }
      if (this.fileModel.pano.compass) { Object.assign(pano, { compass: this.fileModel.pano.compass }); }
      if (this.fileModel.pano.compassCorrection) { Object.assign(pano, { compassCorrection: this.fileModel.pano.compassCorrection }); }
      if (this.fileModel.pano.title) { Object.assign(pano, { title: this.fileModel.pano.title }); }
      if (this.fileModel.pano.view) { Object.assign(pano, { view: this.fileModel.pano.view }); }
      if (this.fileModel.pano.hotspots?.length > 0) { Object.assign(pano, { hotspots: this.fileModel.pano.hotspots }); }

      return pano;
    } else {

      return '';
    }
  }

}
