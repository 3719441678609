import { Pipe, PipeTransform } from '@angular/core';
import { Resource, ResourceType } from 'src/app/pages/teams/models/team.model';

@Pipe({
  name: 'resourceCount'
})
export class ResourceCountPipe implements PipeTransform {
  transform(resources: Resource[], resourceType: ResourceType): number {
    if (!resources || !resourceType) return 0;
    return resources.filter(resource => resource.type === resourceType).length;
  }
}