<div class="mat-dialog-header">
  <h2 *ngIf="!fetching">
    {{ 'TEAMS.TEAM' | translate }}:
    <span class="team-name">{{ team?.name }}</span>
  </h2>
  <div class="skeleton-container" *ngIf="fetching">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
      [theme]="{ 'height': '29px' }">
    </ngx-skeleton-loader>
  </div>
  <p class="subtitle">{{ 'TEAMS.VIEW_TEAM_DETAILS' | translate }}</p>
</div>

<mat-dialog-content class="dialog-content">
  <ng-container *ngIf="data.action !== 'view'">
    <div class="search-input">
      <ngx-skeleton-loader
        *ngIf="fetching"
        count="1"
        appearance="line"
        [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
        [theme]="{ 'height': '43px'}">
      </ngx-skeleton-loader>
    </div>
    <mat-form-field *ngIf="!fetching" class="search-input" appearance="outline">
      <mat-label>{{ 'TEAMS.TEAM_NAME' | translate }}</mat-label>
      <input matInput [(ngModel)]="team.name" placeholder="Enter your team name">
    </mat-form-field>

    <ng-container *ngIf="data.isAdmin">
      <mat-form-field class="search-input" appearance="outline">
        <mat-label>{{ 'TEAMS.TEAM_MANAGER' | translate }}</mat-label>
        <input
          matInput
          class="manager-input"
          [(ngModel)]="team.manager.email"
          name="managerEmail"
          [matAutocomplete]="autoManager"
          placeholder="{{ 'TEAMS.ENTER_MANAGER_DETAILS' | translate }}"
          (ngModelChange)="managerEmail$.next($event)"
          [disabled]="data.isAdmin && data.action !== 'create'"/>
      </mat-form-field>

      <mat-autocomplete #autoManager="matAutocomplete" (optionSelected)="onManagerSelected($event.option.value)">
        <mat-option *ngFor="let user of filteredUsers" [value]="user.email">
          {{ user.email }}
          <span class="secondary-option-text"> | {{ user.name }} {{ user.surname }}</span>
        </mat-option>
      </mat-autocomplete>

    </ng-container>

  </ng-container>
  <div class="content-wrapper">
    <!-- Members Section -->
    <div class="section members-section">
      <div class="title-container">
        <h3>{{ 'TEAMS.MEMBERS' | translate }}</h3>
        <button
          *ngIf="data.action !== 'view'"
          [color]="addingNewMember ? 'warn' : 'accent'"
          mat-mini-fab
          (click)="toggleNewMemberRow()">
        <mat-icon *ngIf="!addingNewMember">add</mat-icon>
        <mat-icon *ngIf="addingNewMember">remove</mat-icon>
      </button>
      </div>
      <p class="info">{{ 'TEAMS.VIEW_MEMBERS' | translate }}</p>
      <div *ngIf="!fetching" class="rows-container">
        <!-- New Member Form -->
        <div *ngIf="addingNewMember" class="new-member member">
          <form [formGroup]="newMemberForm">
            <!-- Email Field -->
            <mat-form-field class="search-input" appearance="outline">
              <mat-label>{{ 'TEAMS.MEMBER_EMAIL' | translate }}</mat-label>
              <input matInput placeholder="Enter your team member email" formControlName="email">
            </mat-form-field>

            <div class="second-line">
              <!-- Role Selection -->
              <mat-form-field appearance="outline" class="role-select">
                <mat-select formControlName="role">
                  <mat-option value="viewer">{{ 'TEAMS.ROLE_VIEWER' | translate }}</mat-option>
                  <mat-option value="editor">{{ 'TEAMS.ROLE_EDITOR' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="newMemberForm.controls['role'].hasError('required')">
                  {{ 'TEAMS.ROLE_REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <div class="second-line-actions">
                <button mat-button
                  [class.disabled]="newMemberForm.invalid"
                  (click)="saveNewMember()">
                  {{ 'TEAMS.SAVE' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="member row-container" *ngFor="let member of team?.members">
          <img *ngIf="member.avatarIconURL" src="member.avatarIconURL" alt="Avatar" class="avatar"/>
          <mat-icon *ngIf="!member.avatarIconURL" class="default-avatar">account_circle</mat-icon>
          <div class="member-details">
            <p class="member-name">{{ member.name }} {{ member.surname }}</p>
            <p class="member-email">{{ member.email }}</p>
          </div>
          <ng-container *ngIf="data.action === 'view'">
            <mat-icon *ngIf="member.role === 'viewer'" class="list-mat-icon">visibility</mat-icon>
            <mat-icon *ngIf="member.role === 'editor'" class="list-mat-icon">edit</mat-icon>
          </ng-container>
          <ng-container  *ngIf="data.action !== 'view'">
            <mat-form-field appearance="outline" class="role-select">
              <mat-select [(value)]="member.role">
                <mat-option value="viewer">{{ 'TEAMS.ROLE_VIEWER' | translate }}</mat-option>
                <mat-option value="editor">{{ 'TEAMS.ROLE_EDITOR' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon class="list-mat-icon" color="warn" (click)="deleteMember(member.email)">delete</mat-icon>
          </ng-container>
        </div>
        <ng-container *ngIf="(!team?.members || team?.members?.length === 0) && !fetching && !addingNewMember">
          <div class="no-row-content">
            <ng-container *ngIf="(team | memberRole:loginStateService.loggedUser$.value?._id) !== 'viewer' ">
              <mat-icon>person_off</mat-icon>
              <span>{{ 'TEAMS.NO_MEMBERS_ASSIGNED' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="(team | memberRole:loginStateService.loggedUser$.value?._id) === 'viewer' ">
              <mat-icon>disabled_visible</mat-icon>
              <span>{{ 'TEAMS.NO_PERMISSION_TO_VIEW_MEMBERS' | translate }}</span>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div *ngIf="fetching">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
          [theme]="{ 'height': '458.5px' }">
        </ngx-skeleton-loader>
      </div>
    </div>

    <!-- Portfolios Section -->
    <div class="section portfolios-section">
      <div class="title-container">
        <h3>{{ 'TEAMS.PORTFOLIOS' | translate }}</h3>
        <button
          *ngIf="data.action !== 'view'"
          [color]="addingNewPortfolio ? 'warn' : 'accent'"
          mat-mini-fab
          (click)="toggleNewPortfolioRow()"
          [disabled]="data.isAdmin && (!team.manager || !team.manager._id)">
          <mat-icon *ngIf="!addingNewPortfolio">add</mat-icon>
        <mat-icon *ngIf="addingNewPortfolio">remove</mat-icon>
      </button>
      </div>
      <p class="info">{{ 'TEAMS.VIEW_PORTFOLIOS' | translate }}</p>
      <div *ngIf="!fetching" class="rows-container">
        <!-- New Portfolio -->
        <div *ngIf="addingNewPortfolio" class="new-portfolio portfolio">
          <form [formGroup]="newPortfolioForm">
            <mat-form-field class="search-input" appearance="outline">
              <mat-label>{{ 'TEAMS.SELECT_PORTFOLIO' | translate }}</mat-label>
              <input
                type="text"
                matInput
                placeholder="Enter portfolio name"
                formControlName="name"
                [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPortfolioSelected($event)">
                <mat-option class="option" *ngFor="let portfolio of filteredPortfolios" [value]="portfolio">
                  {{ portfolio.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
          <div class="actions">
            <button mat-button
                [class.disabled]="newPortfolioForm.invalid"
                (click)="saveNewPortfolio()">
                {{ 'SAVE' | translate }}
              </button>
          </div>
        </div>

        <div class="portfolio row-container" *ngFor="let resource of team?.resources">
          <span class="portfolio-link">{{ resource.label }}</span>
          <ng-container *ngIf="data.action !== 'view'">
            <mat-icon class="list-mat-icon" color="warn" (click)="deletePortfolio(resource.label)">delete</mat-icon>
          </ng-container>
          <ng-container *ngIf="data.action === 'view'">
            <mat-icon class="list-mat-icon" color="accent" (click)="navigateToPortfolio(resource)">open_in_new</mat-icon>
          </ng-container>
        </div>

        <ng-container *ngIf="(!team?.resources || team?.resources?.length === 0) && !fetching && !addingNewPortfolio">
          <div class="no-row-content">
            <ng-container>
              <mat-icon>folder_off</mat-icon>
              <span>{{ 'TEAMS.NO_PORTFOLIOS_ASSIGNED' | translate }}</span>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div *ngIf="fetching">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
          [theme]="{ 'height': '458.5px' }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="dialogRef.close()">{{ 'TEAMS.CLOSE' | translate }}</button>
  <button
    *ngIf="data.action === 'update'"
    mat-button
    color="accent"
    mat-raised-button
    (click)="saveUpdate()">
    {{ 'TEAMS.SAVE' | translate }}
  </button>
  <button
    *ngIf="data.action === 'create'"
    mat-button
    color="accent"
    mat-raised-button
    (click)="createTeam()"
    [disabled]="!team.name
    || team.name === ''
    || addingNewMember
    || addingNewPortfolio
    || (data.isAdmin && (!team.manager?.email))">
    {{ 'TEAMS.CREATE_TEAM' | translate }}
  </button>
</mat-dialog-actions>
