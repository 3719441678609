import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Map } from 'ol';
import { FilesService } from '../../../files.service';
import { FileModel } from '../../../file/file.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OLMapsService } from 'src/app/shared/openlayers/maps.service';
import { Draw } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Geometry, Polygon } from 'ol/geom';
import { Annotation } from '../../../file/sidebar/annotations/annotation.model';
import { Coordinate } from 'ol/coordinate';
import { PvInspectionInteractionsService } from '../../services/pv-inspection-interactions.service';

@Component({
  selector: 'app-ol-image-mini-viewer',
  templateUrl: './ol-image-mini-viewer.component.html',
  styleUrls: ['./ol-image-mini-viewer.component.scss']
})
export class OlImageMiniViewerComponent implements OnInit, OnDestroy, OnChanges {

  @Input() fileId!: string;
  @Input() position!: { x: number, y: number }; // Receive position input
  @Output() closeViewer = new EventEmitter<void>(); // Event to notify parent
  @ViewChild('imageMap', { static: false }) imageMap!: ElementRef;
  @ViewChild('viewerContainer', { static: true }) viewerContainer!: ElementRef;

  fileModel: FileModel
  map!: Map;
  ngDestroy$ = new Subject();

  drawInteraction!: Draw;
  polygonDrawn = false;
  vectorSource = new VectorSource(); 
  vectorLayer!: VectorLayer<VectorSource<Geometry>>;
  annotation: Annotation | null = null;  // Holds the drawn annotation (geometry + comment)

  constructor(
    private filesService: FilesService,
    private oLMapsService: OLMapsService,
    private pvInspectionInteractionsService: PvInspectionInteractionsService
    
  ) {}

  ngOnInit(): void {
    this.setPosition();
    this.fetchImageDetails();
  }

  ngOnDestroy(): void {
    this.destroyMap();
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  setPosition(): void {
    if (this.viewerContainer) {
      const viewer = this.viewerContainer.nativeElement;
      viewer.style.left = `${this.position.x}px`;
      viewer.style.top = `${this.position.y}px`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileId'] && !changes['fileId'].firstChange) {
      this.fetchImageDetails();
    }
  }

  destroyMap(): void {
    if (this.map) {
      this.map.setTarget(null);
      this.map = null as any;
    }
  }

  fetchImageDetails(): void {
    this.filesService.findOne(this.fileId, {
      "annotations":1,
      "public":1,
      "hidden":1,
      "downloadable":1,
      "height":1,
      "thumbnailLink":1,
      "webViewLink":1,
      "width":1,
      "type":1,
      "master":1,
      "meta":1,
      "orderID":1,
      "yaw":1,
      "relativeAltitude":1,
      "pitch":1,
      "annotationStats":1
    })
    .pipe(takeUntil(this.ngDestroy$)).subscribe((res: any) => {
      this.fileModel = res.data as FileModel;
      this.loadImage()        
    })
  }

  loadImage(): void {
    this.destroyMap();
    this.map = this.oLMapsService.renderImage({
      extent: [0, 0, this.fileModel.width, this.fileModel.height],
      target: this.imageMap.nativeElement,
      image: this.fileModel.webViewLink,
      imagePreview: this.fileModel.thumbnailLink,
      annotations: this.fileModel.annotations,
      overVeiwMap: false,
      interactions: true,
      fileModelMatch: undefined,
      scalingFactor: undefined,
      original: this.fileModel,
      center: undefined,
    });

    // Create vector layer for drawings
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
    });

    this.map.addLayer(this.vectorLayer);
  }

  // Emit close event
  onClose(): void {
    this.destroyMap();
    this.closeViewer.emit(); // Notify the parent component
  }

  startDrawingPolygon(): void {
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }

    // Clear the previous annotation before drawing a new one
    //this.vectorSource.clear();
    this.polygonDrawn = false;
    this.annotation = { coordinates: null, comment: '' }; // Reset annotation

    this.drawInteraction = new Draw({
      source: this.vectorSource,
      type: 'Polygon',
    });

    this.drawInteraction.on('drawend', (event) => {
      const poly: Polygon = event.feature.getGeometry() as Polygon;
      const coords: Array<Array<Coordinate>> = poly.getCoordinates();
      const coordinates = JSON.parse(JSON.stringify(coords));
      this.annotation = {
        coordinates: coordinates,
        comment: ''
      };
      this.polygonDrawn = true;
      this.map.removeInteraction(this.drawInteraction);
    });

    this.map.addInteraction(this.drawInteraction);
  }
  
  private constructAnnotationRequestPayload(annotation: Annotation): any {
      const annotationPayload = {
        stateForm: 'box',
        coordinates: annotation.coordinates,
        fileID: this.fileModel._id,
        stateDimension: 20,
        feature: 'serialNumber-serial_number'
      };
        return annotationPayload;
  }

  updatePVModuleWithNewSerialNumber(data: { [key: string]: any }): void {  
    this.pvInspectionInteractionsService.pvModulesUpdates$.next(data);
  }

  saveSerialNumberAnnotation(annotation: Annotation): void {
    const payload = this.constructAnnotationRequestPayload(annotation);
  
    // Ensure the annotationData is structured as a nested object
    const annotationData = {
      identification: {
        serialNumber: annotation.comment
      }
    };
  
    // Pass the entire object to the function
    this.updatePVModuleWithNewSerialNumber(annotationData);
  
    this.annotation = null;
    this.polygonDrawn = false;
  }
}
