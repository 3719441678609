<ng-form
  [formGroup]="formSettings"
  [class.dark]="this.theme && this.theme.includes('dark')"
>
  <div *ngIf="fileModel && isPano && singleFile" class="pano">
    <p>
      <mat-icon class="rtl-mirror">public</mat-icon>
      {{ "SIDEBAR.SETTINGS.PANO.PANORAMA" | translate }}
    </p>

    <!-- Pano Title -->
    <div class="hiddenInput">
      <div>{{ "SIDEBAR.SETTINGS.PANO.TITLE" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'panoTitle'">
        <input
          matInput
          (focus)="setState('panoTitle', true)"
          formControlName="panoTitle"
        />
        <mat-error *ngIf="panoTitle.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate : { count: panoTitle.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'panoTitle'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setPanoTitle()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('panoTitle', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- Pano XML -->
    <p
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType)
      "
    >
      {{ "SIDEBAR.SETTINGS.PANO.XML" | translate }}:
      <span *ngIf="fileModel?.pano?.customXML" class="value short">{{
        fileModel?.pano?.customXML
      }}</span>
      <button
        *ngIf="edit !== 'panoXML'"
        class="editIcon"
        type="button"
        mat-icon-button
        (click)="setState('panoXML', true)"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </p>
    <div class="edit" *ngIf="edit === 'panoXML'">
      <mat-form-field>
        <textarea
          cdkTextareaAutosize
          formControlName="panoXML"
          matInput
        ></textarea>
      </mat-form-field>
      <button class="confirm" color="accent" mat-button (click)="setPanoXML()">
        {{ "SAVE" | translate }}
      </button>
      <button
        mat-button
        color="accent"
        class="confirm"
        (click)="setState('panoTitle', false)"
      >
        {{ "CANCEL" | translate }}
      </button>
    </div>

    <!-- Pano Perspektive -->
    <div
      *ngIf="
        fileModel && permissionsService.permissions$.value?.canUpdate.files
      "
      class="perspective"
    >
      {{ "SIDEBAR.SETTINGS.PANO.INIT_PERSPECTIVE" | translate }}
      <div class="minor">
        <button mat-raised-button color="accent" (click)="setPanoView()">
          <mat-icon class="white rtl-mirror">push_pin</mat-icon>
          {{ "SIDEBAR.SETTINGS.PANO.SET" | translate }}
        </button>
        <button
          class="right"
          color="accent"
          mat-raised-button
          (click)="takePanoView()"
        >
          <mat-icon class="white rtl-mirror">preview</mat-icon>
          {{ "SIDEBAR.SETTINGS.PANO.INGEST" | translate }}
        </button>
      </div>
    </div>

    <!-- Pano LittlePlanet -->
    <div
      *ngIf="
        fileModel && permissionsService.permissions$.value?.canUpdate.files
      "
      class="mat-form-field-wrapper center"
    >
      <mat-slide-toggle
        formControlName="panoLittlePlanet"
        (change)="setPanoLittlePlanet()"
      >
        {{ "SIDEBAR.SETTINGS.PANO.LITTLE_PLANET" | translate }}
      </mat-slide-toggle>
    </div>

    <!-- Pano Compass -->
    <div
      *ngIf="checkSubscription()"
      class="mat-form-field-wrapper center cuddle"
    >
      <mat-slide-toggle
        formControlName="panoCompass"
        (change)="setPanoCompass()"
      >
        {{ "SIDEBAR.SETTINGS.PANO.COMPASS" | translate }}
      </mat-slide-toggle>
    </div>

    <div *ngIf="checkSubscription() && fileModel?.pano?.compass" class="center">
      <button mat-raised-button color="accent" (click)="setPanoNorth()">
        <mat-icon class="white rtl-mirror">push_pin</mat-icon>
        {{ "SIDEBAR.SETTINGS.PANO.SET_NORTH" | translate }}
      </button>
    </div>
  </div>

  <!-- Make Public -->
  <app-sidebar-share
    *ngIf="
      fileModel &&
      (fileModel?.tags?.includes('360_stitched') ||
        fileModel.mimeType?.startsWith('video/')) &&
      permissionsService.permissions$.value?.canUpdate.files &&
      !fileModel?.isFolder
    "
    [fileModel]="fileModel"
  >
  </app-sidebar-share>

  <div *ngIf="fileModel && fileModel.thermalSubFileModel" class="Alignment">
    <p>
      <mat-icon class="rtl-mirror">photo_size_select_large</mat-icon> Thermal
      Alignment
    </p>

    <!-- Alignment -->
    <div
      class="hiddenInput"
      *ngIf="singleFile && fileModel && fileModel.thermalSubFileModel"
    >
      <div>Horizontal Alignment:</div>
      <mat-form-field [class.focus]="edit === 'xTrans'">
        <button mat-button matPrefix class="arrowLeft" (click)="decreaseX()">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <input
          matInput
          class="alignmentInput"
          (focus)="setState('xTrans', true)"
          formControlName="xTrans"
        />
        <button mat-button matSuffix class="arrowRight" (click)="increaseX()">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </mat-form-field>
      <div>Vertical Alignment:</div>
      <mat-form-field [class.focus]="edit === 'yTrans'">
        <button mat-button matPrefix class="arrowLeft" (click)="decreaseY()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <input
          matInput
          class="alignmentInput"
          (focus)="setState('yTrans', true)"
          formControlName="yTrans"
        />
        <button mat-button matSuffix class="arrowRight" (click)="increaseY()">
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="edit === 'xTrans' || edit === 'yTrans'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setTranslationsForOne()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setTranslationsForAll()"
        >
          Save for all
        </button>
      </div>
    </div>
  </div>

  <div class="info">
    <p>
      <mat-icon class="rtl-mirror">info</mat-icon>
      {{ "SIDEBAR.SETTINGS.INFO.INFO" | translate }}
    </p>

    <!-- name -->
    <div class="hiddenInput" *ngIf="singleFile">
      <div>{{ "SIDEBAR.SETTINGS.INFO.NAME" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'name'">
        <input
          matInput
          (focus)="setState('name', true)"
          formControlName="name"
          [matAutocomplete]="autoGroup"
        />
        <mat-autocomplete #autoGroup="matAutocomplete">
          <mat-optgroup
            *ngFor="let group of groupOptionsName | async"
            [label]="group.category"
          >
            <mat-option *ngFor="let name of group.names" [value]="name">
              {{ name }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <mat-error *ngIf="name.errors?.required">{{
          "VALIDATION.REQUIRED" | translate
        }}</mat-error>
        <mat-error *ngIf="name.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate : { count: name.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'name'" class="interaction">
        <button mat-button color="accent" class="confirm" (click)="checkName()">
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('name', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- orderID -->
    <div class="hiddenInput">
      <div>{{ "SIDEBAR.SETTINGS.INFO.ORDER_ID" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'orderID'">
        <input
          matInput
          autocomplete="off"
          digitOnly
          inputmode="numeric"
          maxlength="6"
          pattern="[0-9]*"
          (focus)="setState('orderID', true)"
          formControlName="orderID"
        />
        <mat-hint *ngIf="!singleFile && edit === 'orderID'">{{
          "FILE.ORDER_ID_HINT" | translate
        }}</mat-hint>
        <mat-error *ngIf="orderID.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate : { count: orderID.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'orderID'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setOrderID()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('orderID', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- tags -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType)
      "
    >
      <div>{{ "SIDEBAR.SETTINGS.INFO.TAGS" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'tags'">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let tag of tags.controls; let i = index"
            [removable]="removable"
            [selectable]="selectable"
            (removed)="tagsRemove(i)"
          >
            {{ tag.value }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>

          <input
            #tagsInputField
            [formControl]="tagsInput"
            [matAutocomplete]="auto"
            [matChipInputAddOnBlur]="addOnBlur"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (focus)="setState('tags', true)"
            (matChipInputTokenEnd)="tagsAdd()"
            matInput
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="tagsSelected($event)"
        >
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            <mat-icon aria-label="other" [innerText]="tagsIcon(tag)"></mat-icon>
            {{ tag }}
          </mat-option>
        </mat-autocomplete>

        <mat-hint *ngIf="!singleFile && edit === 'tags'">{{
          "FILE.TAGS_HINT" | translate
        }}</mat-hint>
      </mat-form-field>
      <div *ngIf="edit === 'tags'" class="interaction">
        <button mat-button color="accent" class="confirm" (click)="setTags()">
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('tags', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- DEM Values -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) &&
        fileModel?.tags &&
        fileModel?.tags.includes('dem')
      "
    >
      <div>{{ "SIDEBAR.SETTINGS.INFO.DEM.VALUES" | translate }}:</div>

      <mat-form-field class="half" [class.focus]="edit === 'demValueMin'">
        <mat-label>{{ "SIDEBAR.SETTINGS.INFO.DEM.MIN" | translate }}</mat-label>
        <input
          matInput
          autocomplete="off"
          inputmode="numeric"
          (focus)="setState('demValues', true)"
          formControlName="demValueMin"
        />
      </mat-form-field>

      <mat-form-field class="half" [class.focus]="edit === 'demValueMax'">
        <mat-label>{{ "SIDEBAR.SETTINGS.INFO.DEM.MAX" | translate }}</mat-label>
        <input
          matInput
          autocomplete="off"
          digitOnly
          inputmode="numeric"
          maxlength="6"
          pattern="[0-9]*"
          (focus)="setState('demValues', true)"
          formControlName="demValueMax"
        />
      </mat-form-field>

      <div *ngIf="edit === 'demValues'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setDemValues()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('demValues', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- Card Name -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) &&
        fileModel?.tags &&
        fileModel?.tags.includes('card')
      "
    >
      <div>{{ "SIDEBAR.SETTINGS.INFO.CARD_NAME" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'cardName'">
        <input
          type="text"
          matInput
          formControlName="cardName"
          required
          (focus)="setState('cardName', true)"
          [matAutocomplete]="autoGroup"
        />
        <mat-autocomplete #autoGroup="matAutocomplete">
          <mat-optgroup
            *ngFor="let group of groupOptionsCardName | async"
            [label]="group.category"
          >
            <mat-option *ngFor="let name of group.names" [value]="name">
              {{ name }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <mat-error *ngIf="name.errors?.pattern">{{
          "VALIDATION.USE_SAFE_CHARACTERS" | translate
        }}</mat-error>
        <mat-error *ngIf="name.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate : { count: name.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'cardName'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setCardName()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('cardName', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- Card Thumbnail -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) &&
        fileModel?.tags &&
        fileModel?.tags.includes('card')
      "
    >
      <div>
        {{ "SIDEBAR.SETTINGS.INFO.CARD_Thumbnail" | translate }}:
        <mat-icon class="rtl-mirror" (click)="selectThumbnail()"
          >colorize</mat-icon
        >
      </div>

      <mat-form-field [class.focus]="edit === 'cardThumbnail'">
        <input
          matInput
          (focus)="setState('cardThumbnail', true)"
          formControlName="cardThumbnail"
        />
        <mat-hint *ngIf="!singleFile && edit === 'orderID'">{{
          "FILE.ORDER_ID_HINT" | translate
        }}</mat-hint>
        <mat-error *ngIf="cardThumbnail.errors?.minlength">{{
          "VALIDATION.MIN_LENGTH"
            | translate
              : { count: cardThumbnail.errors?.minlength?.requiredLength }
        }}</mat-error>
        <mat-error *ngIf="cardThumbnail.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate
              : { count: cardThumbnail.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'cardThumbnail'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          [disabled]="cardThumbnail.errors"
          (click)="setCardThumbnail()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('cardThumbnail', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- Inspection Orthomosaic -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) &&
        fileModel?.isFolder &&
        fileModel?.tags &&
        fileModel?.tags.includes('inspection')
      "
    >
      <div>
        {{ "SIDEBAR.SETTINGS.INFO.ORTHOMOSAIC" | translate }}:
        <mat-icon class="rtl-mirror" (click)="selectOrthomosaic()"
          >colorize</mat-icon
        >
      </div>

      <mat-form-field [class.focus]="edit === 'orthomosaic'">
        <input
          matInput
          (focus)="setState('orthomosaic', true)"
          formControlName="orthomosaic"
        />
        <mat-hint *ngIf="!singleFile && edit === 'orderID'">{{
          "FILE.ORDER_ID_HINT" | translate
        }}</mat-hint>
        <mat-error *ngIf="orthomosaic.errors?.minlength">{{
          "VALIDATION.MIN_LENGTH"
            | translate
              : { count: orthomosaic.errors?.minlength?.requiredLength }
        }}</mat-error>
        <mat-error *ngIf="orthomosaic.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate
              : { count: orthomosaic.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'orthomosaic'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          [disabled]="orthomosaic.errors"
          (click)="setOrthomosaic()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('orthomosaic', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <!-- Related DSM Orthomosaic -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) && fileModel?.tags?.includes('orthomosaic')
      "
    >
      <div>
        Related DSM File:
        <mat-icon class="rtl-mirror" (click)="selectDEM()">colorize</mat-icon>
      </div>

      <mat-form-field [class.focus]="edit === 'dem'">
        <input matInput (focus)="setState('dem', true)" formControlName="dem" />
      </mat-form-field>
      <div *ngIf="edit === 'dem'" class="interaction">
        <button
            mat-button
            color="accent"
            class="confirm"
            [disabled]="dem.errors"
            (click)="setDEM()">{{ 'SAVE' | translate }}</button>
        <button
            mat-button
            color="accent"
            class="confirm"
            (click)="setState('dem', false)">{{ 'CANCEL' | translate }}</button>
      </div>
    </div>

    <!-- Images Folder for PV-Inspection Mode -->
    <div *ngIf="fileModel?.tags?.includes('pv-inspection')" class="hiddenInput">
      <div>
        Images folder:
        <mat-icon class="rtl-mirror"
            (click)="selectImagesFolder()">colorize</mat-icon>
      </div>
      <mat-form-field [class.focus]="edit==='imagesFolder'">
        <input matInput
            (focus)="setState('imagesFolder',true)"
            formControlName="imagesFolder">
      </mat-form-field>
      <div *ngIf="edit==='imagesFolder'"
          class="interaction">
        <button
            mat-button
            color="accent"
            class="confirm"
            [disabled]="imagesFolderId.errors"
            (click)="setImagesFolderId()">{{ 'SAVE' | translate }}</button>
        <button
            mat-button
            color="accent"
            class="confirm"
            (click)="setState('imagesFolder', false)">{{ 'CANCEL' | translate }}</button>
      </div>
    </div>

    <!-- Pattern -->
    <div
      class="hiddenInput"
      *ngIf="
        [accountType.ADMIN, accountType.SUPERADMIN].includes(
          login?.accountType
        ) && fileModel?.isFolder
      "
    >
      <div>{{ "SIDEBAR.SETTINGS.INFO.PATTERN" | translate }}:</div>

      <mat-form-field [class.focus]="edit === 'pattern'">
        <input
          #patternInput
          dir="ltr"
          autocomplete="off"
          formControlName="pattern"
          [matAutocomplete]="patternAutocomplete"
          (focus)="setState('pattern', true)"
          matInput
        />

        <mat-autocomplete
          #patternAutocomplete="matAutocomplete"
          autoActiveFirstOption
          class="pattern-autocomplete"
          mdDropdownItems
        >
          <ng-container *ngIf="!patternInput.value">
            <mat-option value="{{ prefix + '{orderID}_{yyyy-mm-dd}_{name}' }}">
              {{
                prefix +
                  "{" +
                  ("ORDER.ID" | translate) +
                  "}_{yyyy-mm-dd}_{" +
                  ("NAME" | translate) +
                  "}"
              }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>

        <mat-error *ngIf="pattern.errors?.maxlength">{{
          "VALIDATION.MAX_LENGTH"
            | translate : { count: pattern.errors?.maxlength?.requiredLength }
        }}</mat-error>
      </mat-form-field>
      <div *ngIf="edit === 'pattern'" class="interaction">
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setPattern()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          mat-button
          color="accent"
          class="confirm"
          (click)="setState('pattern', false)"
        >
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </div>

    <p *ngIf="fileModel">
      {{ "SIDEBAR.SETTINGS.INFO.CREATED" | translate }}:<br /><span
        class="value"
        >{{ fileModel?.created | date : "dd.MM.yyyy | HH:mm:ss" }}</span
      >
    </p>
    <p *ngIf="fileModel">
      {{ "SIDEBAR.SETTINGS.INFO.MODIFIED" | translate }}:<br /><span
        class="value"
        >{{ fileModel?.modified | date : "dd.MM.yyyy | HH:mm:ss" }}</span
      >
    </p>
    <p *ngIf="fileModel">
      {{ "SIDEBAR.SETTINGS.INFO.FORMAT" | translate }}:<br />
      <span class="value">
        {{ fileModel?.mimeType }}
        <span *ngIf="fileModel?.size">| {{ fileModel?.size | size }}</span>
        <span *ngIf="fileModel?.width && fileModel?.height">
          | {{ fileModel?.width + "x" + fileModel?.height + " px" }}</span
        >
      </span>
    </p>
  </div>

  <div *ngIf="fileModel && fileModel.meta" class="metaData">
    <p class="interaction" (click)="toggleMetaData()">
      <mat-icon class="rtl-mirror">description</mat-icon>
      {{ "SIDEBAR.SETTINGS.METADATA" | translate }}
      <mat-icon class="rtl-mirror right">{{
        expandMetaData ? "expand_less" : "expand_more"
      }}</mat-icon>
    </p>
    <div *ngIf="expandMetaData" class="meta-data">
      <p *ngFor="let item of fileModel.meta | keyvalue" class="meta-item">
        <span class="meta-key" for="">{{ item.key | translate }}</span>
        <span class="meta-value"
          >{{ item.value.val || item.value
          }}{{ item.value.num ? " [" + item.value.num + "]" : "" }}</span
        >
      </p>
    </div>
  </div>
</ng-form>
