import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LoginStateService } from '../login/login-state.service';
import { takeUntil } from 'rxjs/operators';
import { User } from '../users/models/user.model';
import { Subject } from 'rxjs';
import { Team } from './models/team.model';
import { TeamsService } from './service/teams.service';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { TeamDialogComponent } from 'src/app/shared/team-dialog/team-dialog.component';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {

  private loggedUser: User;
  private ngDestroy$ = new Subject();
  public viewMode: 'client' | 'admin' | null = null;
  public search: string = ''
  
  public createdTeams: Team[] = [];
  public invitedTeams: Team[] = [];
  public allTeams: Team[] = [];

  // Styling Vars
  public theme: string = undefined;
  public isSmall: Boolean = false;
  public isXSmall: Boolean = false;
  isMobile = this.platform.ANDROID || this.platform.IOS;

  constructor( 
    private readonly loginStateService: LoginStateService,
    private readonly teamsService: TeamsService,
    private readonly themeService: ThemeService,
    private readonly breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private platform: Platform,
  ) {
      this.loginStateService.loggedUser$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((loggedUser) => {
        this.loggedUser = loggedUser;
        this.viewMode = this.loggedUser?.accountType === 'admin' ? 'admin' : 'client';
      });

      this.themeService.changed$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(theme => {
        this.theme = theme;
      });

      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium
      ])
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        
        this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
        this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);

      });
  }

  ngOnInit(): void {
    if (this.viewMode === 'client') {
      this.loadClientTeams();
    } else if (this.viewMode === 'admin') {
      this.loadAdminTeams();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

  // ------------ Getting Data ------------
  private loadClientTeams(): void {
    this.teamsService.getCreatedTeams(this.loggedUser._id.toString()).subscribe((teams) => (this.createdTeams = teams));
    this.teamsService.getInvitedTeams(this.loggedUser._id.toString()).subscribe((teams) => (this.invitedTeams = teams));
  }

  private loadAdminTeams(): void {
    this.teamsService.getAllTeams().subscribe((teamsResponse) => (this.allTeams = teamsResponse));
    this.teamsService.findMany(undefined,undefined,100000).subscribe((teamsResponse) => (this.allTeams = teamsResponse.data));
  }

  private reloadDataSources():void {
    if (this.viewMode === 'client') {
      this.loadClientTeams();
    } else if (this.viewMode === 'admin') {
      this.loadAdminTeams();
    }
  }

  // ------------ Actions ------------
  public viewTeam(team: Team):void {
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '1000px',
      maxWidth: '90vw',
      data: { teamId: team._id, action: 'view', isAdmin: (this.viewMode === 'admin') }
    });
  }

  public createTeam(): void {
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '1000px',
      maxWidth: '90vw',
      data: { teamId: undefined, action: 'create', isAdmin: (this.viewMode === 'admin') }
    });
  
    dialogRef.afterClosed().subscribe((newTeam: Team) => {
      if (newTeam) {
        this.dialogService.showDialog('Team has been created successfully', null, null, null, null, true);
        
        if (this.viewMode === 'client') {
          this.createdTeams = [...this.createdTeams, newTeam]; // Creates a fresh array
        } else if (this.viewMode === 'admin') {
          this.allTeams = [...this.allTeams, newTeam]; // Creates a fresh array
        }
      }
    });
  }

  public deleteTeam(teamId: string): void {
    this.teamsService.deleteOne(teamId).subscribe(() => {
      this.dialogService.showDialog('Team has been deleted successfully', null, null,null,null,true)
      if (this.viewMode === 'client') {
        this.createdTeams = this.createdTeams.filter(team => team._id !== teamId);
        this.invitedTeams = this.invitedTeams.filter(team => team._id !== teamId);
      } else if (this.viewMode === 'admin') {
        this.allTeams = this.allTeams.filter(t => t._id !== teamId);
      }
    });
  }

  public editTeam(team: Team): void {
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '1000px',
      maxWidth: '90vw',
      data: { teamId: team._id, action: 'update', isAdmin: (this.viewMode === 'admin') }
    });
  
    dialogRef.afterClosed().subscribe((updatedTeam: Team) => {
      if (updatedTeam) {
        this.dialogService.showDialog('Team has been updated successfully', null, null, null, null, true);
  
        if (this.viewMode === 'client') {
          this.createdTeams = this.createdTeams.map(t => t._id === updatedTeam._id ? updatedTeam : t);
          this.invitedTeams = this.invitedTeams.map(t => t._id === updatedTeam._id ? updatedTeam : t);
        } else if (this.viewMode === 'admin') {
          this.allTeams = this.allTeams.map(t => t._id === updatedTeam._id ? updatedTeam : t);
          console.log('All new Teams:', this.allTeams);
        }
      }
    });
  }
}
