import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/WebGLTile';
import { View } from 'ol';
import { OSM } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import GeoTIFF from 'ol/source/GeoTIFF';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { FileModel } from '../../file/file.model';
import { Point, Polygon } from 'ol/geom';
import { BehaviorSubject } from 'rxjs';
import { easeOut } from 'ol/easing';
import { DEFAULT_ACTIVE_BLUE_STYLE, HIGHLIGHT_ORANGE } from 'src/app/shared/openlayers/openlayers-constants';
import { deg2rad, hexToRgb } from '../utils/helpers';
import { DEFAULT_STYLE, HIDDEN_STYLE, PV_MODULE_COLORS, SELECTED_STYLE } from '../utils/pv-module-styles';

@Injectable({
  providedIn: 'root'
})
export class PvInspectionOlmapService {
  private temperatureLayer: VectorLayer<VectorSource>;
  public hoveredFeature$: BehaviorSubject<Feature> = new BehaviorSubject(undefined);
  public selectedFeatures$: BehaviorSubject<Feature[]> = new BehaviorSubject(undefined);

  constructor() {
    this.temperatureLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 200,
    });
  }

  // --------------------------- Map Level -----------------------------
  public initMap(): Map {
    let mapOptions = {
      controls: [],
      projection: 'EPSG:25832',
      view: new View({
        center: [722122, 4973948],
        zoom: 2,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: document.querySelector('#map') as HTMLElement
    };
    const map = new Map(mapOptions);
    this.updateSize(map);

    map.addLayer(this.temperatureLayer);
    return map
  }

  public updateSize(map: Map): void {
    map.updateSize();
  }
  
  // --------------------------- Layer Level -----------------------------
  public registerProjection(geojsonLayer): void {
    fetch(geojsonLayer.file.webViewLink, {
      method: 'GET',
      headers: { 'Range': 'bytes=0-1024' } // Fetch only the first 1KB
    })
    .then(response => response.text()) // Read as text instead of JSON
    .then(text => {
      const match = text.match(/"name"\s*:\s*"urn:ogc:def:crs:EPSG::(\d+)"/);
      
      if (match) {
        const epsgCode = `EPSG:${match[1]}`; 
        if (!proj4.defs[epsgCode]) {
          proj4.defs(epsgCode, `+proj=utm +zone=${epsgCode.slice(-2)} +datum=WGS84 +units=m +no_defs`);
          register(proj4);
        }
      } else {
        console.warn('CRS projection not found in the fetched data.');
      }
    })
    .catch(error => console.error('Error fetching CRS:', error));
  }

  public clearAndRemoveDroneCapturesLayer(map: Map, layers: any[]): void {
    const droneCaptureLayer = layers.find(layer => layer.label === 'Drone Captures');
    if (droneCaptureLayer && droneCaptureLayer.olLayer) {
      map.removeLayer(droneCaptureLayer.olLayer);
      const index = layers.indexOf(droneCaptureLayer);
      if (index > -1) {
        layers.splice(index, 1);
      }
    } 
  }

  public getMainThermalOrthoFromLayers(layers: any[]): TileLayer {
    return layers.find(layer => layer.isMainTiff && layer.olLayer.getClassName() === 'tiff-thermal').olLayer
  }

  public addTiffLayer(map: Map, layer): TileLayer {
    const geoTiffSource = new GeoTIFF({
      sources: [
        {
          url: layer.file.webViewLink,
        },
      ],
      interpolate: true,
    });
    const geoTiffLayer = new TileLayer({
      source: geoTiffSource,
      zIndex: layer.zIndex,
      className: layer.type
    });
    map.addLayer(geoTiffLayer);    
    return geoTiffLayer;
  }

  public addTiffThermalLayer(map: Map, layer, selectedThermalPalette): TileLayer {
    const stepValue = (layer.file.meta.max_temperature - layer.file.meta.min_temperature) / (selectedThermalPalette.length - 1);

    const colorMap = [0, [0, 0, 0, 0]]; // Start with transparent black

    selectedThermalPalette.forEach((color, index) => {
      if (index !== (selectedThermalPalette.length - 1)) {
        colorMap.push(layer.file.meta.min_temperature + stepValue * index, hexToRgb(color));
      } else {
        colorMap.push(layer.file.meta.max_temperature, hexToRgb(color));
      }
    });
  
    // Create the GeoTIFF source
    const geoTiffSource = new GeoTIFF({
      sources: [{ url: layer.file.webViewLink }],
      normalize: false, // Use raw data values
      interpolate: false, // Enable linear interpolation
    });
  
    // Create the TileLayer with a style function
    const geoTiffLayer = new TileLayer({
      source: geoTiffSource,
      style: {
        color: [
          'interpolate',
          ['linear'],
          ['band', 1], // Reference the first band
          ...colorMap
        ],
      },
      zIndex: layer.zIndex,
      className: layer.type
    });
  
    map.addLayer(geoTiffLayer);
    return geoTiffLayer;
  }

  public addGeojsonLayer(map: Map, layer): VectorLayer<VectorSource> {
    const vectorSource = new VectorSource({
      url: layer.file.webViewLink,
      format: new GeoJSON({ 
        dataProjection: 'EPSG:25833', 
        featureProjection: 'EPSG:25833'
      })
    })

    // Listen for when the GeoJSON data loads
    vectorSource.on('addfeature', (event) => {
      const feature = event.feature;
      feature.set('type', 'pvModule');
    });

    const geojsonLayer = new VectorLayer({
      zIndex: layer.zIndex,
      className: layer.type,
      source: vectorSource,
      style: DEFAULT_ACTIVE_BLUE_STYLE
    });

    map.addLayer(geojsonLayer); 
    
    return geojsonLayer;
  }

  public getMainOrthoFromLayers(layers: any[]): TileLayer {
    return layers.find(layer => layer.isMainTiff && layer.olLayer.getClassName() === 'tiff').olLayer
  }

  public pushLayer(layers: any[],olLayer: any, zIndex: number, isMainTiff: boolean, label: string): void {
    const layerObject = {
      olLayer,
      zIndex,
      isVisible: true,
      isMainTiff: isMainTiff,
      label: label
    };
  
    if (layers.length === 0) {
      layers.push(layerObject);
    } else {
      let index = layers.findIndex(l => l.zIndex < zIndex);  
      if (index === -1) {
        layers.push(layerObject);
      } else {
        layers.splice(index, 0, layerObject);
      }
    }
  }

  public placeImagesPinsOnMap(
    images: FileModel[], 
    layers: any[], 
    map: Map, 
    imagePinStyles: any
  ): void {
    this.clearAndRemoveDroneCapturesLayer(map,layers)
    const imagesLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 10,
    });
    map.addLayer(imagesLayer);
    this.pushLayer(layers, imagesLayer, 100, false, 'Drone Captures');
    // Create a new vector source
    const vectorSource = new VectorSource(); 
    images.forEach(image => {
      if (image.geo?.coordinates) {
        const coordinates = fromLonLat([image.geo.coordinates[0], image.geo.coordinates[1]]);
        const rotation = image.yaw ? deg2rad(image.yaw) : 0;
  
        const feature = new Feature({
          geometry: new Point(coordinates),
          id: image._id,
          rotation: rotation,
          type: 'image',
        });
  
        feature.setId(String(image._id));
  
        const positionStyle = new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({ color: 'rgba(155, 155, 155, 0.7)' }),
            stroke: new Stroke({ color: 'black', width: 1 }),
          }),
        });
  
        const headingStyle = new Style({
          image: new Icon({
            anchor: [12, 25],
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            imgSize: [20, 20],
            color: '#9b9b9b',
            src: '/assets/images/icons/marker_direction.svg',
            scale: 0.65,
            opacity: 0.7,
            rotation: rotation,
          }),
        });
  
        const combinedStyle: Style[] = [positionStyle, headingStyle];
  
        imagePinStyles[String(image._id)] = combinedStyle;
        feature.setStyle(combinedStyle);
        vectorSource.addFeature(feature);
      }
    });
    // Assign the new source to the layer
    imagesLayer.setSource(vectorSource);
  }

  public getPvModulesLayer(layers: any[]): VectorLayer<VectorSource> | undefined {
    return layers.find(layer => layer.label === 'Modules Plan')?.olLayer;
  }

  // --------------------------- Feature Level ---------------------------

  public getHoveredFeature$(): BehaviorSubject<Feature | undefined> {
    return this.hoveredFeature$;
  }

  public getSelectedFeatures$(): BehaviorSubject<Feature[] | undefined> {
    return this.selectedFeatures$;
  }

  public setHoveredFeature(feature: Feature | undefined): void {
    this.hoveredFeature$.next(feature);
  }

  public setSelectedFeatures(features: Feature[] | undefined): void {
    this.selectedFeatures$.next(features);
  }

  public zoomOnFeature(map: Map, feature: Feature): void {
    map.getView().fit(feature.getGeometry().getExtent(), {
      padding:[300, 300, 300, 300], maxZoom:22, duration:1000, easing:easeOut    
    });
  }

  public calculateModuleTemperature(
    moduleGeometry: Polygon,
    layers: any[],
    map: Map
  ): { min: number; max: number; avg: number; delta: number } | null {
    const thermalLayer = this.getMainThermalOrthoFromLayers(layers);
    if (!thermalLayer) {
      console.error('Thermal layer not found.');
      return null;
    }
  
    if (!moduleGeometry) {
      console.error('No geometry found for the selected module.');
      return null;
    }
  
    const extent = moduleGeometry.getExtent();
    const size = map.getSize();
    if (!size) return null;
  
    const minPixel = map.getPixelFromCoordinate([extent[0], extent[1]]);
    const maxPixel = map.getPixelFromCoordinate([extent[2], extent[3]]);
  
    const minX = Math.floor(Math.min(minPixel[0], maxPixel[0]));
    const maxX = Math.ceil(Math.max(minPixel[0], maxPixel[0]));
  
    const minY = Math.floor(Math.min(minPixel[1], maxPixel[1]));
    const maxY = Math.ceil(Math.max(minPixel[1], maxPixel[1]));
  
    const thermalValues: number[] = [];
  
    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {
        const coordinate = map.getCoordinateFromPixel([x, y]);
        if (moduleGeometry.intersectsCoordinate(coordinate)) {
          const pixelTemperature = thermalLayer.getData([x, y]);
          if (pixelTemperature !== undefined) {
            thermalValues.push(pixelTemperature[0]);
          }
        }
      }
    }
  
    if (thermalValues.length === 0) {
      console.warn('No thermal data found inside the module.');
      return null;
    }
  
    const minTemp = Math.min(...thermalValues);
    const maxTemp = Math.max(...thermalValues);
    const avgTemp = thermalValues.reduce((sum, temp) => sum + temp, 0) / thermalValues.length;
    const deltaTemp = maxTemp - minTemp;
  
    return {
      min: minTemp,
      max: maxTemp,
      avg: avgTemp,
      delta: deltaTemp,
    };
  }

  restoreFeatureStyle(feature: Feature): void {
    const baseStyle = feature.get('_baseStyle');
    if (baseStyle) {
      feature.setStyle(baseStyle);
    } else {
      feature.setStyle(DEFAULT_STYLE);
    }
  }

  public getStyleBySeverity(severity: number, isSelected: boolean = false): Style {
    if (severity >= 100) return this.makeStyle(PV_MODULE_COLORS.URGENT, isSelected, true);
    if (severity >= 80) return this.makeStyle(PV_MODULE_COLORS.CRITICAL, isSelected, true);
    if (severity >= 60) return this.makeStyle(PV_MODULE_COLORS.IMPORTANT, isSelected, true);
    if (severity >= 40) return this.makeStyle(PV_MODULE_COLORS.LOW, isSelected, true);
    if (severity >= 20) return this.makeStyle(PV_MODULE_COLORS.AD, isSelected, true);
    
    // No annotation
    if (isSelected) return this.makeStyle('#2979ff', true); // blue dashed
    return DEFAULT_STYLE;
  }
  
  private makeStyle(color: string, isSelected: boolean = false, filled: boolean = false): Style {
    const fillColor = filled ? color.replace(/33$/, '') : `${color}33`;
    return new Style({
      stroke: new Stroke({
        color,
        width: 2,
        lineDash: isSelected ? [8, 12] : undefined,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    });
  }

  public highlightFeatures(features: Feature[]): void {
    features.forEach((feature) => {
      const annotations = feature.get('inspection')?.annotations ?? [];
      const maxSeverity = Math.max(...annotations.map(a => a.stateDimension || 0), 0);
      const selectedStyle = this.getStyleBySeverity(maxSeverity, true);
      feature.setStyle(selectedStyle);
    });
  }

  public clearHighlights(features: Feature[]): void {
    features.forEach((feature) => {
      this.restoreFeatureStyle(feature); // Restore from _baseStyle
    });
  }

  public setFeatureVisibility(feature: Feature, visible: boolean): void {
    const baseStyle = feature.get('_baseStyle');
    feature.setStyle(visible ? baseStyle : HIDDEN_STYLE);
  }
}
