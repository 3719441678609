import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { getAnnotationType } from '../../utils/helpers';

interface FilterOption {
  name: string;
  completed: boolean;
  color: string;
  subtasks?: FilterOption[];
}

@Component({
  selector: 'app-pv-filter',
  templateUrl: './pv-filter.component.html',
  styleUrls: ['./pv-filter.component.scss']
})
export class PvFilterComponent implements OnInit, OnChanges {
  // The parent checkbox that tracks the overall completion of all filters
  public allComplete = false;

  // The filters with nested subtasks
  public filters: FilterOption[] =[]

  @Input() selectedValues: any[] = [];
  @Input() dynamicFilters: any[] = [];
  @Input() currentLang!: string;


  @Output() filtersChanged = new EventEmitter<any>();
  @Output() closeFilterBox = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    console.log('PvFilterComponent initialized');
    console.log('filters:', this.filters)
  }

  ngOnChanges(): void {
    if (this.dynamicFilters?.length) {
      this.filters = this.dynamicFilters.map(filter => {
        const selected = this.selectedValues.find(f => f.name === filter.name);
        const selectedValues = selected?.values ?? [];
  
        const subtasks = filter.values.map(value => ({
          name: value,
          completed: selectedValues.includes(value),
          color: 'primary'
        }));
  
        return {
          name: filter.name,
          completed: subtasks.every(s => s.completed),
          color: 'primary',
          subtasks
        };
      });
    }
  }

  // This method checks if some (but not all) subtasks are selected
  public someComplete(filter: FilterOption): boolean {
    if (!filter.subtasks) return false;
    return filter.subtasks.some(t => t.completed) && !filter.completed;
  }

  public getAnnotationTypeLabel(annotationType: string): string {
    return getAnnotationType(annotationType, this.currentLang);
  }

  // When parent checkbox is clicked, set all child checkboxes
  public setAll(filter: FilterOption, completed: boolean): void {
    filter.completed = completed;
    if (filter.subtasks) {
      filter.subtasks.forEach(t => t.completed = completed);
    }
  }

  // Update parent checkbox based on children’s state
  public updateAllComplete(filter: FilterOption): void {
    if (!filter.subtasks) return;
    filter.completed = filter.subtasks.every(t => t.completed);
  }

  // Apply the selected filters and emit them
  public applyFilters(): void {
    const selectedFilters = this.filters.map(filter => ({
      name: filter.name,
      values: filter.subtasks?.filter(t => t.completed).map(t => t.name) || [],
    }));

    this.filtersChanged.emit(selectedFilters);
    this.closeFilterBox.emit();
  }

  // Cancel filter selection and close the filter box
  public cancelFilters(): void {
    this.closeFilterBox.emit();
  }
}
