import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { getAnnotationType } from '../../utils/helpers';
import { Annotation } from '../../../file/sidebar/annotations/annotation.model';
import { PvInspectionInteractionsService } from '../../services/pv-inspection-interactions.service';
import { Inspection, PVModule } from '../../model/pv-module.model';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { AnnotationsService } from '../../../file/sidebar/annotations/annotations.service';

@Component({
  selector: 'app-sidenav-pv-module-details',
  templateUrl: './sidenav-pv-module-details.component.html',
  styleUrls: ['./sidenav-pv-module-details.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*', opacity: 1, padding: '6px 0px 16px 0px' })),
      state('collapsed', style({ height: '0px', opacity: 0, padding: '0px 0px 16px 0px' })),
      transition('expanded <=> collapsed', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class SidenavPvModuleDetailsComponent implements OnInit, OnChanges {
  @Input() public pvModule: PVModule;
  @Input() public currentLang!: string;

  @Output() public closeSidenav = new EventEmitter<void>();
  @Output() public toggleAnnotationForm = new EventEmitter<void>();
  @Output() public annotationDeleted = new EventEmitter<void>();

  // UI State
  public annotationFormActive = false;
  public showIdentification = false;
  public showLocation = false;
  public showInspection = true;
  public editingKey: string | null = null;
  public editingValue: string = '';


  // Field Definitions
  public identificationFields = [
    { key: 'serialNumber', label: 'Serial Number', path: 'identification' },
    { key: 'fId', label: 'FID', path: 'identification' },
    { key: 'moduleType', label: 'Module Type', path: 'identification' },
    { key: 'modelNumber', label: 'Model Number', path: 'identification' },
    { key: 'manufacturer', label: 'Manufacturer', path: 'identification' },
    { key: 'installationDate', label: 'Installation Date', path: 'identification' },
    { key: 'removalDate', label: 'Replacement Date', path: 'identification' },
    { key: 'warrantyExpiryDate', label: 'Warranty Expiry Date', path: 'identification' }
  ];

  public locationFields = [
    { key: 'panelNumber', label: 'Panel Number', path: 'location' },
    { key: 'arrayId', label: 'Array ID', path: 'location' },
    { key: 'stringNumber', label: 'String', path: 'location' },
    { key: 'tiltAngle', label: 'Tilt Angle', path: 'location' },
    { key: 'azimuthAngle', label: 'Azimuth Angle', path: 'location' },
    { key: 'shadingInfo', label: 'Shading Info', path: 'location' }
  ];

  constructor( 
    private pvInspectionInteractionsService: PvInspectionInteractionsService,
    private readonly ordersService: OrdersService,
    private readonly annotationsService: AnnotationsService
  ) { }

  ngOnInit(): void {
    console.log('this.pvModule', this.pvModule)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pvModule']?.currentValue) {
      console.log('pvModule input changed:', changes['pvModule'].currentValue);
    }
  }

  // Template Interaction
  public toggleSection(section: 'identification' | 'location' | 'inspection'): void {
    switch (section) {
      case 'identification':
        this.showIdentification = !this.showIdentification;
        break;
      case 'location':
        this.showLocation = !this.showLocation;
        break;
      case 'inspection':
        this.showInspection = !this.showInspection;
        break;
    }
  }

  public onCloseSidenav(): void {
    this.closeSidenav.emit();
  }

  public onToggleAnnotationForm(): void {
    this.annotationFormActive = !this.annotationFormActive;
    this.toggleAnnotationForm.emit();
  }

  // Annotation Helpers
  public getAnnotationTypeLabel(annotationType: string): string {
    return getAnnotationType(annotationType, this.currentLang);
  }

  public onEditAnnotation(annotation: Annotation): void {
    console.log('Editing annotation:', annotation);
  }

  public onDeleteAnnotation(annotation: Annotation): void {
    console.log('Deleting annotation:', annotation);
    this.annotationsService.deleteOne(annotation._id.toString()).subscribe(() => {
      const inspection = this.activeInspection;
      if (inspection?.annotations) {
        inspection.annotations = inspection.annotations.filter(a => a._id !== annotation._id);
      }
      this.annotationDeleted.emit();
    });
  }

  // Field Formatting
  public formatField(value: any, key: string): string | number {
    if (value === null || value === undefined || value === '') return '';

    const dateFields = ['installationDate', 'removalDate', 'warrantyExpiryDate'];
    if (dateFields.includes(key)) {
      return new Date(value).toLocaleDateString('en-GB'); // dd/MM/yyyy
    }

    return value;
  }

  public fieldValue(value: any): string | number {
    if (value === null || value === undefined || value === '') return '';
    return value;
  }

  // Editing Logic
  public getFieldKey(field: { path: string; key: string }): string {
    return `${field.path}.${field.key}`;
  }

  public startEditing(field: { path: string; key: string }): void {
    this.editingKey = this.getFieldKey(field);
    const currentValue = this.pvModule?.[field.path]?.[field.key];
    this.editingValue = currentValue != null ? currentValue.toString() : '';
  }
  
  public finishEditing(field: { path: string; key: string }, newValue?: any): void {
    const { path, key } = field;
  
    const inputValue = newValue ?? this.editingValue;
    const trimmedValue = inputValue?.trim?.() ?? '';
    let cleanedValue: any = trimmedValue === '' ? null : trimmedValue;
  
    const numberKeys = ['tiltAngle', 'azimuthAngle', 'stringNumber'];
    const dateKeys = ['installationDate', 'removalDate', 'warrantyExpiryDate'];
  
    if (numberKeys.includes(key)) {
      cleanedValue = cleanedValue !== null ? Number(cleanedValue) : null;
    } else if (dateKeys.includes(key)) {
      cleanedValue = cleanedValue !== null ? new Date(cleanedValue) : null;
    }
  
    if (!this.pvModule[path]) {
      this.pvModule[path] = {};
    }
  
    this.pvModule[path][key] = cleanedValue;
    this.editingKey = null;
    this.editingValue = '';
  
    this.pvInspectionInteractionsService.pvModulesUpdates$.next({
      [path]: { [key]: cleanedValue }
    });
  }

  public isInvalid(field: { key: string; path: string }, value: string): boolean {
    const numberKeys = ['tiltAngle', 'azimuthAngle', 'stringNumber'];
    const dateKeys = ['installationDate', 'removalDate', 'warrantyExpiryDate'];
  
    const trimmed = value.trim();
  
    if (trimmed === '') return false; // Empty is allowed (means null)
  
    if (numberKeys.includes(field.key)) {
      return isNaN(Number(trimmed));
    }
  
    if (dateKeys.includes(field.key)) {
      return isNaN(Date.parse(trimmed));
    }
  
    return false;
  }  

  public get activeInspection(): Inspection | undefined {
    const activeOrderId = this.ordersService.activeOrder$.value._id;
    return this.pvModule?.inspections?.find(i => i.orderId === activeOrderId);
  }
}
