import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memberRole'
})
export class MemberRolePipe implements PipeTransform {

  transform(team: any, userId: string): string {
    if (!team || !userId) return 'undefined';

    // Check if the user is the owner (team manager)
    if (team.manager && team.manager._id === userId) {
      return 'owner';
    }

    // Check in team members
    if (team.members && Array.isArray(team.members)) {
      const member = team.members.find(m => m._id === userId);
      if (member) {
        return member.role;
      }
    }

    // Default role if user is not found
    return 'admin';
  }
}