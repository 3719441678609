<div cdkDrag #viewerContainer class="image-viewer-container">
  <!-- Header -->
  <div class="header" cdkDragHandle>
    <div class="left">
      <mat-icon svgIcon="barcode" (click)="startDrawingPolygon()"></mat-icon>
    </div>
    <div class="right">
      <mat-icon (click)="onClose()">close</mat-icon>
    </div>
  </div>

  <!-- OpenLayers Map -->
  <div #imageMap class="olmap-container">
    <div *ngIf="polygonDrawn" class="footer">
      <mat-form-field appearance="outline" class="serial-number-input">
        <input matInput [(ngModel)]="annotation.comment" placeholder="Enter description">
      </mat-form-field>
      <mat-icon class="save-button" (click)="saveSerialNumberAnnotation(annotation)">send</mat-icon>
    </div>
  </div>
</div>
