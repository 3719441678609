import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Direction } from '@angular/cdk/bidi';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { forwardRef, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { retry } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CompletedPart, QueueItem } from './upload.model';
import { DialogService } from './../../shared/dialog/dialog.service';
import { environment } from './../../../environments/environment';
import { FilesService } from './../../pages/files/files.service';
import { LambdaRequestBody, LambdaService } from './../../shared/helpers/lambda.service';
import { LanguageService } from './../i18n/language.service';
import { PresignResponse } from './../../pages/files/file/file.model';
// import { OrdersService } from '../orders/orders.service';
 import { FoldersService } from '../folders/folders.service';

@Injectable()
export class UploadService implements OnDestroy {

  dir: Direction;
  queueItems$: BehaviorSubject<Array<QueueItem>> = new BehaviorSubject([]);
  private foldersService: FoldersService;

  private concurrentUploads = 10;
  private partSize = 1024 * 1024 * 1024 * 5; // 5GB

  constructor(
      private http: HttpClient,
      private dialogService: DialogService,
      private filesService: FilesService,
      private lambdaService: LambdaService,
      private languageService: LanguageService,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
      private injector: Injector
    ) {
    // Lazy injection to avoid NG0200: Circular dependency Error
    setTimeout(() => this.foldersService = this.injector.get(FoldersService));

    this.languageService.dir$.subscribe(dir => this.dir = dir);
    
  }

  abortMultipartUpload(key: string, uploadID: string): Observable<any> {
    const href = `${environment.apiPath}s3/${
      encodeURIComponent(encodeURIComponent(key))
    }/abortMultipartUpload`;

    return this.http.post<any>(href, {
      bucket: environment.storage,
      key,
      uploadID
    });
  }

  completeMultipartUpload(key: string, parts: Array<any>, uploadID: string): Observable<any> {
    const href = `${environment.apiPath}s3/${
      encodeURIComponent(encodeURIComponent(key))
    }/completeMultipartUpload`;

    return this.http.post<any>(href, {
      bucket: environment.storage,
      key,
      parts,
      uploadID
    });
  }

  public getKey(file: File): string {
      const parts = Math.floor(file.size / this.partSize) + 1;
      if (parts === 1) { // <= 5GB
        return file.name;
      } else {
        return `${file.name}?parts=${parts}`;
      }
  }

  // Get presignPutURLs for all files
  handleFilesInput(files: FileList | Array<File>, folderID: string): void {
      const keys: Array<string> = [];
      Array.from(files).forEach((file: File, index: number) => {
        keys.push(this.getKey(file))
      });

      const subscription = this.presignPutURLs(keys, folderID, 1440) // expire put urls after 1 day
      .subscribe(presignResponse => {
        subscription.unsubscribe();
        this.handleFilesQueue(files, presignResponse.data, folderID);
      },
      error => {
        this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
      });
  }

  // Add files to queue
  handleFilesQueue(files: FileList | Array<File>, presignResponse: Array<PresignResponse>, folderID: string): void {
    Array.from(files).forEach((file: File, index: number) => {

      // Check if key contains the name, if not something went wrong
      if (!presignResponse[index].key.includes(file.name)) {
        this.dialogService.showDialog('UPLOAD.FAILED', null, 'ERRORS.SOMETHING_WENT_WRONG', null);

        return;
      }
      const _id = { $oid: presignResponse[index].key.split('/')[1] }; // Get generated id
      const name = presignResponse[index].key.split('/')[2]; // Get renamed file name using pattern
      const fileURL = presignResponse[index].getURL.split('?')[0]; // Remove pre-signed query

      const queueItem = new QueueItem(presignResponse[index].key, name, 'cloud_upload', 'upload-file');
      queueItem.loaded = 0;
      queueItem.size = file.size;
      queueItem.getURL = presignResponse[index].getURL;
      queueItem.putURL = presignResponse[index].putURL;
      queueItem.putURLs = presignResponse[index].putURLs;
      this.queueItems$.value.push(queueItem);
      this.queueItems$.next(this.queueItems$.value);

      // Define fileModel
      queueItem.fileModel = {
        _id, // Use the same file id in DB and s3
        folderID: folderID && folderID.length === 24 ? { $oid: folderID } : null,
        fileURL,
        mimeType: file.type || 'application/octet-stream',
        name, // Use the renamed by pattern filename
        size: file.size,
        orderID: this.foldersService.activeFolder$.value?.orderID || null
      };

      // Set request observables on the queue items
      if (queueItem.putURL) { // Single file

        queueItem.request = this.uploadFile(queueItem.putURL, file);

      } else if (queueItem.putURLs) { // Multipart file

        const observableArray = Array<Observable<any>>();

        queueItem.putURLs.forEach((putURL: string, idx: number) => {
          queueItem.uploadID = putURL.split('&uploadId=')[1].split('&')[0];
          const start = idx * this.partSize;
          const end = (idx + 1) * this.partSize;
          const blob = (idx + 1 < queueItem.putURLs.length) ? file.slice(start, end) : file.slice(start);

          observableArray.push(this.uploadFile(putURL, blob));
        });

        queueItem.abortMultipartUpload = this.abortMultipartUpload(queueItem.key, queueItem.uploadID);
        queueItem.request = combineLatest(observableArray);
      }
    });

    this.nextUpload();
  }

  handleThumbnail(queueItem: QueueItem): void {
    if (queueItem.fileModel.size < 500 * 1024 * 1024) { // files smaller than 500MB (lambda disk limit)

      // Get image metadata
      const operations = [];
      operations.push({ name: 'meta' });

      if (queueItem.fileModel.size < 100 * 1024 * 1024 && // images smaller than 100MB (lambda memory limit)
          queueItem.fileModel.mimeType.startsWith('image/') && // all images
          !queueItem.fileModel.mimeType.endsWith('arw')) { // except raw images
        // Resize and crop the img to fill the 144x144 area (for table icons on @2x retina)
        operations.push({ name: 'iconURL', parameters: ['144', '144'] });
        // Resize the img to height = 720px preserving the aspect ratio (for grid thumbnails on @2x retina)
        operations.push({ name: 'thumbnailURL', parameters: ['0', '720'] });
      }

      // Generate thumbnail
      const lambdaRequestBody: LambdaRequestBody = {
        bucket: environment.storage,
        keys: [queueItem.key],
        operations
      };

      this.lambdaService.runFunction(`s3ImageProcessing${environment.production ? '' : 'Dev'}`, lambdaRequestBody)
      .subscribe(lambdaResponse => {
        const data = lambdaResponse.data;

        // Update fileModel and insert it in the DB Files collection
        queueItem.fileModel.meta = data.meta || null;
        queueItem.fileModel.geo = data.geo || null;
        queueItem.fileModel.taken = data.taken && { $date: { $numberLong: String(new Date(data.taken).getTime()) }};

        queueItem.fileModel.height = data.fileHeight || null;
        queueItem.fileModel.width = data.fileWidth || null;

        queueItem.fileModel.iconURL = data.iconURL && data.iconURL.split('?')[0]; // Remove pre-signed query
        queueItem.fileModel.thumbnailURL = data.thumbnailURL && data.thumbnailURL.split('?')[0]; // Remove pre-signed query
        queueItem.fileModel.orderID = this.foldersService.activeFolder$.value.orderID || null;

        this.insertFile(queueItem);
      },
      () => {
        // Insert the file even if the thumbnail function failed
        this.insertFile(queueItem);
        this.translate.get('FILES.THUMBNAIL_FAILED').subscribe(translation => {
          this.snackBar.open(`${queueItem.fileModel.name} ${translation}`, '', { duration: 3000 });
        });
      });
    } else {
      // Insert fileModel in the DB Files collection
      this.insertFile(queueItem);
    }
  }

  insertFile(queueItem: QueueItem): void {
    this.filesService.insertMany([queueItem.fileModel])
    .subscribe(
      () => {
        // Remove item from Upload Queue
        queueItem.stopUpload();
        this.removeQueueItem(queueItem);

        // Start next upload or refresh the list if all finished
        if (this.queueItems$.value.length) {
          this.nextUpload();
        } else {
          this.filesService.refreshed.emit('refreshed');

          this.translate.get('UPLOAD.COMPLETE').subscribe(translation => {
            this.snackBar.open(`${translation}`, '', { duration: 10000 });
          });
        }
      },
      error => {
        queueItem.stopUpload();
        this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
      }
    );
  }

  // Start next queued upload
  nextUpload(): void {
    let inProgress = this.queueItems$.value.filter((item: QueueItem) => item.inProgress && !item.stopped).length;

    if (inProgress < this.concurrentUploads) {
      for (const item of this.queueItems$.value) {
        if (!item.inProgress && !item.stopped) {
          inProgress++;

          item.startProgress();

          if (item.putURL) { // Single part upload
            item.subscription = item.request
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent: // 0: The request was sent out over the wire.
                case HttpEventType.ResponseHeader: // 2: The response status code and headers were received.
                case HttpEventType.DownloadProgress: // 3: A download progress event was received.
                case HttpEventType.User: // 5: A custom event from an interceptor or a backend.
                  break;
                case HttpEventType.UploadProgress: // 1: An upload progress event was received.
                  item.loaded = event.loaded;
                  break;
                default: // 4: The full response including the body was received.
                  const res = event as HttpResponse<any>;
                  const eTag = res.headers.get('ETag');

                  // Check for eTag && Check if loaded bytes matches the file size
                  if (eTag && item.loaded === item.size) {
                    this.handleThumbnail(item);
                  } else {
                    item.stopUpload();
                    this.nextUpload();
                    this.dialogService.showDialog('UPLOAD.FAILED', null, 'ERRORS.SOMETHING_WENT_WRONG');
                  }
              }
            },
            error => {
              item.stopUpload();
              this.nextUpload();
              this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
            });

          } else { // Multipart upload

            const partSize = Array<number>();
            const parts = Array<CompletedPart>();

            item.subscription = item.request
            .subscribe((value: Array<any>) => {
              item.loaded = 0; // Recalculate below the loaded sum of all parts
              value.forEach((res: any, idx: number) => {
                if (res.total) {
                  partSize[idx] = res.total;
                } else if (res.headers && !parts[idx]) {
                  parts[idx] = {
                    ETag: res.headers.get('ETag'),
                    PartNumber: idx + 1
                  };
                }
                item.loaded += res.loaded || partSize[idx] || 0;
              });
            },
            (error: any) => {
              item.stopUpload();
              this.nextUpload();
              this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
            },
            () => { // Multipart upload finished
              if (item.loaded === item.size) { // Check if loaded bytes matches the file size

                // Trigger multipart stitching on s3
                const subscription = this.completeMultipartUpload(item.key, parts, item.uploadID)
                .subscribe(() => {
                  subscription.unsubscribe();
                  item.uploadID = null; // Clear the uploadID so the cancelMultipartUpload function is not called in the end
                  this.handleThumbnail(item); 
                },
                error => {
                  this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
                });
              } else {
                item.stopUpload();
                this.nextUpload();
                this.dialogService.showDialog('UPLOAD.FAILED', null, 'ERRORS.SOMETHING_WENT_WRONG');
              }
            });
          }
        }

        if (inProgress >= this.concurrentUploads) {
          break;
        }
      }
    }

    if (inProgress) {
      this.translate.get(
          this.queueItems$.value.length === 1 ? 'UPLOAD.ITEM' : 'UPLOAD.ITEMS', // eslint-disable-next-line prefer-template
          { count: `${inProgress}${inProgress !== this.queueItems$.value.length ? '/' + this.queueItems$.value.length : ''}` }
      ).subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 0 }); // Show forever
      });
    } else {
      this.snackBar.dismiss();
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.queueItems$.unsubscribe();
  }

  presignPutURL(key: string): Observable<any> {
    const href = `${environment.apiPath}s3/${
      encodeURIComponent(encodeURIComponent(key))
    }/presignURL`;

    return this.http.put<any>(href, {
      bucket: environment.storage
    })
    .pipe(
      retry(3) // retry a failed request up to 3 times
    );
  }

  presignPutURLs(keys: Array<string>, folderID: string, expiration: number): Observable<any> {
    const href = `${environment.apiPath}s3/presignURLs`;

    return this.http.put<any>(href, {
      bucket: environment.storage,
      expiration,
      folderID: folderID && folderID.length === 24 ? { $oid: folderID } : null,
      keys
    })
    .pipe(
      retry(3) // retry a failed request up to 3 times
    );
  }

  removeQueueItem(queueItem: QueueItem): void {
    const queueItems = this.queueItems$.value;
    const index = queueItems.indexOf(queueItem);

    if (queueItem.name === 'generate-pdf' || queueItem.name === 'unzip' || queueItem.name === 'zip') {
      this.cancelSSEEvent(queueItem.name,queueItem.key)
    }
    
    queueItems.splice(index, 1);
    this.queueItems$.next(queueItems);
  }

  cancelSSEEvent(name: string, requestID: string) {
    const body = {
      name: name,
      requestID: requestID
    };

    return this.http.post(`${environment.apiPath}events/cancel`, body).subscribe({
      next: (response) => {
        //TODO: Handle cancellation success
      },
      error: (error) => {
       //TODO: Handle cancellation failure 
      },
    });
  }

  uploadFile(url: string, file: File | Blob): Observable<any> {
    const req = new HttpRequest('PUT', url, file, {
      headers: new HttpHeaders({
        'Content-Type':  file.type || 'application/octet-stream'
      }),
      reportProgress: true
    });

    return this.http.request<any>(req)
    .pipe(
      retry(3) // retry a failed request up to 3 times
    );
  }
}
