import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team, TeamsFilter } from '../models/team.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(
    private http: HttpClient
  ) {}

  //------------------------ API CALLS ------------------
  public findMany(
    filter?: object,
    cursor?: number,
    pageSize?: number,
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (cursor) {
      params = params.append('cursor', cursor.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    return this.http.get<Team[]>(`${environment.apiPath}teams`, {params});
  }

  public findOne(teamId: string):Observable<Team> {
    return this.http.get<Team>(`${environment.apiPath}teams/${teamId}`);
  }

  public insertOne(team: Team): Observable<Team> {
    return this.http.post<Team>(`${environment.apiPath}teams`, team);
  }

  public updateOne(team: Team): Observable<Team> {
    return this.http.put<Team>(`${environment.apiPath}teams/${team._id}`, team);
  }

  public deleteOne(teamId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiPath}teams/${teamId}`);
  }

  //----------------------- Business Logic ---------------------
  public getCreatedTeams(managerId: string): Observable<Team[]> {
    const filter = this.constructFilter(managerId, undefined);
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<Team[]>(`${environment.apiPath}teams`, { params }).pipe(
      map((response: any) => response.data || [])
    );
  }

  public getInvitedTeams(memberId: string): Observable<Team[]> {
    const filter = this.constructFilter(undefined, memberId);
    const params = new HttpParams().set('filter', JSON.stringify(filter));

    return this.http.get<Team[]>(`${environment.apiPath}teams`, { params }).pipe(
      map((response: any) => response.data || [])
    );
  }

  public getAllTeams(): Observable<Team[]> {
    return this.findMany(undefined, undefined, 100000).pipe(
      map((responseTeams: any) => responseTeams.data || [])
    );
  }

  public fetchATeamById(teamId: string): Observable<Team> {
    return this.findOne(teamId).pipe(
      map((responseTeam: any) => responseTeam.data || undefined)
    );
  }

  public updateTeamById(team: Team): Observable<Team> {
    return this.updateOne(team).pipe(
      map((responseTeam: any) => responseTeam.data || undefined)
    );
  }

  // ------------------- Helpers --------------
  public constructFilter(
    managerID?: string,
    memberID?: string,
  ): TeamsFilter {
    const filter: TeamsFilter = {};
    if (managerID) filter.managerID = managerID;
    if (memberID) filter.memberID = memberID;
    return filter;
  }

  public constructRequestPayload(team: Team):Team {
    const teamRequestPayload = {}
    if (team._id) {
      teamRequestPayload['_id'] = team._id
    }
    if (team.manager._id) {
      teamRequestPayload['managerID'] = team.manager._id
    }
    if (team.name) {
      teamRequestPayload['name'] = team.name
    }
    if (team.resources.length > 0) {
      teamRequestPayload['resources'] = team.resources
    }
    if (team.members.length > 0) {
      teamRequestPayload['members'] = team.members
    }
    return teamRequestPayload
  }

}
