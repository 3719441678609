import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Team } from '../../models/team.model';
import { Subject } from 'rxjs';
import { TeamsComponent } from '../../teams.component';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnChanges {
  @Input() allTeams: Team[] = [];
  @Input() theme: string;
  @Input() isSmall: boolean;
  @Input() isXSmall: boolean;

  displayedColumns: string[] = ['name', 'owner', 'portfolios', 'members', 'actions'];
  ngDestroy$ = new Subject();

  constructor(
    public teams: TeamsComponent
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSmall'] || changes['isXSmall']) {
      this.updateDisplayedColumns();
    }
  }

  private updateDisplayedColumns(): void {
    if (this.isXSmall) {
      this.displayedColumns = ['owner', 'members', 'actions'];
    } else if (this.isSmall) {
      this.displayedColumns = ['name', 'owner', 'members', 'actions'];
    } else {
      this.displayedColumns = ['name', 'owner', 'portfolios', 'members', 'actions'];
    }
  }

  public applySearch(event?: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('All Teams search: ',filterValue)
  }

}
