import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PvFilterService {
  private filtersSubject = new BehaviorSubject<any[]>([]);
  filters$ = this.filtersSubject.asObservable();

  private selectedFiltersSubject = new BehaviorSubject<any[]>([]);
  selectedFilters$ = this.selectedFiltersSubject.asObservable();

  private activeOrderId: string;

  public setActiveOrder(orderId: string): void {
    this.activeOrderId = orderId;
  }

  public getActiveOrder(): string {
    return this.activeOrderId;
  }

  public setFilters(filters: any[]): void {
    this.filtersSubject.next(filters);
  }

  public getCurrentFilters(): any[] {
    return this.filtersSubject.getValue();
  }

  public setSelectedFilters(filters: any[]): void {
    this.selectedFiltersSubject.next(filters);
  }

  public getSelectedFilters(): any[] {
    return this.selectedFiltersSubject.getValue();
  }
}
