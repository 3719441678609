<div class="mat-dialog-header">
  <h2>Add file to display on the map</h2>
  <p class="subtitle">Select the type of the file that you want to upload then drop or select the file to upload</p>
</div>

<mat-dialog-content class="dialog-content">
  <section>
    <h5>1. Select file type</h5>
    <div class="file-type-options">
      <div class="file-type-box" [class.selected]="selectedType === 'design'" (click)="selectFileType('design')">
        <div>Design Surface</div>
        <small>GeoTiff files</small>
      </div>
      <div class="file-type-box" [class.selected]="selectedType === 'thermal'" (click)="selectFileType('thermal')">
        <div>Thermal Design Surface</div>
        <small>Thermal GeoTiff files</small>
      </div>
      <div class="file-type-box" [class.selected]="selectedType === 'geojson'" (click)="selectFileType('geojson')">
        <div>Geo-referenced</div>
        <small>Geojson files</small>
      </div>
    </div>
  </section>

  <section>
    <h5>2. Select a file</h5>
    <div class="file-drop-area"
      (click)="!selectedFile && fileInput.click()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.dragging]="isDragging">
      <ng-container *ngIf="!selectedFile; else filePreview">
        <p>Drop or select the file to upload</p>
        <small *ngIf="selectedType === 'geojson'">Upload .geojson file</small>
        <small *ngIf="selectedType !== 'geojson'">Upload .tiff file</small>
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
        <button mat-raised-button color="primary" (click)="fileInput.click(); $event.stopPropagation()">Select file</button>
      </ng-container>

      <ng-template #filePreview>
        <p>{{ selectedFile?.name }}</p>
        <small>Ready to upload</small>
        <button mat-button color="warn" (click)="removeFile($event)">Remove</button>
      </ng-template>

      <input type="file"
        #fileInput
        hidden
        [accept]="getAcceptTypes()"
        (change)="onFileSelected($event)" />
    </div>
  </section>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!selectedFile || !selectedType" (click)="onUpload()">Upload</button>
</mat-dialog-actions>
