import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { createEmptyTeam, ResourceType, Team } from 'src/app/pages/teams/models/team.model';
import { TeamsService } from 'src/app/pages/teams/service/teams.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from '../theme/theme.service';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { PortfoliosService } from 'src/app/pages/portfolios/portfolios.service';
import { Portfolio } from '../portfolio/portfolio.model';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { UsersService } from 'src/app/pages/users/users.service';
import { userSearchFilter } from '../helpers/data-helpers';
import { User } from 'src/app/pages/users/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss']
})

export class TeamDialogComponent implements OnInit, OnDestroy {
  public team: Team = createEmptyTeam();
  public fetching = true
  public theme: string;
  public addingNewMember:boolean = false
  public addingNewPortfolio:boolean = false
  public newMemberForm: FormGroup;
  public newPortfolioForm: FormGroup;
  public filteredPortfolios: Portfolio[] = [];
  public selectedManager: any = null; // Stores selected manager info
  public filteredUsers: any[] = []; // Stores filtered users
  private snackBar: MatSnackBar
  public managerEmail$ = new BehaviorSubject<string>(''); 

  private ngDestroy$ = new Subject();
  private allPortfolios: Portfolio[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private teamsService: TeamsService,
    private themeService: ThemeService,
    public dialogRef: MatDialogRef<TeamDialogComponent>,
    private portfoliosService: PortfoliosService,
    public loginStateService: LoginStateService,
    private usersService: UsersService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { teamId: string, action: 'view' | 'create' | 'update', isAdmin: boolean }
  ) {

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
      const root = document.documentElement;
      if (theme.includes('dark')) {
        root.classList.add('dark-theme');
      } else {
        root.classList.remove('dark-theme');
      }
    });

    this.newMemberForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['viewer', Validators.required]
    });

    this.newPortfolioForm = this.fb.group({
      name: ['', Validators.required],
      id: ['']
    });   
  
    if (this.data.teamId) {
      this.teamsService.fetchATeamById(this.data.teamId).subscribe(team => {
        this.team = team;
        this.fetching = false;

        if (this.data.action === 'create') {
          this.team.manager = { _id: '', email: '', name: '', surname: '' };
        } else if (this.data.action === 'update'){
          if (this.data.isAdmin) {
            this.fetchPortfolios();
          }
        }

        if (this.data.isAdmin && this.team.managerID) {
          this.usersService.findOne(this.team.managerID).subscribe(manager => {
            this.team.manager = manager;
            this.fetchPortfolios();
            this.cd.markForCheck();
          });
        }

      }, error => {
        console.error('Error fetching team data:', error);
      });
    } else {
      this.fetching = false;
    }
  }

  ngOnDestroy(): void {
  this.ngDestroy$.next();
  this.ngDestroy$.complete();
  }

  ngOnInit(): void {
    if(!this.data.isAdmin) {
      this.fetchPortfolios();
    }

    this.newPortfolioForm.get('label')?.valueChanges.pipe(
      startWith(''),
      map(value => this._filterPortfolios(value || ''))
    ).subscribe(filtered => {
      this.filteredPortfolios = filtered;
      console.log('filtered:', filtered);
    });

    this.listenToManagerEmailUpdates()
  }

  // ------------------ Manager Input ------------
  public listenToManagerEmailUpdates(): void {
    this.managerEmail$
    .pipe(
      takeUntil(this.ngDestroy$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query: string) => {
        if (!query || query.length < 3) {
          this.filteredUsers = [];
          return of([]);
        }
        return this.usersService.findMany(
          userSearchFilter(`query:${query}`),
          null,
          0,
          10,
          { avatarIconURL: 1, email: 1, name: 1, surname: 1, organization: 1 }
        );
      })
    )
    .subscribe(
      (users) => {
        this.filteredUsers = users.data;
      },
      (error) => {
        console.error('Error fetching users:', error);
        this.filteredUsers = [];
      }
    );
  }

  public onManagerSelected(email: string): void {
    const selectedUser = this.filteredUsers.find(user => user.email === email);
    if (selectedUser) {
      this.team.manager = selectedUser;
      this.team.managerID = selectedUser._id;
      this.fetchPortfolios();
    }
  }

  // --------------------- Dialog Actions -------------------
  public saveUpdate(): void {
    if (this.team) {
      this.teamsService.updateTeamById(this.teamsService.constructRequestPayload(this.team)).subscribe(
        updatedTeam => {
          if (updatedTeam) {
            this.teamsService.fetchATeamById(updatedTeam._id).subscribe(team => {
              this.team = team;
              this.dialogRef.close(team);
            });
          }
        },
        error => {
          this.snackBar.open('Failed to update team. Please try again.', 'Close', { duration: 3000 });
        }
      );
    }
  }

  public createTeam(): void {
    if (this.team) {
      this.teamsService.insertOne(this.teamsService.constructRequestPayload(this.team)).subscribe(
        (response: any) => {
          this.teamsService.fetchATeamById(response.data._id).subscribe(team => {
            this.team = team;
            this.dialogRef.close(team);
          });
        },
        error => {
            this.snackBar.open('Failed to create team. Please try again.', 'Close', { duration: 3000 });
        }
      );
    }
  }

  // ---------------- Naming ---------------- 
  public applyName(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.team.name = inputElement.value;
  }

  // ------------- Members Container --------------
  public deleteMember(memberEmail: string): void {
    if (this.team) {
      this.team.members = this.team.members.filter(member => member.email !== memberEmail);
    }
  }

  public toggleNewMemberRow(): void {
    this.addingNewMember = !this.addingNewMember;
    if (!this.addingNewMember) {
      this.cancelAddingNewMember();
    } else {
      this.newMemberForm.reset({ email: '', role: 'viewer' });
    }
  }

  public saveNewMember() {
    if (this.newMemberForm.valid && this.team) {
      const memberEmail = this.newMemberForm.value.email.trim();
        const isAlreadyAdded = this.team.members.some(member => member.email === memberEmail);
      if (isAlreadyAdded) {
        this.newMemberForm.controls['email'].setErrors({ alreadyExists: true });
        return;
      }
        this.team.members.unshift(this.newMemberForm.value);
      this.cancelAddingNewMember();
    } else {
      this.newMemberForm.markAllAsTouched();
    }
  }

  private cancelAddingNewMember(): void {
    this.addingNewMember = false;
    this.newMemberForm.reset({ email: '', role: 'viewer' });
  }

  // ------------- Portfolio Container -------------
  public fetchPortfolios(): void {
    if (this.data.isAdmin) {
      this.portfoliosService.findOwnedPortfoliosByUserId(this.team.managerID || this.team.manager._id as string).subscribe(
        (portfolios) => {
          this.allPortfolios = portfolios;
          this.filteredPortfolios = this._filterPortfolios(''); // Apply filtering immediately
        },
        (error) => {
          console.error('Error fetching portfolios:', error);
        }
      );
    } else {
      this.portfoliosService.findOwnedPortfolios().subscribe(
        (portfolios) => {
          this.allPortfolios = portfolios;
          this.filteredPortfolios = this._filterPortfolios(''); // Apply filtering immediately
        },
        (error) => {
          console.error('Error fetching portfolios:', error);
        }
      );
    }
  }

  public onPortfolioSelected(event: any): void {
    const selectedPortfolio: Portfolio = event.option.value;
    if (selectedPortfolio) {
      this.newPortfolioForm.patchValue({
        name: selectedPortfolio.name,
        id: selectedPortfolio._id
      });
    }
  }

  private _filterPortfolios(value: string): Portfolio[] {
    const filterValue = value.toLowerCase();
    return this.allPortfolios
      .filter(portfolio => !this.team.resources.some(resource => resource.id === portfolio._id))
      .filter(portfolio => portfolio.name.toLowerCase().includes(filterValue));
  }

  private cancelAddingNewPortfolio(): void {
    this.addingNewPortfolio = false;
    this.newPortfolioForm.reset({ label: '' });
  }

  public toggleNewPortfolioRow(): void {
    this.addingNewPortfolio = !this.addingNewPortfolio;
    if (!this.addingNewPortfolio) {
      this.cancelAddingNewPortfolio();
    } else {
      this.newPortfolioForm.reset({ label: '' });
    }
  }

  public saveNewPortfolio() {
    if (this.newPortfolioForm.valid && this.team) {
      const portfolioName = this.newPortfolioForm.value.name;
      const portfolioId = this.newPortfolioForm.value.id;
      if (!portfolioId) {
        console.error("No valid portfolio selected.");
        return;
      }
      const isAlreadyAdded = this.team.resources.some(resource => resource.id === portfolioId);
      if (isAlreadyAdded) {
        console.warn("Portfolio is already added to the team.");
        return;
      }
      const newResource = {
        type: "portfolio" as ResourceType,
        label: portfolioName,
        id: portfolioId
      };
      this.team.resources.unshift(newResource);
      this.filteredPortfolios = this._filterPortfolios('');
      this.cancelAddingNewPortfolio();
    } else {
      this.newPortfolioForm.markAllAsTouched();
    }
  }

  public deletePortfolio(portfolioLabel: string): void {
    if (this.team) {
      this.team.resources = this.team.resources.filter(resource => resource.label !== portfolioLabel);
    }
  }

  public navigateToPortfolio(resource: any):void {
    this.router.navigate([{ outlets: {
      primary: ['portfolios', resource.id],
      detail: null
    }}]);
    this.dialogRef.close(true);
  }
}