<div class="feature-details">

  <!-- ====== Header ====== -->
  <div class="sidenav-header">
    <div class="left">
      <span class="title">{{ pvModules.length }} Modules Details</span>
    </div>
    <div class="right">
      <mat-icon class="material-icons-outlined" (click)="onCloseSidenav()">close</mat-icon>
    </div>
  </div>

  <!-- ====== Content ====== -->
  <div class="sidenav-content">

    <!-- === Inspection Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('inspection')">
        <span class="section-title">Inspection</span>
        <mat-icon>{{ showInspection ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>

      <div class="section-container" [@expandCollapse]="showInspection ? 'expanded' : 'collapsed'">
        <div class="inspection-card">

          <div class="row-info">
            <span class="info-title">Inspected on:</span>
            <span class="info-value">12.12.2025</span>
          </div>

          <div class="sub-section">
            <span class="info-title">Temperatures °C:</span>
            <table class="temperature-table">
              <thead>
                <tr class="temperature-header">
                  <th class="temperature-cell">Min</th>
                  <th class="temperature-cell">Max</th>
                  <th class="temperature-cell">Avg</th>
                  <th class="temperature-cell">Delta</th>
                </tr>
              </thead>
              <tbody>
                <tr class="temperature-values">
                  <td class="temperature-cell">{{ temperatureStats.min.toFixed(2) }}</td>
                  <td class="temperature-cell">{{ temperatureStats.max.toFixed(2) }}</td>
                  <td class="temperature-cell">{{ temperatureStats.avg.toFixed(2) }}</td>
                  <td class="temperature-cell">{{ temperatureStats.delta.toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </section>

    <!-- === Identification Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('identification')">
        <span class="section-title">Identification</span>
        <mat-icon>{{ showIdentification ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>

      <div class="section-container" [@expandCollapse]="showIdentification ? 'expanded' : 'collapsed'">
        <ng-container *ngFor="let field of identificationFields">
          <div class="row-info">
            <span class="info-title">{{ field.label }}:</span>

            <!-- Display -->
            <span
              *ngIf="editingKey !== getFieldKey(field)"
              class="info-value"
              [ngClass]="{ 'undefined-info': fieldValues.get(field.key) === 'N/A' }"
              (click)="startEditing(field)"
            >
              {{ fieldValues.get(field.key) }}
            </span>

            <!-- Edit -->
            <input
              *ngIf="editingKey === getFieldKey(field)"
              class="info-input"
              [(ngModel)]="editingValue"
              (blur)="finishEditing(field)"
              (keydown.enter)="finishEditing(field)"
              [ngClass]="{ 'input-error': isInvalid(field, editingValue) }"
              [placeholder]="fieldValues.get(field.key) === 'Mixed' ? '(Multiple values)' : ''"
              autofocus
            />
          </div>
        </ng-container>
      </div>
    </section>

    <!-- === Location Section === -->
    <section>
      <div class="section-header cursor-pointer" (click)="toggleSection('location')">
        <span class="section-title">Location</span>
        <mat-icon>{{ showLocation ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </div>

      <div class="section-container" [@expandCollapse]="showLocation ? 'expanded' : 'collapsed'">
        <ng-container *ngFor="let field of locationFields">
          <div class="row-info">
            <span class="info-title">{{ field.label }}:</span>

            <!-- Display -->
            <span
              *ngIf="editingKey !== getFieldKey(field)"
              class="info-value"
              [ngClass]="{ 'undefined-info': fieldValues.get(field.key) === 'N/A' }"
              (click)="startEditing(field)"
            >
              {{ fieldValues.get(field.key) }}
            </span>

            <!-- Edit -->
            <input
              *ngIf="editingKey === getFieldKey(field)"
              class="info-input"
              [(ngModel)]="editingValue"
              (blur)="finishEditing(field)"
              (keydown.enter)="finishEditing(field)"
              [ngClass]="{ 'input-error': isInvalid(field, editingValue) }"
              [placeholder]="fieldValues.get(field.key) === 'Mixed' ? '(Multiple values)' : ''"
              autofocus
            />
          </div>
        </ng-container>
      </div>
    </section>

  </div>

  <!-- ====== Footer ====== -->
  <div class="sidenav-footer">
    <button
      color="accent"
      mat-raised-button
      type="button"
      (click)="onToggleAnnotationForm()">
      <mat-icon>warning</mat-icon>
      <span>Add annotation</span>
    </button>
  </div>
</div>
