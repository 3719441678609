import { Component, ElementRef, HostListener, OnChanges, OnInit, Input, Output, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil,skip, timeout } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { Options } from '@angular-slider/ngx-slider';
import {MatSliderModule} from '@angular/material/slider';



/* OL */
import RegularShape from 'ol/style/RegularShape'
import { Circle } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { Extent, equals, getIntersection, containsCoordinate, getHeight } from 'ol/extent';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import Draw, { createBox } from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { ImageStatic, Source } from 'ol/source';
import {getUid} from 'ol/util';
import Interaction from 'ol/interaction/Interaction';

import { AnnotationsService } from './../../pages/files/file/sidebar/annotations/annotations.service';
import { DrawType, AnnotationsStateDimension, Annotation } from './../../pages/files/file/sidebar/annotations/annotation.model';
import { FileModel } from '../../pages/files/file/file.model';
import { FilesService } from '../../pages/files/files.service';
import { OlImageService } from './ol-image.service';
import { OLLayerService } from './../openlayers/layer.service';
import { OLMapsService } from './../openlayers/maps.service';
import { InspectionService } from 'src/app/pages/files/inspection/inspection.service';
import ImageLayer from 'ol/layer/Image';
import { DialogService } from '../dialog/dialog.service';
import { WebSocketService } from 'src/shared/websocket/websocket.service';
import { until } from 'protractor';
import Label from 'cesium/Source/Scene/Label';
import { PermissionRole } from 'src/app/pages/files/file/permission.model';
import { SidebarService } from 'src/app/pages/files/file/sidebar/sidebar.service';
import { Login } from 'src/app/pages/login/login.model';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { Layer } from 'ol/layer';
import { MatDialog } from '@angular/material/dialog';
import { PanoDialogComponent } from '../pano-dialog/pano-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '../permissions/permissions.service';



@Component({
  selector: 'app-ol-image',
  templateUrl: './ol-image.component.html',
  styleUrls: ['./ol-image.component.scss'],
})

export class OlImageComponent implements OnDestroy {
  activeDetailID = '';
  currentSeverityColor: 'blue';
  draw: Draw;
  login: Login;
  drawActive: boolean = false;
  drawType: DrawType;
  editing: number;
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fileModel: FileModel;
  loading: boolean;
  map: Map;
  noImage = true;
  overviewMapActive: boolean = true;
  source = new VectorSource({wrapX: false});
  sourceTempArea = new VectorSource({wrapX: false});
  sourceTempSpot = new VectorSource({wrapX: false});
  vector = new VectorLayer({
    source: this.source,
    className: "Drawing"
  });
  mapOverlayer: any;
  value: number = 50;
  showToolBar: boolean = false;
  showToolPallets: boolean = false;
  showOpacity: boolean = false;
  showIsotherm: boolean = false;
  measureSpotActive : boolean = false;
  measureAreaActive : boolean = false;
  addingNormalAnnotation : boolean = false;
  permissionRole = PermissionRole;
  private subscription: any;
  private tempMessageValue: any;
  private standbyFeatureToBeDeleted: Feature = undefined;
  private featureHovered: boolean=false;
  minTemperature: number= undefined;
  maxTemperature: number= undefined;
  minFocus: number= undefined;
  maxFocus: number= undefined;
  isothermActivation: boolean = false;
  options: Options = {
    floor: undefined,
    ceil: undefined,
    translate: (value: number): string => {
      return value + '°C';
    },
    step: 0.01,
    hideLimitLabels: true,
  };
  optionsIsotherm: Options = {
    floor: undefined,
    ceil: undefined,
    translate: (value: number): string => "",
    step: 0.01,
    hideLimitLabels: true,
    disabled: !this.isothermActivation,
  };
  temperatureSliderInitialized = false
  actualPalette = undefined
  paletteList={}
  limitMin: number;
  limitMax: number;

  @ViewChild('imageMap', { static: false }) imageMap!: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper!: ElementRef;
  private ngDestroy$ = new Subject();

  constructor(
    public filesService: FilesService,
    private route: ActivatedRoute,
    private router: Router,
    private olImageService: OlImageService,
    private oLMapsService: OLMapsService,
    private oLLayerService: OLLayerService,
    private annotationsService: AnnotationsService,
    private inspectionService: InspectionService,
    private dialogService: DialogService,
    private sidebarService: SidebarService,
    private webSocketService: WebSocketService,
    private loginStateService: LoginStateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public permissionsService: PermissionsService
  ) {

    // Check if edit single or multiple files
    this.router.events
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      const firstChild = this.route.snapshot.parent.children[0];
      const secondChild = this.route.snapshot.parent.children[1];
      const newDetailID = secondChild && secondChild.outlet === 'detail' ? secondChild.params['fileID'] : '';

      // Set activeDetailID from file/:fileID parameter
      if (this.activeDetailID !== newDetailID) { // Detail was changed
        this.activeDetailID = newDetailID;
        if (this.activeDetailID === '') {
          if(this.imageMap.nativeElement.children.length > 0) {
            this.imageMap.nativeElement.children[0].remove();
          }
          this.fileModel = undefined;
        } else {
          this.temperatureSliderInitialized = false;
          this.subscribeToWebSocket()
          this.paletteList={}
          this.getFile(this.activeDetailID);        
        }
      }
    });
    this.filesService.data$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      //this.getFile(this.activeDetailID);
    });

    this.olImageService.editing$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.drawing(data);
      this.updateAnnotations();
    });

    this.olImageService.drawInteraction$
    .pipe(takeUntil(this.ngDestroy$), skip(1))
    .subscribe((status) => {
        if (!status){
          this.map.removeInteraction(this.draw)
        }
    });

    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((login) => {
        this.login = login;
    });

    this.olImageService.type$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.drawType = data;
      this.clearVector();
      this.switchType();  
    });

    this.annotationsService.annotations$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (this.fileModel) {
        this.fileModel.annotations = data;
        this.updateAnnotations();
      }
    });

    this.olImageService.status$
    .pipe(takeUntil(this.ngDestroy$), skip(2))
    .subscribe(data => {
      if (data && (this.measureSpotActive || this.measureAreaActive)) {
        if (data === "put back draft" || data === "remove draft") {
          if (this.standbyFeatureToBeDeleted) {
            if (data === "put back draft") {
              this.putBackFeature(this.standbyFeatureToBeDeleted);
            } else if (data === "remove draft") {
              this.removeTempPolygonFeature(this.standbyFeatureToBeDeleted);
            }
          }
          this.showOpacity = false;
          if (!this.drawingActive()) {
            this.map.addInteraction(this.draw);
          }
        } else if (data === "clear map") {
          this.measureAreaActive = false;
          this.measureSpotActive = false;
          this.showOpacity = false;
          this.map.removeLayer(this.vector);
          this.removeAllDrawInteractions();
          this.clearTemperatureDrawings();
          this.draw = undefined;
          this.drawActive = false;
        }
      }
    });

    //check X translations
    this.olImageService.xTransNew$
    .pipe(takeUntil(this.ngDestroy$), skip(1))
    .subscribe((newValue)=>{
    this.onxTransChange(newValue);
    })

    //check Y translations
    this.olImageService.yTransNew$
    .pipe(takeUntil(this.ngDestroy$), skip(1))
    .subscribe((newValue)=>{
      this.onyTransChange(newValue);
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onTemperatureSliderChange() {
    let fileID
    if(this.fileModel.thermalSubFileModel){
      fileID = this.fileModel.thermalSubFileModel._id.toString()
    } else {
      fileID = this.fileModel._id.toString()
    }
    this.olImageService.convertPalette(fileID,this.actualPalette,this.minTemperature,this.maxTemperature,this.minFocus,this.maxFocus,this.isothermActivation)
  }

  onTemperatureFocusSliderChange() {
    let fileID
    if(this.fileModel.thermalSubFileModel){
      fileID = this.fileModel.thermalSubFileModel._id.toString()
    } else {
      fileID = this.fileModel._id.toString()
    }
    this.olImageService.convertPalette(fileID,this.actualPalette,this.minTemperature,this.maxTemperature,this.minFocus,this.maxFocus,this.isothermActivation)
  }

  subscribeToWebSocket(){
    if (this.webSocketService.isConnected) {
      this.webSocketService.connect();
    } else {
      this.webSocketService.connect();
    }
    this.subscription = this.webSocketService.getMessages().subscribe(
      (message) => {
        switch (message['type']){
          case "measure-temperature": {
            this.tempMessageValue = message; 
            if (!this.temperatureSliderInitialized) {
              this.options['floor'] = message['data']['min'].toFixed(2)
              this.options['ceil'] = message['data']['max'].toFixed(2)
              this.optionsIsotherm['floor'] = message['data']['min'].toFixed(2)
              this.optionsIsotherm['ceil'] = message['data']['max'].toFixed(2)
              if (!this.minTemperature && !this.maxTemperature) {
                this.minTemperature = Number(message['data']['min'].toFixed(2))
                this.maxTemperature = Number(message['data']['max'].toFixed(2))
              }
              this.limitMin = Number(message['data']['min'].toFixed(2))
              this.limitMax = Number(message['data']['max'].toFixed(2))
              this.temperatureSliderInitialized = true;
              if(!this.minFocus && !this.maxFocus) {
                this.maxFocus = this.limitMax;
                this.minFocus = this.limitMin;
              }
            }
            break; 
          } 
          case "convert-palette": this.updatePalette(message['data']['image']); break; 
          default: /*console.log("undefined message type: ",message)*/ break;
        }
      },
      (error) => {
       // console.error('WebSocket error:', error);
        this.subscription.unsubscribe();
        this.webSocketService.unsubscribe()
        this.tempMessageValue=undefined;
        this.subscription = undefined;
        this.subscribeToWebSocket()
        this.InitializeThermalMS()
      }
    );
  }

  findLayerByClassName (name: string){
    return this.map.getAllLayers().find((layer)=> layer.getClassName()===name)
  }

  getActualPalette(fileID){
     this.olImageService.getActualPalette(fileID).pipe(takeUntil(this.ngDestroy$)).subscribe(
      (response)=>{
        this.actualPalette = response['data']
        this.paletteList[this.actualPalette]= true
        this.map.getAllLayers()[1].set("className_",`palette ${this.actualPalette}`)
      }
    )
  }

  addLayer(name,base64ImageData){
    let extent = [0, 0, this.fileModel.width, this.fileModel.height]
    if (this.fileModel.thermalSubFileModel){
      const scalingFactor = this.fileModel.thermalSubFileModel?.scalingFactor
      const center = this.filesService.centerAdjustments
      extent = [center[0], center[1], (this.fileModel.thermalSubFileModel.width*scalingFactor)+center[0], (this.fileModel.thermalSubFileModel.height*scalingFactor)+center[1]]
    }
   const Layer =new ImageLayer({
    className: name,
      zIndex: 0,
      source: new ImageStatic({
        crossOrigin: 'anonymous',
        url: base64ImageData,
        imageExtent: extent
      })
    })
    this.map.addLayer(Layer)
  }

  updatePalette (base64ImageData){
    const allLayers = this.map.getAllLayers();
    if(this.fileModel.thermalSubFileModel){
      const layer = allLayers[2]
      const extent = layer.getProperties().source.imageExtent_
      const source = new ImageStatic({
        crossOrigin: 'anonymous',
        url: base64ImageData,
        imageExtent: extent
      })
      layer.setSource(source)
    } else {
      const layer =allLayers.find((layer)=>layer.getClassName().includes(this.actualPalette))
      if (!layer){
        this.addLayer(`palette ${this.actualPalette}` ,base64ImageData)
      } else { 
          let extent = [0, 0, this.fileModel.width, this.fileModel.height]
          const source = new ImageStatic({
          crossOrigin: 'anonymous',
          url: base64ImageData,
          imageExtent: extent
        })
        layer.setSource(source)
  
      }
      this.updateAnnotations()
      this.updateLayersOpapity(this.actualPalette)

    }
  }

  updateLayersOpapity(newPalette){
    const allLayers = this.map.getAllLayers();
    allLayers.forEach((layer) => {
      // Check if the layer's className starts with 'palette'
      if (layer.getClassName() && layer.getClassName().startsWith('palette')) {
        layer.setOpacity(0); // Set opacity to 0 for matching layers
      }
      // Check if the layer's name includes the newPalette string
      if (layer.getClassName() && layer.getClassName().includes(newPalette)) {
        layer.setOpacity(1); // Set opacity to 1 for matching layers
      }
    });
  }

  displayPanoPopup(){
    if (this.fileModel.orderID){
      const filter = { $and: [{ orderID: this.fileModel.orderID},{tags:"360_stitched"}] };
      this.filesService.findMany(filter,null,null,null,null).subscribe((resp)=>{
      if (resp.data){
        const dialogRef = this.dialog.open(PanoDialogComponent, {
          width: '100%', 
          height: '90%', 
          data:{
            panoList:resp.data
          }
        });
      }
      else {
        this.translate.get([
          'PANO.FETCH.ERROR.NO_PANOS'
        ]).subscribe(translations => {
          this.dialogService.showDialog(translations['PANO.FETCH.ERROR.NO_PANOS']+': FF'+ this.fileModel.orderID, null, null, null, false, true);
        })
      }
      })
    } else {
      this.translate.get([
        'PANO.FETCH.ERROR.NO_ORDER_ID'
      ]).subscribe(translations => {
        this.dialogService.showDialog(translations['PANO.FETCH.ERROR.NO_ORDER_ID'], null, null, null, false, true);
      })
    }
  }

  updatePaletteList(newPalette){
    for (const key in this.paletteList) {
      if (this.paletteList.hasOwnProperty(key)) {
        this.paletteList[key] = false;
      }
    }
    this.paletteList[newPalette]=true
    this.actualPalette=newPalette
  }

  get isFullscreen(): boolean {
    return (window.innerWidth == screen.width && window.innerHeight == screen.height)
  }

  onSliderChange(newValue: number) {
    this.map.getAllLayers()[2].setOpacity(newValue/100)
    this.map.getAllLayers()[2].set('draggable', true)
  }

  emptyFunction(){
  }

  setNewComment(){
    this.filesService.openAnnotationTab(true)
    if (this.standbyFeatureToBeDeleted){
      this.putBackFeature(this.standbyFeatureToBeDeleted)
    }
    if (this.measureAreaActive || this.measureSpotActive){
      this.olImageService.status$.next("clear map")
    }
    if (this.showToolBar){
      this.showToolBar=false
    }
    this.addingNormalAnnotation = !this.addingNormalAnnotation
    if (this.addingNormalAnnotation){
      this.annotationsService.setNewComment(true)
    }else{
      this.annotationsService.setNewComment(false)
    }
  }

  ExtendToolbar(){
    if (this.addingNormalAnnotation){
      this.addingNormalAnnotation=false
      this.annotationsService.addNewAnnotation$.next(false)
    }
   this.showToolBar =!this.showToolBar
   if (this.fileModel.thermalSubFileModel){
    if (this.showToolBar){
      this.map.getAllLayers()[2].setOpacity(0.5)
     }else{
      this.map.getAllLayers()[2].setOpacity(0)
     }
   }
   this.measureSpotActive = false;
   this.measureAreaActive = false;
   this.showOpacity = false
   this.showToolPallets = false;
   this.clearTemperatureDrawings()
  }

  extendOpacityBar(){
    this.value = 50
    this.showOpacity =!this.showOpacity
    if (this.showOpacity){
      this.showToolPallets = false
      this.showIsotherm = false
    }
  }

  extendIsothermBar() {
    this.showIsotherm = !this.showIsotherm
    if (this.showIsotherm) {
      this.showToolPallets = false
      this.showOpacity = false
    }
  }

  extendPalettesBar(){
    this.showToolPallets =!this.showToolPallets
    if (this.showToolPallets){
      this.showOpacity = false
      this.showIsotherm = false
    }
  }

  onChangeIsothermActivation(): void {
    this.optionsIsotherm = Object.assign({}, this.optionsIsotherm, {disabled: !this.isothermActivation});
    this.maxFocus = this.limitMax;
    this.minFocus = this.limitMin;
  }
  
  onxTransChange(newValue: number) {
    const matchHeight =this.fileModel.thermalSubFileModel.height
    const matchWidth =this.fileModel.thermalSubFileModel.width
    const scalingFactor =this.fileModel.thermalSubFileModel.scalingFactor
    const cX =this.filesService.centerAdjustments[0]
    const cY =this.filesService.centerAdjustments[1]
    const yTrans = this.fileModel.thermalSubFileModel.yTrans
    this.fileModel.thermalSubFileModel.xTrans= newValue;

    const newExtent = [cX+newValue*scalingFactor, cY+yTrans*scalingFactor, matchWidth*scalingFactor+cX+newValue*scalingFactor, matchHeight*scalingFactor+cY+yTrans*scalingFactor]
    const layer = this.map.getAllLayers()[2]
    const newSource=new ImageStatic({
      crossOrigin: 'anonymous',
      url:layer.getProperties().source.url_,
      imageExtent: newExtent,
      
    })
    this.map.getAllLayers()[2].setSource(newSource)
  }

  updateExtent (){
    const matchHeight =this.fileModel.thermalSubFileModel.height
    const matchWidth =this.fileModel.thermalSubFileModel.width
    const scalingFactor =this.fileModel.thermalSubFileModel.scalingFactor
    const cX =this.filesService.centerAdjustments[0]
    const cY =this.filesService.centerAdjustments[1]
    const yTrans = this.fileModel.thermalSubFileModel.yTrans
    const xTrans = this.fileModel.thermalSubFileModel.xTrans

    const newExtent = [cX+xTrans*scalingFactor, cY+yTrans*scalingFactor, matchWidth*scalingFactor+cX+xTrans*scalingFactor, matchHeight*scalingFactor+cY+yTrans*scalingFactor]
    const layer = this.map.getAllLayers()[2]
    const newSource=new ImageStatic({
      crossOrigin: 'anonymous',
      url:layer.getProperties().source.url_,
      imageExtent: newExtent,
      
    })
    this.map.getAllLayers()[2].setSource(newSource)
  }

  onyTransChange(newValue: number) {
    const matchHeight =this.fileModel.thermalSubFileModel.height
    const matchWidth =this.fileModel.thermalSubFileModel.width
    const scalingFactor =this.fileModel.thermalSubFileModel.scalingFactor
    const cX =this.filesService.centerAdjustments[0]
    const cY =this.filesService.centerAdjustments[1]
    const xTrans = this.fileModel.thermalSubFileModel.xTrans
    this.fileModel.thermalSubFileModel.yTrans= newValue;

    const newExtent = [cX+xTrans*scalingFactor, cY+newValue*scalingFactor, matchWidth*scalingFactor+cX+xTrans*scalingFactor, matchHeight*scalingFactor+cY+newValue*scalingFactor]
    const layer = this.map.getAllLayers()[2]
    const newSource=new ImageStatic({
      crossOrigin: 'anonymous',
      url:layer.getProperties().source.url_,
      imageExtent: newExtent,
    })
    this.map.getAllLayers()[2].setSource(newSource)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.updateSize();
    }, 10);
  }

  @Output() updateSize(): void {
    if(this.map) {
      this.map.updateSize();
    } else {
      setTimeout(() => {
        this.updateSize();
      }, 100);
    }
  }

  getFile(fileID: string): void {
    this.clearSourceTemArea()
    this.clearSourceTemSpot()
    this.showToolBar= false; // each time we have new image, un-show the IR toolbar
    if (fileID) {
      this.fetchingChange$.next(true);
      this.filesService.findOne(fileID, {
        "annotations":1,
        "public":1,
        "hidden":1,
        "downloadable":1,
        "height":1,
        "thumbnailLink":1,
        "webViewLink":1,
        "width":1,
        "type":1,
        "master":1,
        "meta":1,
        "orderID":1,
        "yaw":1,
        "relativeAltitude":1,
        "pitch":1,
        "annotationStats":1
      })
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
      response => {
        const newFileModel = response?.data as FileModel;
        const oldFileModel = this.fileModel;
        this.fileModel = newFileModel;
        this.filesService.propertiesCalc(this.fileModel)
        this.fileModel=this.filesService.enrichFileWithFeatures(this.fileModel)

        if (oldFileModel && newFileModel && oldFileModel._id !== newFileModel._id || oldFileModel === undefined) {
          //grabbing master from DataSourceBeforeFilter
          if (this.fileModel.master){
              this.filesService.getMaster(this.fileModel.master)         
          } else{
            this.filesService.masterFileModel = undefined
          }

          this.loadImage();
          this.resetView();
          this.updateAnnotations();
          this.showOpacity = false
          this.showToolPallets = false
          this.draw=undefined
          this.removeAllDrawInteractions()

          if (this.fileModel.type != undefined){
            this.InitializeThermalMS()
          }
        }
      });
    }
  }

  InitializeThermalMS(){
    let fileID
    let width
    let height
    let fraction
    if(this.fileModel.thermalSubFileModel){
      fileID = this.fileModel.thermalSubFileModel._id
      width = this.fileModel.thermalSubFileModel.width
      height = this.fileModel.thermalSubFileModel.height
      fraction =width/640
    } else {
      fileID = this.fileModel._id
      width = this.fileModel.width
      height = this.fileModel.height
      fraction =width/640
    }
    // getting min and max for each image
    this.olImageService.measureSpot(fileID,[0,0],[width-0.0000000001,height-0.000000001],fraction)
    if (!this.actualPalette) { 
      // if this is the first image
      this.getActualPalette(fileID)
    } else {
      if (this.minFocus && this.minTemperature) {
        this.onTemperatureFocusSliderChange()
      }
    }
  }

  convertPalette(newPalette){
    if (this.editing>=0){
      this.annotationsService.setNewComment(false)
    }
    if (this.standbyFeatureToBeDeleted){
      this.putBackFeature(this.standbyFeatureToBeDeleted)
    }
    this.measureAreaActive = !this.measureAreaActive
    this.showOpacity = false
    this.addingNormalAnnotation = false
    this.measureSpotActive = false
    this.measureAreaActive = false

    let fileID
    if(this.fileModel.thermalSubFileModel){
      fileID = this.fileModel.thermalSubFileModel._id.toString()
    } else {
      fileID = this.fileModel._id.toString()
    }
    this.olImageService.convertPalette(fileID,newPalette,this.minTemperature,this.maxTemperature,this.minFocus,this.maxFocus,this.isothermActivation)
    this.updatePaletteList(newPalette)
  }

  getPalettes(){
    this.olImageService.getPalettes().pipe(takeUntil(this.ngDestroy$)).subscribe(
      (response)=>{
        //TODO: Handle response
      }
    )
  }

 mapHasLayer (map,className): boolean{
  const layers = map.getLayers().getArray();
  // Check if a layer with the specified class name exists
  const layerExists = layers.some(layer => {
    return layer.getClassName() === className;
  });
  return layerExists
 }

 removeLayersByClassName(map, className) {
    const layersToRemove = map.getLayers().getArray().filter(layer => layer.getClassName() === className);
    layersToRemove.forEach(layer => {
      map.removeLayer(layer);
    });
  }

  measure(){
    this.filesService.openAnnotationTab(true)
    if (this.editing>=0){
      this.annotationsService.setNewComment(false)
    }
    if (this.standbyFeatureToBeDeleted){
      this.putBackFeature(this.standbyFeatureToBeDeleted)
    }
    this.showOpacity = false
    this.showIsotherm = false
    this.showToolPallets = false
    this.measureAreaActive = false
    this.addingNormalAnnotation = false
    this.measureSpotActive = !this.measureSpotActive

    if (this.measureSpotActive){
      this.annotationsService.closeSetNew(false)
      this.drawing(null)
      if(this.map) {
        this.clearVector();
        this.map.removeLayer(this.vector)
        this.removeAllDrawInteractions()
        this.clearTemperatureDrawings()
        this.draw = undefined
        this.drawActive = false;
      }
      this.drawActive = false;
      this.drawing(-2)
    } else {
      this.drawActive = false
      this.clearTemperatureDrawings()
      this.drawing(null)
    }
  }

  measureArea(){
    this.filesService.openAnnotationTab(true)
    if (this.editing>=0){
      this.annotationsService.setNewComment(false)
    }
    if (this.standbyFeatureToBeDeleted){
      this.putBackFeature(this.standbyFeatureToBeDeleted)
    }
    this.measureAreaActive = !this.measureAreaActive
    this.showOpacity = false
    this.showIsotherm = false
    this.showToolPallets = false
    this.addingNormalAnnotation = false
    this.measureSpotActive = false
    if (this.measureAreaActive){
      this.annotationsService.closeSetNew(false)
      this.drawing(null)
      if(this.map) {
        this.clearVector();
        this.map.removeLayer(this.vector)
        //this.map.removeInteraction(this.draw);
        this.removeAllDrawInteractions()
        this.clearTemperatureDrawings()
        this.draw = undefined
        this.drawActive = false;
      }
      this.drawActive = false;
      this.drawing(-3)
    } else {
      this.drawActive = false
      this.clearTemperatureDrawings()
      this.drawing(null)
    }
  }

  removeVectorLayres(){
    const layers = this.map.getAllLayers()
    layers.forEach((layer)=>{
      if (layer instanceof VectorLayer && layer.getClassName()==="ol-layer"){
        this.map.removeLayer(layer)
      }
    })
  }

  updateAnnotations(): void {
    if(this.map && this.fileModel) {
      this.removeLayersByClassName(this.map,"Geometry")
      this.removeLayersByClassName(this.map,"Label")
      this.removeVectorLayres()
      const polygons = [];
      let i = 0;
      
      if (this.fileModel.annotations) {
        for (const annotation of this.fileModel.annotations) {
          if (annotation.coordinates && i !== this.editing) {
            const poly = new Feature({
              geometry: new Polygon(annotation.coordinates)
            });
            const colorPolygon = AnnotationsStateDimension.getColor(annotation.stateDimension);
            if (annotation.averageTemperature && !annotation.maximumTemperature){
              poly.setStyle([
                new Style({
                  geometry: poly.getGeometry().getInteriorPoint(),
                  image: new RegularShape({
                    fill: new Fill({ color: 'black' }), // Fill color for the circle
                    stroke: new Stroke({
                      color: 'black', // Stroke color for the circle
                      width: 1,
                    }),
                    radius: 10, // Radius of the circle
                    points: 20, // Number of points in the circle (adjust as needed)
                  }),
                }),
                new Style({
                  geometry: poly.getGeometry().getInteriorPoint(),
                  image: new Icon({
                    src: '/assets/images/icons/aim.svg',
                    offsetOrigin: "bottom-left",
                    color: colorPolygon,
                    anchor: [0.5, 0.5],
                    opacity: 1,
                    displacement: [0, 0]
                  })
                })
              ]);
              
              // Create a style function that applies both styles to the feature
            }else{
              poly.setStyle(new Style({
                fill: new Fill({ color: 'transparent' }),
                stroke: new Stroke({ color: colorPolygon, width: 3 }),
              }));
            }
            polygons.push(poly);
          }
          i++;
        }
      }

      if (this.editing !== null && this.editing !== -1 && this.editing !== -2 && this.editing !== -3) {
        var featurething = new Feature({
            geometry: new Polygon(this.fileModel?.annotations[this.editing]?.coordinates)
        });
        this.source.addFeature(featurething)
      }

      const sourceGeometry = new VectorSource({
        wrapX: false,
        features: polygons
      });

      const geometry = new VectorLayer({
        source: sourceGeometry,
        className: "Geometry",
      });
      this.map.addLayer(geometry);
      this.map.addLayer(this.oLMapsService.getLabelLayer(this.fileModel.annotations, this.editing));
    }
  }

  changeZoom(zoom: number): void {
    var view = this.map.getView();
    view.animate({
        center: view.getCenter(),
        zoom:   view.getZoom() + zoom,
        duration: 250
    });
  }

  toggleFullScreen(): void{
    if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
      document.exitFullscreen();
      setTimeout(() => {
        this.map.getView().fit([0, 0, this.fileModel.width, this.fileModel.height], {padding:[25, 25, 25, 25], maxZoom:20});
      }, 100);
    } else {
    this.wrapper.nativeElement.requestFullscreen();
    }
  }

  displayThermal(){
    if ( this.map.getAllLayers()[2].getOpacity()==0){   
      this.map.getAllLayers()[2].setOpacity(0.5)
    }else{
      this.map.getAllLayers()[2].setOpacity(0)
    }
  }

  loadImage(): void {
    if(this.imageMap.nativeElement.children.length > 0) {
      this.imageMap.nativeElement.children[0].remove();
    }
    this.map = this.oLMapsService.renderImage({
      extent: [0, 0, this.fileModel.width, this.fileModel.height],
      target: this.imageMap.nativeElement,
      image: this.fileModel.webViewLink,
      imagePreview: this.fileModel.thumbnailLink,
      annotations: this.fileModel.annotations,
      overVeiwMap: true,
      interactions: true,
      fileModelMatch: this.fileModel.thermalSubFileModel,
      scalingFactor: this.fileModel.thermalSubFileModel?.scalingFactor,
      original:this.fileModel,
      center: this.filesService.centerAdjustments,
    });
    if (this.fileModel.thermalSubFileModel){
      this.updateExtent ()
    }
    const overviewMap = document.getElementsByClassName('ol-overviewmap')[0];
    overviewMap?.classList.add('hideOverviewMap');
    this.map.on('moveend', () => {this.checkOverviewMap()});
    this.map.on('loadstart', () => { this.loading = true; });
    this.map.on('loadend', () => { this.loading = false; });

    if (this.fileModel.thumbnailLink === undefined) {
      this.noImage = true;
    } else {
      this.noImage = false;
    }
  }
  
  @HostListener('document:keydown', ['$event', '$event.target'])
  onKeyDown(event: KeyboardEvent, target: HTMLElement): void {
    switch (event.key) {
      case 'Escape':
        if(this.drawActive){
          this.removeAllDrawInteractions()
          this.map.addInteraction(this.draw);
        }
        break;
      case 'Backspace':
        if(this.drawActive){
          this.draw.removeLastPoint();
        }
        break;
    }
  }

  checkOverviewMap(): void {
    const overviewMap = document.getElementsByClassName('ol-overviewmap')[0];
    const mapExtent =  this.map.getView().calculateExtent();
    const intersection = getIntersection(mapExtent, [0, 0, this.fileModel?.width, this.fileModel?.height])
    if (equals(intersection, [0, 0, this.fileModel?.width, this.fileModel?.height]) && this.overviewMapActive) {
      overviewMap?.classList.add('hideOverviewMap');
      this.overviewMapActive = false;

    } else if(!equals(intersection, [0, 0, this.fileModel?.width, this.fileModel?.height]) && !this.overviewMapActive) {
      overviewMap?.classList.remove('hideOverviewMap');
      this.overviewMapActive = true;
    }
  }

  switchType(): void {
    if (this.map){
      this.olImageService.setFinished(false);
      this.map.removeInteraction(this.draw);
      this.draw = new Draw(this.getDrawPorpperties());
      this.map.addInteraction(this.draw);
      this.draw.on('drawstart', () => { 
        this.olImageService.setFinished(false);
        this.clearVector();
      });
      this.draw.on('drawend', (evt) => {
        this.olImageService.setFinished(true);

        let poly: Polygon = evt.feature.getGeometry() as Polygon;
        if (poly instanceof Circle) {
          poly = fromCircle(poly as Circle);
        }
        const coords: Array<Array<Coordinate>> = poly.getCoordinates();
        this.olImageService.setCoords(coords);
      });
    }
  }

  resetView(): void {
    this.map.getView().fit([0, 0, this.fileModel.width, this.fileModel.height], {padding:[25, 25, 25, 25], maxZoom:20});
  }

  getDrawPorpperties(): any {
    if (this.drawType === 'Circle') {
      return {source: this.source, type: 'Circle'}
    } else if (this.drawType === 'Arrow') {
      return {source: this.source, type: 'Polygon'} // ToDO style
    } else if (this.drawType === 'Polygon') {
      return {source: this.source, type: 'Polygon'}
    } else if (this.drawType === 'Point') {
      return {source: this.source, type: 'Point'}
    } else {
      return {source: this.source, type: 'Circle', geometryFunction: createBox()}
    }
  }

  getThermalExtent(): Extent{
    let layer
    if (this.fileModel.thermalSubFileModel){
      return [0,0,this.fileModel.thermalSubFileModel.width,this.fileModel.thermalSubFileModel.height] as Extent
    } else {
      layer = this.map.getAllLayers()[1];
      return layer.getProperties().source.imageExtent_ as Extent;
    }
     
  }

  getAllowedExtentToDraw():Extent{
    let layer
    if (this.fileModel.thermalSubFileModel){
      layer = this.map.getAllLayers()[2];
    } else {
      layer = this.map.getAllLayers()[1];
    }
    return layer.getProperties().source.imageExtent_ as Extent;
  }

  isInsideFrame(coords): boolean{
    const currentZoom = this.map.getView().getZoom();
    const extent = this.getThermalExtent()
    return containsCoordinate(extent, coords);
  }

  adjustedCoords(coords: Coordinate[][]): Coordinate[][] {
    const scalingFactor = this.fileModel.thermalSubFileModel.scalingFactor;
    const thermalWidth = this.fileModel.thermalSubFileModel.width;
    const thermalHeight = this.fileModel.thermalSubFileModel.height;
    const xTrans = this.fileModel.thermalSubFileModel.xTrans;
    const yTrans = this.fileModel.thermalSubFileModel.yTrans;

    const moveX = (this.fileModel.width - thermalWidth * scalingFactor) / 2 + xTrans * scalingFactor;
    const moveY = (this.fileModel.height - thermalHeight * scalingFactor) / 2 + yTrans * scalingFactor;

    const adjustedCoords: Coordinate[][] = [];

    for (const row of coords) {
        const adjustedRow: Coordinate[] = [];
        for (const point of row) {
            const adjustedX = (point[0] - moveX) / scalingFactor;
            const adjustedY = (point[1] - moveY) / scalingFactor;
            adjustedRow.push([adjustedX, adjustedY]);
        }
        adjustedCoords.push(adjustedRow);
    }
    return adjustedCoords;
}

  convertToExtent(point): Coordinate{
    const scalingFactor = this.fileModel.thermalSubFileModel.scalingFactor
    const thermalWidth = this.fileModel.thermalSubFileModel.width
    const thermalHeight = this.fileModel.thermalSubFileModel.height
    const xTrans = this.fileModel.thermalSubFileModel.xTrans
    const yTrans = this.fileModel.thermalSubFileModel.yTrans

    const moveX = (this.fileModel.width-thermalWidth*scalingFactor)/2 + xTrans * scalingFactor
    const moveY = (this.fileModel.height-thermalHeight*scalingFactor)/2 + yTrans * scalingFactor

    const adjustedPoint = [(point[0] - moveX) / scalingFactor,(point[1] - moveY) / scalingFactor]
    return adjustedPoint;
  }

  constructRectangle(center, width: number): Array<Array<Coordinate>> {
    const halfWidth = width / 2;
    
    const topLeft: Coordinate = [center[0] - halfWidth, center[1] + halfWidth];
    const topRight: Coordinate = [center[0] + halfWidth, center[1] + halfWidth];
    const bottomRight: Coordinate = [center[0] + halfWidth, center[1] - halfWidth];
    const bottomLeft: Coordinate = [center[0] - halfWidth, center[1] - halfWidth];
    
    return [
      [topLeft, topRight, bottomRight, bottomLeft,topLeft]
    ];
  }


  // if editing==null then we stop drawing
  // if editing>=0 then we are drawing to edit an annotation based on its index 
  // if editing==-1 then we are drawing for normal annotation
  // if editing==-2 then we are drawing for temperature spot measurement
  // if editing==-3 then we are drawing for temperature Area measurement
  drawing(editing: number): void {
    this.editing = editing;
    if (editing !== null) {
      if (this.editing == -1){
        if (this.map && !this.drawActive) {
          this.map.addLayer(this.vector)
          this.clearVector();
          this.switchType();
          this.map.addInteraction(this.draw);
          this.drawActive = true;
        }
      } else if (this.editing == -2){
        if(this.map && !this.drawActive) {
          const temperatureDrawingsLabels = new VectorLayer({
            source: this.sourceTempSpot,
            className: "Temperature Drawings Labels",
          });
          this.map.addLayer(temperatureDrawingsLabels);

          // setting drawing point
          this.draw = new Draw({source: this.sourceTempSpot, type: 'Point'});
          
          this.olImageService.setFinished(false);
            
            
            this.draw.on('drawstart', () => { 
              this.olImageService.setFinished(false);
              if (this.standbyFeatureToBeDeleted){
                this.putBackFeature(this.standbyFeatureToBeDeleted)
              }
            });

            this.draw.on('drawend', (evt) => {
              this.olImageService.setFinished(true);

              let point = evt.feature.getGeometry() as Polygon;
              // coords bellow belongs the actual fileModel (it could be RGB or thermal)
              let coords: Coordinate[][] = point.getCoordinates();
              this.olImageService.setCoords(coords);
              const [x, y] = coords.map(Number);
              let centerSpot: Coordinate=[x, y]

              // if actual fileModel is RGB, get the coordinates of that point inside the thermal image 
              if (this.fileModel.thermalSubFileModel){
                centerSpot= this.convertToExtent([x,y]); 
              }

              // Invert the height of that point
              const thermalHeight = this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel.height:this.fileModel.height
              centerSpot[1]= thermalHeight-centerSpot[1]

              // Building a rectangle based on zoom level
              const zoom = this.map.getView().getZoom();
              const scaleFactor = 0    
              const topLeft: Coordinate = [centerSpot[0]- scaleFactor / 2, centerSpot[1] - scaleFactor / 2];
              const bottomRight: Coordinate = [centerSpot[0] + scaleFactor / 2, centerSpot[1] + scaleFactor / 2];
              const fraction=(this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel.width:this.fileModel.width)/640
              
              // creating a layer named Temperature, in which we will display all temps annotations
              const fileID = this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel._id.toString():this.fileModel._id.toString()
              this.olImageService.measureSpot(fileID,topLeft,bottomRight,fraction)
              if (this.isInsideFrame(bottomRight) && this.isInsideFrame(topLeft) ){
                setTimeout(() => {
                  this.addLabelOnPoint(this.map, evt.feature,this.tempMessageValue['data'], temperatureDrawingsLabels);
                }, 50);
                 
              } else {
                 this.addLabelOnPoint(this.map, evt.feature, "N/A", temperatureDrawingsLabels);
              }              
            });
            
            // Declare a variable to store the hovered feature
            let hoveredFeature = null as Feature;

            // Handle the pointermove event on the hover interaction
            this.map.on('pointermove', (event) => {
              if (this.measureSpotActive){
                const pixel = this.map.getEventPixel(event.originalEvent);
                const allowedExtentToDraw= this.getAllowedExtentToDraw()
  
                if (containsCoordinate(allowedExtentToDraw,this.map.getCoordinateFromPixel(pixel))){
                  const newHoveredFeature = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                    if (layer === temperatureDrawingsLabels) {
                      return feature ;
                    }
                  }) as Feature;
                  this.removeAllDrawInteractions()
                  if (!newHoveredFeature && this.map.getInteractions().getLength()==9 && !this.featureHovered){
                    this.map.addInteraction(this.draw)  
                  }
                  
                  if (hoveredFeature !== newHoveredFeature) {
                    if (hoveredFeature) {
                      // Restore the original style of the previously hovered feature
                      const originalStyle = hoveredFeature.get('originalStyle') as Style;
                      hoveredFeature.setStyle(originalStyle);
                      hoveredFeature.set('originalStyle', undefined); // Clear the stored original style
                    }
                    
                    hoveredFeature = newHoveredFeature;
                    if (hoveredFeature) {
                      // Disable the drawing interaction when hovering over features
                      this.removeAllDrawInteractions()
    
                      // Store the original style of the hovered feature
                      const originalStyle = hoveredFeature.getStyle() as Style;
                      hoveredFeature.set('originalStyle', originalStyle);
                      // Change style for the hovered feature
                      const label = hoveredFeature.get('label');
                      if (label!=="N/A" && originalStyle){
                        this.featureHovered = true
                        const newStyle = originalStyle.clone();
                        newStyle.getText().setBackgroundFill(new Fill({ color: '#2979ffba' }));
                        newStyle.getText().setPadding([4, 4, 4, 4])
                        newStyle.setImage(
                          new Icon({
                            src: '/assets/images/icons/pointer2 blue.png',
                            scale: 1.05,
                            //color: '#2979ffba',
                            anchor: [0.5, 0.5],
                            opacity: 1,
                            displacement:[0,0]
                          })
                        )                  
                        hoveredFeature.setStyle(newStyle);
                  
                      }
                    } else {
                      // Re-enable the drawing interaction when not hovering over features
                      this.featureHovered = false
                      if (this.measureSpotActive && ! this.standbyFeatureToBeDeleted && !this.drawingActive()){
                        this.map.addInteraction(this.draw);
                      }
                      
                    }
                  }
                }else{
                  this.removeAllDrawInteractions()
                }
              }
            });

            // Listen for click event
            this.map.on('click', (event) => {
              this.olImageService.setFinished(true);
              const pixel = this.map.getEventPixel(event.originalEvent);

              // Get the clicked feature at the pixel
              const clickedFeature = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                if (layer === temperatureDrawingsLabels) {
                  return feature;
                }
              }) as Feature;

              if (clickedFeature) {

                let center: Circle = clickedFeature.getGeometry() as Circle;

                let centerCoords: Array<Array<Coordinate>> = center.getCoordinates();
                const coordinates: Array<Array<Coordinate>> = this.constructRectangle(centerCoords,10)
                

                // Trigger your action here based on the clicked feature
                const label = clickedFeature.get('label');
                if (label!==undefined){
                  if (this.standbyFeatureToBeDeleted){
                    this.sourceTempSpot.addFeature(this.standbyFeatureToBeDeleted)
                  }
                  let newAnnotation = {
                    "fileID": this.fileModel._id.toString(),
                    "comment": "",
                    "coordinates": coordinates,
                    "feature": "general-damage" as string,
                    "stateDimension": 80 as number,
                    "stateForm": "Box" as DrawType,
                    "type": 1 as number,
                    "averageTemperature":label?.average as number,
                    "maximumTemperature": null,
                    "minimumTemperature": null,
                    "deltaTemperature": null,
                  } as Annotation
                  this.annotationsService.TemperatureAnnotation([newAnnotation])
                  this.olImageService.setCoords(coordinates);
                  this.standbyFeatureToBeDeleted = clickedFeature

                  temperatureDrawingsLabels.getSource().removeFeature(clickedFeature);                
                }
              }
            });  
          this.drawActive = true;
        }
      } else if (this.editing == -3){
          if (this.map && !this.drawActive) {
            const temperatureDrawingsLabels = new VectorLayer({
              source: this.sourceTempArea,
              className: "Temperature Drawings Labels",
            });
            this.map.addLayer(temperatureDrawingsLabels);
              
              this.olImageService.setFinished(false);
              this.draw = new Draw({source: this.sourceTempArea, type: 'Circle', geometryFunction: createBox()});      

              // Drawing starts
              this.draw.on('drawstart', (evt) => { 
                this.olImageService.setFinished(false);
                if (this.standbyFeatureToBeDeleted){
                  this.putBackFeature(this.standbyFeatureToBeDeleted)
                }

              });
              
              // Drawing ends
              this.draw.on('drawend', (evt) => {
                this.olImageService.setFinished(true);
        
                let poly: Polygon = evt.feature.getGeometry() as Polygon;
                let coords: Array<Array<Coordinate>> = poly.getCoordinates();
                const coordinates = JSON.parse(JSON.stringify(coords));
                if (this.fileModel.thermalSubFileModel){
                  coords=this.adjustedCoords(coords)
                }
                this.olImageService.setCoords(coords);

                // invert the height
                const thermalHeight = this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel.height:this.fileModel.height
                const clonedCoords = JSON.parse(JSON.stringify(coords));
                const invertedCoords = clonedCoords.map(row => {
                    return row.map(coord => {
                        coord[1] = thermalHeight - coord[1];
                        return coord;
                    });
                });
                let fileID=this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel._id.toString():this.fileModel._id.toString()
                let topLeft: Coordinate = [invertedCoords[0][0][0], invertedCoords[0][2][1]];
                let bottomRight: Coordinate = [invertedCoords[0][1][0], invertedCoords[0][1][1]];
                const fraction=(this.fileModel.thermalSubFileModel?this.fileModel.thermalSubFileModel.width:this.fileModel.width)/640
                this.olImageService.measureSpot(fileID,topLeft,bottomRight,fraction)
                if (this.isInsideFrame(bottomRight) && this.isInsideFrame(topLeft) ){
                setTimeout(() => {
                  this.tempMessageValue['data']['coordinates']= coordinates
                  this.addLabelsOnPoint(this.map, poly.getFirstCoordinate(),this.tempMessageValue['data'], temperatureDrawingsLabels);
                }, 100);
              }
                
              });

              // Handle the pointermove event on the hover interaction
              let oldHoveredFeature = null as Feature;
              this.map.on('pointermove', (event) => {
                if (this.measureAreaActive){
                  const pixel = this.map.getEventPixel(event.originalEvent);
                  const allowedExtentToDraw= this.getAllowedExtentToDraw()
                  if (containsCoordinate(allowedExtentToDraw,this.map.getCoordinateFromPixel(pixel))){
                    const newHoveredFeature = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                    if (layer === temperatureDrawingsLabels) {
                        return feature as Feature;
                    }
                    }) as Feature;  
                    if (!newHoveredFeature && this.map.getInteractions().getLength()==9 && !this.featureHovered){
                      this.map.addInteraction(this.draw)  
                    }
                    
                    //this.removeAllDrawInteractions()
                    
                    
                    if (oldHoveredFeature !== newHoveredFeature) {
                      if (oldHoveredFeature) {
                        const originalStyle = oldHoveredFeature.get('originalStyle') as Style;
                        oldHoveredFeature.setStyle(originalStyle);
                        oldHoveredFeature.set('originalStyle', undefined); 
                      }
      
                      // Hovering Process
                      oldHoveredFeature = newHoveredFeature;
                      if (oldHoveredFeature) {
                        // Disable the drawing interaction when hovering over features
                        this.removeAllDrawInteractions()
      
                        // Store the original style of the hovered feature
                        const originalStyle = oldHoveredFeature.getStyle() as Style;
                        oldHoveredFeature.set('originalStyle', originalStyle);
                        
                        // Change style for the hovered feature
                        const label = oldHoveredFeature.get('label');
                        if (label !== "N/A") {
                          this.featureHovered = true
                          if (originalStyle) {
                            const newStyle = originalStyle.clone();
                            newStyle.getText().setBackgroundFill(new Fill({ color: '#2979ffba' }));
                            newStyle.getText().setPadding([4, 4, 4, 4]);
                            newStyle.setImage(new Icon({
                                src: '/assets/images/icons/icons8-plus.svg', // Replace with the path to your icon image
                                scale: 1.05, // Adjust the scale as needed
                                anchor: [0.5, 0.5], // This sets the anchor point of the icon to its center
                                opacity: 0.7,
                                displacement: [100, 12]
                            }))
                            // Combine both styles into an array
                            oldHoveredFeature.setStyle(newStyle);
                        }}
                      } else {
                        if (this.measureAreaActive) {
                          this.featureHovered = false
                          if (this.measureAreaActive && ! this.standbyFeatureToBeDeleted && !this.drawingActive()){
                            this.map.addInteraction(this.draw);
                          }
                        }
                      }
                    }
                    
                    
                  } else {
                    this.removeAllDrawInteractions()
                  }
                } 
              });
              
              // Listen for click event
              this.map.on('click', (event) => {
                this.olImageService.setFinished(true);
                const pixel = this.map.getEventPixel(event.originalEvent);

                // Get the clicked feature at the pixel
                const clickedFeature = this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                  if (layer === temperatureDrawingsLabels) {
                    return feature;
                  }
                }) as Feature;

                if (clickedFeature ) {
                  let poly: Polygon = clickedFeature.getGeometry() as Polygon;
                  let coords: Array<Array<Coordinate>> = poly.getCoordinates();
                  

                  // Trigger your action here based on the clicked feature
                  const label = clickedFeature.get('label');
                  if (label!==undefined){
                    if (this.standbyFeatureToBeDeleted){
                      this.sourceTempArea.addFeature(this.standbyFeatureToBeDeleted)
                    }
                    let newAnnotation = {
                      "fileID": this.fileModel._id.toString(),
                      "comment": "",
                      "coordinates": label?.coordinates,
                      "feature": "general-damage" as string,
                      "stateDimension": 80 as number,
                      "stateForm": "Box" as DrawType,
                      "type": 1 as number,
                      "averageTemperature":label?.average as number,
                      "maximumTemperature": label?.max as number,
                      "minimumTemperature": label?.min as number,
                      "deltaTemperature": label?.max - label?.average as number,
                    } as Annotation
                    this.annotationsService.TemperatureAnnotation([newAnnotation])
                    this.olImageService.setCoords(label?.coordinates);
                    this.standbyFeatureToBeDeleted = clickedFeature
                    
                    temperatureDrawingsLabels.getSource().removeFeature(clickedFeature);
                  }
                }
              });
            this.drawActive = true;
          }
      } else if (this.editing >= 0) {
        if (this.measureAreaActive){
          this.measureAreaActive = false
          this.showOpacity = false        
          this.removeLayersByClassName(this.map,"Temperature Drawings Labels")
          this.drawActive = false
        }
        if (this.measureSpotActive){
          this.measureSpotActive = false
          this.showOpacity = false        
          this.removeLayersByClassName(this.map,"Temperature Drawings Labels")
          this.drawActive = false
        }
        if (this.map && !this.drawActive) {
          this.map.addLayer(this.vector)
          this.map.removeInteraction(this.draw)
          if (!this.fileModel.annotations[this.editing].averageTemperature){
            this.switchType();
            this.map.addInteraction(this.draw);
            this.drawActive = true;
          }
        }
      }
    } else {
      if(this.map) {
        this.map.removeLayer(this.vector) // remove draft
        this.removeAllDrawInteractions()
        if (this.addingNormalAnnotation){
          this.addingNormalAnnotation= false
        }
        if (!this.measureAreaActive && !this.measureSpotActive){
          this.draw = undefined
          this.drawActive = false;
        }
      }
    }
  }

  removeAllDrawInteractions(): void {
    const interactions = this.map.getInteractions().getArray();
  
    // Create an array to hold the interactions to be removed
    const interactionsToRemove: Interaction[] = [];
  
    // Find and store all Draw interactions
    for (const interaction of interactions) {
      if (interaction instanceof Draw) {
        interactionsToRemove.push(interaction);
      }
    }
  
    // Remove each Draw interaction from the map
    for (const interactionToRemove of interactionsToRemove) {
      this.map.removeInteraction(interactionToRemove);
    }
  }
  addLabelOnPoint(map, feature, label, labelLayer) {
    const geometry = feature.getGeometry();
    const coordinates = geometry.getCoordinates();
  
    const labelFeature = new Feature({
      geometry: new Point(coordinates),
      label: label,
    });
  
    const labelStyle = new Style({
      text: new Text({
        text: label==="N/A"? label: label?.average?.toFixed(2).toString()+"°C",
        fill: new Fill({ color: 'white' }),
        backgroundFill: new Fill({
          color: '#000000DD',
        }),
        textAlign: 'center',
        font: '14px Roboto,sans-serif',
        offsetY: -10,
        padding: [4, 4, 4, 4],
      }),
      image: new Icon({
        src: '/assets/images/icons/pointer black.png',
        scale: 1.05, 
        anchor: [0.5, 0.5], 
        opacity: 1,
        displacement:[0,0]
      }),
    });
  
    labelFeature.setStyle(labelStyle);
  
    // Add the label feature to the label layer
    labelLayer.getSource().addFeature(labelFeature);
  }

  drawingActive():boolean{
  // Get all interactions on the map
  const interactions = this.map.getInteractions().getArray();

  // Iterate through the interactions to find the Draw interaction
  for (const interaction of interactions) {
    if (interaction instanceof Draw) {
      // Draw interaction found, return true
      return true;
    }
  }
  return false;
  }

  addLabelsOnPoint(map, topLeft, label, labelLayer) {
  
    const labelFeature = new Feature({
      geometry: new Point(topLeft),
      label: label,

    });
  
    const labelStyle = new Style({
      text: new Text({
        text: "Max: "+label?.max.toFixed(2).toString()+"°C \n"+"Min: "+label?.min.toFixed(2).toString()+"°C\n\n"+"Avg: "+label?.average.toFixed(2).toString()+"°C" ,
        fill: new Fill({ color: 'white' }),
        backgroundFill: new Fill({
          color: '#000000DD',
        }),
        textAlign: 'left',
        placement: 'line',
        font: '14px Roboto,sans-serif',
        offsetY: -35,
        offsetX: 5,
        padding: [4, 4, 4, 4],
      }),
    });
  
    labelFeature.setStyle(labelStyle);
  
    // Add the label feature to the label layer
    labelLayer.getSource().addFeature(labelFeature);
    
  }
  
  // this for clearing the map from drawing drafts
  clearVector(): void {
    this.vector.getSource().clear();
  }

  clearTemperatureDrawings(): void {
    this.removeLayersByClassName(this.map,"Temperature Drawings Labels")
    this.removeLayersByClassName(this.map,"Temperature Drawings")
  }

  removeTempPolygonFeature(labelFeature: Feature): void {
    const geo: Polygon = labelFeature.getGeometry() as Polygon
    const featureSource = this.getFeatureSource(labelFeature);
    let source
    if (featureSource === "sourceTempArea") {
      source = this.sourceTempArea
    } else if  (featureSource === "sourceTempSpot") {
      source = this.sourceTempSpot
    }    
    const features = source.getFeatures();
    const indexToRemove = features.findIndex(feature => {
      const f: Polygon = feature.getGeometry() as Polygon
      return f.getFlatCoordinates()[0] === geo.getFlatCoordinates()[0] && f.getFlatCoordinates()[1] === geo.getFlatCoordinates()[1]
    });
    if (indexToRemove !== -1) {
      source.removeFeature(features[indexToRemove]);
    } else {
      console.error('Label feature not found in the source.');
    }
    this.standbyFeatureToBeDeleted = undefined
  }

  putBackFeature(labelFeature: Feature): void {
    const geo: Polygon = labelFeature.getGeometry() as Polygon
    const featureSource = this.getFeatureSource(labelFeature);
    let source
    if (featureSource === "sourceTempArea") {
      source = this.sourceTempArea
    } else if  (featureSource === "sourceTempSpot") {
      source = this.sourceTempSpot
    }   
    source.addFeature(labelFeature)
    this.standbyFeatureToBeDeleted = undefined
  }

  getFeatureSource(feature): string {
    const geo = feature.getGeometry();
    const featureCoordinates = geo.getFlatCoordinates();

    // Check if the feature belongs to sourceTempArea
    const indexInTempArea = this.sourceTempArea.getFeatures().findIndex(feature => {
        const f = feature.getGeometry() as Polygon;
        const fCoordinates = f.getFlatCoordinates();
        return (
            fCoordinates[0] === featureCoordinates[0] &&
            fCoordinates[1] === featureCoordinates[1]
        );
    });

    if (indexInTempArea !== -1) {
        return "sourceTempArea";
    }

    // Check if the feature belongs to sourceTempSpot
    const indexInTempSpot = this.sourceTempSpot.getFeatures().findIndex(feature => {
        const f = feature.getGeometry() as Polygon;
        const fCoordinates = f.getFlatCoordinates();
        return (
            fCoordinates[0] === featureCoordinates[0] &&
            fCoordinates[1] === featureCoordinates[1]
        );
    });

    if (indexInTempSpot !== -1) {
        return "sourceTempSpot";
    }

    // If the feature doesn't belong to either source, return null
    return null;
  }

  clearSourceTemSpot(){
  this.sourceTempSpot.clear()
  }

  clearSourceTemArea(){
    this.sourceTempArea.clear()
  }
}
