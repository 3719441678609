<div class="pv-site-container">
  <div class="top-section">
    <div #map class="map">
      <div class="map-overlay">
        <button class="button" mat-raised-button color="accent" (click)="navigateToFullInspectionMode()">
          {{ 'SOLAR_SITE.HEADER.SWITCH_TO_FULL_INSPECTION_MODE' | translate }}
        </button>
      </div>
    </div>

    <div class="stats-sidebar">
      <div class="stat-card box">
        <h3>{{ siteStats?.totalModules | number }}</h3>
        <p>{{ 'SOLAR_SITE.STATS.PV_MODULES' | translate }}</p>
      </div>

      <div class="stat-card  box">
        <h3>{{ siteStats?.capacity }}</h3>
        <p>{{ 'SOLAR_SITE.STATS.TOTAL_CAPACITY' | translate }}</p>
      </div>

      <div class="stat-card  box">
        <h3>{{ siteStats?.identifiedModules }}</h3>
        <p>{{ 'SOLAR_SITE.STATS.IDENTIFIED_PV_MODULES' | translate }}</p>
      </div>

      <div class="diagnosis-title">{{ 'SOLAR_SITE.STATS.POWER_DIAGNOSIS' | translate }}</div>

      <div class="stat-card box">
        <h3>{{ siteStats?.modulesWithAnomalies }}</h3>
        <p>{{ 'SOLAR_SITE.STATS.MODULES_WITH_ANOMALIES' | translate }}</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedModulesPercent }}%</h3>
        <p>{{ 'SOLAR_SITE.STATS.AFFECTED_MODULES' | translate }}</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedPerformance }}</h3>
        <p>{{ 'SOLAR_SITE.STATS.AFFECTED_OUTPUT_PERFORMANCE' | translate }}</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedPerformancePercent }}%</h3>
        <p>{{ 'SOLAR_SITE.STATS.PERFORMANCE_AFFECTED' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="charts-and-details">
    <div class="anomalies-section">
      <h4>{{ 'SOLAR_SITE.ANALYSIS.VISUAL_AND_THERMAL_ANOMALIES_FOUND' | translate }}</h4>
      <div class="charts">
        <div class="chart bar box">
          <span class="box-subtitle">{{ 'SOLAR_SITE.ANALYSIS.DISTRIBUTION_BY_TYPE' | translate }}</span>
          <canvas #typeChart></canvas>
        </div>

        <div class="chart box">
          <span class="box-subtitle">{{ 'SOLAR_SITE.ANALYSIS.DISTRIBUTION_BY_SEVERITY' | translate }}</span>
          <canvas #severityChart></canvas>
        </div>
      </div>
    </div>

    <div class="details-container">
      <h4>{{ 'SOLAR_SITE.OVERVIEW.OVERVIEW_DETAILS' | translate }}</h4>
      <div class="details-grid box">
        <div class="details-section">
          <span class="box-subtitle">{{ 'SOLAR_SITE.OVERVIEW.WEATHER_DETAILS' | translate }}</span>
          <div class="details-infos">
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="weather"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.CLOUD_COVER' | translate }}</p>
                <strong>{{ siteStats?.weather?.condition }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="wind"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.WIND' | translate }}</p>
                <strong>{{ siteStats?.weather?.wind }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="thermometer"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.TEMPERATURE' | translate }}</p>
                <strong>{{ siteStats?.weather?.temperature }} °C</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="humidity"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.HUMIDITY' | translate }}</p>
                <strong>{{ siteStats?.weather?.humidity }}%</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="details-section">
          <span class="box-subtitle">{{ 'SOLAR_SITE.OVERVIEW.INSPECTION_DETAILS' | translate }}</span>
          <div class="details-infos">
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="drone"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.UAS' | translate }}</p>
                <strong>{{ siteStats?.inspection?.uas }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="camera"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.IMAGING_SYSTEM' | translate }}</p>
                <strong>{{ siteStats?.inspection?.imagingSystem }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="chart-up"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.PPA_RATE' | translate }}</p>
                <strong>{{ siteStats?.inspection?.ppaRate }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="solar-panel"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.SUNLIGHT_HOURS' | translate }}</p>
                <strong>{{ siteStats?.inspection?.sunlightHours }}</strong>
              </div>
            </div>
            <div class="detail full">
              <div class="detail-icon">
                <mat-icon svgIcon="calendar-dots"></mat-icon>
              </div>
              <div class="detail-value">
                <p>{{ 'SOLAR_SITE.OVERVIEW.INSPECTED_ON' | translate }}</p>
                <strong>{{ siteStats?.inspection?.period }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="anomaly-table">
    <table mat-table [dataSource]="tableRows" class="mat-table" multiTemplateDataRows>

      <!-- Anomaly Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.ANOMALIES' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ getAnnotationTypeLabel(row.type) }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.TOTAL' | translate }}</td>
      </ng-container>

      <!-- Count Column -->
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.COUNT' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.count }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.count }}</td>
      </ng-container>

      <!-- Model Types Column -->
      <ng-container matColumnDef="modelTypes">
        <th mat-header-cell *matHeaderCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.MODEL_TYPES' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.modelTypes }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.modelTypes }}</td>
      </ng-container>

      <!-- DC (kW) Column -->
      <ng-container matColumnDef="dcKw">
        <th mat-header-cell *matHeaderCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.DC_KW' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.dcKw }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.dcKw }}</td>
      </ng-container>

      <!-- DC (%) Column -->
      <ng-container matColumnDef="dcPercent">
        <th mat-header-cell *matHeaderCellDef>{{ 'SOLAR_SITE.ANOMALY_TABLE.DC_PERCENT' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.dcPercent }}%</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.dcPercent }}%</td>
      </ng-container>

      <!-- Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
