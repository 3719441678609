<div class="container">
  <ng-container *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)">
    <div class="left-side">
      <mat-form-field class="search-input" appearance="outline">
        <mat-label>{{ 'SEARCH' | translate }}</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Portfolio Name">
      </mat-form-field>
      <button
        mat-raised-button
        color="accent"
        class="primary-button add-button"
        click
        (click)="openCreatePortfolioDialog()">
        New portfolio
      </button>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-table" matSort>
        <!-- Team Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.name }} </td>
          <td mat-footer-cell *matFooterCellDef> Total: {{dataSource?.data.length}} </td>
        </ng-container>
        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef class="double-width"> Owner </th>
          <td mat-cell *matCellDef="let portfolio" class="double-width">
            <div class="manager-container">
              <div class="avatar">
                <mat-icon *ngIf="!portfolio.user.avatarIconURL" mat-list-icon>
                  account_circle
                </mat-icon>
                <img *ngIf="portfolio.user.avatarIconURL" src="portfolio.user.avatarIconURL" alt="Member Avatar" />
              </div>
              <div class="manager-details">
                <span class="manager-full-name">
                  {{portfolio.user.name}} {{portfolio.user.surname}}
                </span>
                <span class="manager-email">
                  {{portfolio.user.email}}
                </span>
              </div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef class="double-width"></td>
        </ng-container>
        <!-- Average Score Column -->
        <ng-container matColumnDef="averageScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ø Score</th>
          <td mat-cell *matCellDef="let portfolio">
            {{ portfolio.stats?.averageScore?.toFixed(2) }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Sites in Critical Condition Column -->
        <ng-container matColumnDef="critical">
          <th mat-header-cell *matHeaderCellDef>Critical</th>
          <td mat-cell *matCellDef="let portfolio">{{ portfolio.stats?.sitesStats?.critical }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Sites stats Column -->
        <ng-container matColumnDef="stats">
          <th mat-header-cell *matHeaderCellDef>Statistics</th>
          <td mat-cell *matCellDef="let portfolio">
            <div class="stats-container">
              <div class="healthy-condition">{{ portfolio.stats?.sitesStats?.minor }}</div>
              <div class="mediocre-condition">{{ portfolio.stats?.sitesStats?.important }}</div>
              <div class="critical-condition">{{ portfolio.stats?.sitesStats?.critical }}</div>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Sites in Mediocre Condition Column -->
        <ng-container matColumnDef="important">
          <th mat-header-cell *matHeaderCellDef>Mediocre</th>
          <td mat-cell *matCellDef="let portfolio">{{ portfolio.stats?.sitesStats?.important }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Sites in Fair Condition Column -->
        <ng-container matColumnDef="minor">
          <th mat-header-cell *matHeaderCellDef>Healthy</th>
          <td mat-cell *matCellDef="let portfolio">{{ portfolio.stats?.sitesStats?.minor }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Total sites Column -->
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sites</th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.sitesStats?.total }}  </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Created At Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef> Created At </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.createdAt | date }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let portfolio" class="action-cell">
            <button
              [matMenuTriggerFor]="menu"
              mat-icon-button
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="toggleEditPortfolioDialog(portfolio)">
                <mat-icon>edit</mat-icon>
                <span>{{ 'EDIT' | translate }}</span>
              </button>
              <button
                mat-menu-item
                (click)="toggleCollaborationDialog(portfolio, portfolio.userId)"
              >
                <mat-icon>share</mat-icon>
                <span>{{ 'COLLABORATION.ACTION.COLLABORATE' | translate }}</span>
              </button>
              <button
                (click)="onDelete(portfolio)"
                mat-menu-item
              >
                <mat-icon color="warn">delete</mat-icon>
                <span>{{ 'DELETE' | translate }}</span>
              </button>
            </mat-menu>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let portfolio; columns: displayedColumns;" (click)="navigateToPortfolio(portfolio._id)"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="![accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)">
    <ng-container >
      <div class="header">
        <h4 class="header-title"> {{ [accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)? 'Portfolios': ('PORTFOLIO.OWNED_PORTFOLIOS' | translate) }}</h4>
        <button *ngIf="ownedPortfolios.length > 0" mat-mini-fab [ngStyle]="{transform: 'scale(0.9)'}" color="accent" (click)="openCreatePortfolioDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="card-container section-bottom-margin">
        <mat-card
          *ngFor="let portfolio of ownedPortfolios"
          class="portfolio-card"
          (click)="navigateToPortfolio(portfolio._id)"
          role="button"
          tabindex="0"
        >
          <button
            class="edit-button"
            [matMenuTriggerFor]="menu"
            mat-icon-button
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleEditPortfolioDialog(portfolio)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'EDIT' | translate }}</span>
            </button>
            <button
              *ngIf="portfolio.role !== 'viewer' && portfolio.role !== 'editor'"
              mat-menu-item
              (click)="toggleCollaborationDialog(portfolio, portfolio.userId)"
            >
              <mat-icon>share</mat-icon>
              <span>{{ 'COLLABORATION.ACTION.COLLABORATE' | translate }}</span>
            </button>
            <button
              *ngIf="portfolio.stats.sitesStats.total === 0"
              (click)="onDelete(portfolio)"
              mat-menu-item
            >
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'DELETE' | translate }}</span>
            </button>
          </mat-menu>
          <div class="card-icon">
            <mat-icon svgIcon="portfolio"></mat-icon>
          </div>
          <mat-card-content>
            <h4 class="card-title">{{ portfolio.name }}</h4>
            <div class="info-row">
              <span class="info-label">{{ 'USER.ROLE.OWNER' | translate }}:</span>
              <span class="info-value">{{ portfolio.user?.name }} {{ portfolio.user?.surname }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'ORDERS.ACCESS_LEVEL' | translate }}:</span>
              <span class="info-value">{{
                "USER.ROLE." +
                  ((portfolio?.accessLevel ? portfolio?.accessLevel : "owner")
                    | uppercase) | translate
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.AVERAGE_SCORE' | translate }}:</span>
              <span class="info-value">{{
                (portfolio.stats?.averageScore | number : "1.2-2") || "0"
              }}</span>
            </div>
            <div class="info-row margin-break">
              <span class="info-label">{{ 'PORTFOLIO.SITES_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.total || "0"
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.CRITICAL_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 1
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.WARN_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 0.5
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.GOOD_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 0
              }}</span>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card
          *ngIf="ownedPortfolios.length === 0"
          class="portfolio-card new-portfolio-card"
          (click)="openCreatePortfolioDialog()"
          role="button"
          tabindex="0"
        >
          <div class="card-icon">
            <mat-icon class="add-portfolio-icon">add</mat-icon>
          </div>
        </mat-card>
      </div>
    </ng-container>
    <ng-container *ngIf="sharedPortfolios.length > 0">
      <h4 class="section-title">{{ 'PORTFOLIO.SHARED_PORTFOLIOS' | translate }}</h4>
      <div class="card-container">
        <mat-card
          *ngFor="let portfolio of sharedPortfolios"
          class="portfolio-card"
          (click)="navigateToPortfolio(portfolio._id)"
          role="button"
          tabindex="0"
        >
          <button
            *ngIf="!portfolio?.accessLevel"
            class="edit-button"
            [matMenuTriggerFor]="menu"
            mat-icon-button
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleEditPortfolioDialog(portfolio)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'EDIT' | translate }}</span>
            </button>
            <button
              *ngIf="portfolio.role !== 'viewer' && portfolio.role !== 'editor'"
              mat-menu-item
              (click)="toggleCollaborationDialog(portfolio, portfolio.userId)"
            >
              <mat-icon>share</mat-icon>
              <span>{{ 'COLLABORATION.ACTION.COLLABORATE' | translate }}</span>
            </button>
            <button
              *ngIf="portfolio.stats.sitesStats.total === 0"
              (click)="onDelete(portfolio)"
              mat-menu-item
            >
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'DELETE' | translate }}</span>
            </button>
          </mat-menu>
          <div class="card-icon">
            <mat-icon svgIcon="portfolio"></mat-icon>
          </div>
          <mat-card-content>
            <h3 class="card-title">{{ portfolio.name }}</h3>
            <div class="info-row">
              <span class="info-label">{{ 'USER.ROLE.OWNER' | translate }}:</span>
              <span class="info-value">{{ portfolio.user.email }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'ORDERS.ACCESS_LEVEL' | translate }}:</span>
              <span class="info-value">{{
                "USER.ROLE." +
                  ((portfolio?.accessLevel ? portfolio?.accessLevel : "owner")
                    | uppercase) | translate
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.AVERAGE_SCORE' | translate }}:</span>
              <span class="info-value">{{
                (portfolio.stats?.averageScore | number : "1.2-2") || "0"
              }}</span>
            </div>
            <div class="info-row margin-break">
              <span class="info-label">{{ 'PORTFOLIO.SITES_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.total || "0"
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.CRITICAL_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 1
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.WARN_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 0.5
              }}</span>
            </div>
            <div class="info-row">
              <span class="info-label">{{ 'PORTFOLIO.GOOD_COUNT' | translate }}:</span>
              <span class="info-value">{{
                portfolio.stats?.sitesStats.scores | countOf : 0
              }}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </ng-container>
</div>
