import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { PVModule } from '../../model/pv-module.model';
import { getAnnotationType } from '../../utils/helpers';
import { PvInspectionInteractionsService } from '../../services/pv-inspection-interactions.service';
import { OrdersService } from 'src/app/shared/orders/orders.service';

@Component({
  selector: 'app-sidenav-pv-modules-details',
  templateUrl: './sidenav-pv-modules-details.component.html',
  styleUrls: ['./sidenav-pv-modules-details.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({ height: '*', opacity: 1, padding: '6px 0px 16px 0px' })),
      state('collapsed', style({ height: '0px', opacity: 0, padding: '0px 0px 16px 0px' })),
      transition('expanded <=> collapsed', [animate('300ms ease-in-out')]),
    ])
  ]
})
export class SidenavPvModulesDetailsComponent implements OnChanges {
  @Input() public pvModules: PVModule[] = [];
  @Input() public currentLang: string = 'en';

  @Output() public closeSidenav = new EventEmitter<void>();
  @Output() public toggleAnnotationForm = new EventEmitter<void>();

  public showInspection = true;
  public showIdentification = false;
  public showLocation = false;
  public annotationFormActive = false;
  public editingKey: string | null = null;
  public editingValue = '';

  public fieldValues = new Map<string, string>();
  public temperatureStats = { min: 0, max: 0, avg: 0, delta: 0 };

  public identificationFields = [
    { key: 'serialNumber', label: 'Serial Number', path: 'identification' },
    { key: 'fId', label: 'FID', path: 'identification' },
    { key: 'moduleType', label: 'Module Type', path: 'identification' },
    { key: 'modelNumber', label: 'Model Number', path: 'identification' },
    { key: 'manufacturer', label: 'Manufacturer', path: 'identification' },
    { key: 'installationDate', label: 'Installation Date', path: 'identification' },
    { key: 'removalDate', label: 'Replacement Date', path: 'identification' },
    { key: 'warrantyExpiryDate', label: 'Warranty Expiry Date', path: 'identification' }
  ];

  public locationFields = [
    { key: 'panelNumber', label: 'Panel Number', path: 'location' },
    { key: 'arrayId', label: 'Array ID', path: 'location' },
    { key: 'stringNumber', label: 'String', path: 'location' },
    { key: 'tiltAngle', label: 'Tilt Angle', path: 'location' },
    { key: 'azimuthAngle', label: 'Azimuth Angle', path: 'location' },
    { key: 'shadingInfo', label: 'Shading Info', path: 'location' }
  ];

  constructor(
    private pvInspectionInteractionsService: PvInspectionInteractionsService,
    private ordersService: OrdersService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pvModules']?.currentValue) {
      this.computeFieldValues();
      this.computeTemperatureStats();
    }
  }

  public onCloseSidenav(): void {
    this.closeSidenav.emit();
  }

  public onToggleAnnotationForm(): void {
    this.annotationFormActive = !this.annotationFormActive;
    this.toggleAnnotationForm.emit();
  }

  public toggleSection(section: 'inspection' | 'identification' | 'location'): void {
    if (section === 'inspection') this.showInspection = !this.showInspection;
    else if (section === 'identification') this.showIdentification = !this.showIdentification;
    else if (section === 'location') this.showLocation = !this.showLocation;
  }

  public getType(annotationType: string): string {
    return getAnnotationType(annotationType, this.currentLang);
  }

  public getFieldKey(field: { path: string; key: string }): string {
    return `${field.path}.${field.key}`;
  }

  private computeFieldValues(): void {
    this.fieldValues.clear();
    if (!this.pvModules?.length) return;

    const allFields = [...this.identificationFields, ...this.locationFields];

    for (const field of allFields) {
      const values = this.pvModules.map(m => this.getNestedValue(m, field.path, field.key));
      const uniqueValues = Array.from(new Set(values.filter(v => v !== undefined && v !== null)));
      this.fieldValues.set(
        field.key,
        uniqueValues.length > 1 ? 'Mixed' : this.formatField(uniqueValues[0], field.key)
      );
    }
  }

  private computeTemperatureStats(): void {
    if (!this.pvModules?.length) return;
  
    const activeOrderId = this.ordersService.activeOrder$?.value._id;
  
    // Get temperature stats only from inspections that match the active order
    const temperatures = this.pvModules
      .map(m => m.inspections?.find(i => i.orderId === activeOrderId)?.temperatures)
      .filter(Boolean);
  
    const total = temperatures.length;
    if (!total) return;
  
    const minSum = temperatures.reduce((acc, t) => acc + (t.min ?? 0), 0);
    const maxSum = temperatures.reduce((acc, t) => acc + (t.max ?? 0), 0);
    const avgSum = temperatures.reduce((acc, t) => acc + (t.avg ?? 0), 0);
    const deltaSum = temperatures.reduce((acc, t) => acc + (t.delta ?? 0), 0);
  
    this.temperatureStats = {
      min: minSum / total,
      max: maxSum / total,
      avg: avgSum / total,
      delta: deltaSum / total
    };
  }

  private getNestedValue(obj: any, path: string, key: string): any {
    return obj?.[path]?.[key] ?? undefined;
  }

  public startEditing(field: { path: string; key: string }): void {
    this.editingKey = this.getFieldKey(field);
    this.editingValue = this.fieldValues.get(field.key) ?? '';
  }

  public finishEditing(field: { path: string; key: string }, newValue?: string): void {
    const inputValue = newValue ?? this.editingValue;
    const trimmed = inputValue?.trim?.() ?? '';
    let cleanedValue: any = trimmed === '' ? null : trimmed;

    const numberKeys = ['tiltAngle', 'azimuthAngle', 'stringNumber'];
    const dateKeys = ['installationDate', 'removalDate', 'warrantyExpiryDate'];

    if (numberKeys.includes(field.key)) {
      cleanedValue = cleanedValue !== null ? Number(cleanedValue) : null;
    } else if (dateKeys.includes(field.key)) {
      cleanedValue = cleanedValue !== null ? new Date(cleanedValue) : null;
    }

    for (const module of this.pvModules) {
      if (!module[field.path]) module[field.path] = {};
      module[field.path][field.key] = cleanedValue;
    }

    this.editingKey = null;
    this.editingValue = '';
    this.computeFieldValues();

    this.pvInspectionInteractionsService.pvModulesUpdates$.next({
      [field.path]: { [field.key]: cleanedValue }
    });
    
  }

  public formatField(value: any, key: string): string {
    if (value === null || value === undefined || value === '') return 'N/A';

    const dateFields = ['installationDate', 'removalDate', 'warrantyExpiryDate'];
    if (dateFields.includes(key)) {
      return new Date(value).toLocaleDateString('en-GB');
    }

    return value.toString();
  }

  public isInvalid(field: { key: string; path: string }, value: string): boolean {
    const trimmed = value.trim();
    if (trimmed === '') return false;

    const numberKeys = ['tiltAngle', 'azimuthAngle', 'stringNumber'];
    const dateKeys = ['installationDate', 'removalDate', 'warrantyExpiryDate'];

    if (numberKeys.includes(field.key)) {
      return isNaN(Number(trimmed));
    }

    if (dateKeys.includes(field.key)) {
      return isNaN(Date.parse(trimmed));
    }

    return false;
  }
}
