import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Portfolio } from "../portfolio/portfolio.model";
import { PortfoliosService } from "src/app/pages/portfolios/portfolios.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { UsersService } from "src/app/pages/users/users.service";

@Component({
  selector: "app-add-modify-portfolio-dialog",
  templateUrl: "./add-modify-portfolio-dialog.component.html",
  styleUrls: ["./add-modify-portfolio-dialog.component.scss"],
})
export class AddModifyPortfolioDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public usersAutocompleteOptions: Array<any> = [];
  private ngDestroy$ = new Subject();
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private readonly modal: MatDialogRef<AddModifyPortfolioDialogComponent>,
    private readonly fb: FormBuilder,
    private portfoliosService: PortfoliosService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: { portfolio?: Portfolio; isAdmin: boolean }
  ) {}

  public ngOnInit(): void {
    this.buildForm();
    if (this.data.isAdmin && !this.data.portfolio) {
      this.setupUserAutocomplete();
    }
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public addUserFromAutoComplete(selectedUser: any): void {
    if (selectedUser) {
      this.form.get('userEmail').setValue(selectedUser.email);
      if (!this.form.contains('userId')) {
        this.form.addControl('userId', this.fb.control(''));
      }
      this.form.get('userId').setValue(selectedUser._id);
    }
  }

  public handleKeydown(event: KeyboardEvent): void {
    if (this.data.isAdmin && !this.data.portfolio) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }
  }

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    const formValue = this.form.value;
  
    if (this.data.portfolio) {
      const dataChanged = this.data.portfolio.name !== formValue.name   
      if (!dataChanged) {
        this.modal.close();
        return;
      }
      this.data.portfolio.name = formValue.name;
      this.portfoliosService
        .updateOne(this.data.portfolio)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((response) => {
          this.modal.close(response.data);
        });
    } else {
      const portfolio = { name: formValue.name, userId: formValue.userId };
      this.portfoliosService
        .insertOne(portfolio)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((response) => {
          this.modal.close(response.data);
        });
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: [this.data.portfolio?.name || "", Validators.required],
    });

    if (this.data.isAdmin && !this.data.portfolio) {
      this.form.addControl("userEmail", this.fb.control("", Validators.required));
    }
  }

  private setupUserAutocomplete(): void {
    this.form.get("userEmail")?.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => {
          const pageSize = 10;
          const cursor = 0;
          const filter = { query: value };
          const fields = { email: 1, name: 1 };
          return this.usersService.findMany(filter, null, cursor, pageSize, fields);
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((users) => {
        this.usersAutocompleteOptions = users.data;
        //this.usersAutocompleteOptions = this.mockData;
      });
  }
}
