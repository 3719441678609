import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { THERMAL_PALETTES } from '../../model/pv-module.model';

@Component({
  selector: 'app-sidenav-thermal-details',
  templateUrl: './sidenav-thermal-details.component.html',
  styleUrls: ['./sidenav-thermal-details.component.scss']
})
export class SidenavThermalDetailsComponent implements OnInit {

  @Input() thermalMetaData: any = undefined;
  @Input() selectedThermalPalette: any = undefined;


  @Output() closeSidenavEvent = new EventEmitter<void>();
  @Output() updateLayerEvent = new EventEmitter<{ minTemp: number, maxTemp: number }>();
  @Output() paletteChangeEvent = new EventEmitter<{ newPalette: string[], minTemp: number, maxTemp: number }>();
  
  constructor() { }

  ngOnInit(): void {
    console.log('SidenavThermalDetailsComponent');
  }
  
  closeSidenav() {
    this.closeSidenavEvent.emit();
  }

  onHistogramRangeChange(event: { minTemp: number, maxTemp: number }) {
    this.updateLayerEvent.emit(event);
  }

  onPaletteChange(event: { newPalette: string[], minTemp: number, maxTemp: number }) {
    this.selectedThermalPalette = event.newPalette;
    this.paletteChangeEvent.emit(event);
  }


}
