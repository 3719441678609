import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { PvInspectionService } from '../../services/pv-inspection.service';
import { OrdersService } from 'src/app/shared/orders/orders.service';

@Component({
  selector: 'app-sidenav-map-layers-details',
  templateUrl: './sidenav-map-layers-details.component.html',
  styleUrls: ['./sidenav-map-layers-details.component.scss']
})
export class SidenavMapLayersDetailsComponent implements OnInit {

  @Input() layers: any[] = [];
  @Input() map: Map;
  @Input() pvInspectionService: PvInspectionService;
  @Input() siteId: any;

  @Output() closeSidenavEvent = new EventEmitter<void>();
  @Output() toggleUploadLayerDialogEvent = new EventEmitter<void>();
  
  constructor() { }

  public ngOnInit(): void {
    console.log('SidenavMapLayersDetailsComponent');
  }

  public closeSidenav() {
    this.closeSidenavEvent.emit();
  }

  public toggleUploadLayerDialog() {
    this.toggleUploadLayerDialogEvent.emit();
  }

  public drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    this.layers.forEach((layerObject, index) => layerObject.olLayer.setZIndex(this.layers.length - index));
  }

  public toggleLayerVisibility(layer: any) {
    if (layer.isVisible) {
      this.map.removeLayer(layer.olLayer);
    } else {
      this.map.addLayer(layer.olLayer);
    }
    layer.isVisible = !layer.isVisible
  }

  public convertGeojsonToSavableModules(vectorLayer: VectorLayer<VectorSource>):void {
    this.pvInspectionService.saveGeojsonAsIndependentModules(vectorLayer.getSource(), this.siteId)
  }
}
