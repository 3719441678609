import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Portfolio, PortfolioStats, ResponseData } from 'src/app/shared/portfolio/portfolio.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService {

  constructor(
    private readonly http: HttpClient 
  ) { }

  public insertOne(portfolio: Portfolio): Observable<ResponseData<Portfolio>> {
    return this.http.post<ResponseData<Portfolio>>(`${environment.apiPath}portfolios`, portfolio)
  }

  public getPortfolios(
    filter: object,
    cursor: number,
    pageSize: number,
  ): Observable<any> {
      let params = new HttpParams();
      console.log(filter)
      if (filter && Object.keys(filter).length) {
        params = params.append('filter', JSON.stringify(filter));
      }
      if (cursor) {
        params = params.append('cursor', cursor.toString());
      }
      if (pageSize) {
        params = params.append('pageSize', pageSize.toString());
      }
    return this.http.get<any>(`${environment.apiPath}portfolios`,{ params })
  }

  public getPortfolioStats(id: string): Observable<ResponseData<PortfolioStats>> {
    return this.http.get<ResponseData<PortfolioStats>>(`${environment.apiPath}portfolios/${id}/statistics`)
  }

  public getPortfolioById(id: string): Observable<ResponseData<Portfolio>> {
    return this.http.get<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${id}`)
  }

  public deleteOne(PortfolioId: string): Observable<any> {
    return this.http.delete<Portfolio>(`${environment.apiPath}portfolios/${PortfolioId}`);
  }

  public updateOne(portfolio: Portfolio): Observable<ResponseData<Portfolio>>{
    return this.http.patch<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${portfolio._id}`,portfolio);
  }

  //------------------ Business Logic ------------------//
  public findOwnedPortfolios(): Observable<Portfolio[]> {
    return this.getPortfolios(undefined,0,5000).pipe(
      map(response => {
        if (response.data?.portfolios && response.data?.stats) {
          const portfolios = response.data.portfolios.map((portfolio, index) => ({
            ...portfolio,
            stats: response.data.stats[index]
          }));
  
          return portfolios.filter(p => !p.accessLevel);
        }
        return [];
      }),
      catchError(error => {
        console.error('Error fetching portfolios:', error);
        return of([]);
      })
    );
  }

  public findOwnedPortfoliosByUserId(clientId: string): Observable<Portfolio[]> {
    const filter = {'ownerID': clientId}
    return this.getPortfolios(filter,0,5000).pipe(
      map(response => {
        if (response.data?.portfolios && response.data?.stats) {
          const portfolios = response.data.portfolios.map((portfolio, index) => ({
            ...portfolio,
            stats: response.data.stats[index]
          }));
  
          return portfolios;
        }
        return [];
      }),
      catchError(error => {
        console.error('Error fetching portfolios:', error);
        return of([]);
      })
    );
  }

  public findInvitedPortfolios(): Observable<Portfolio[]> {
    return this.getPortfolios(undefined, undefined, 5000).pipe(
      map(response => {
        if (response.data?.portfolios && response.data?.stats) {
          const portfolios = response.data.portfolios.map((portfolio, index) => ({
            ...portfolio,
            stats: response.data.stats[index]
          }));
  
          return portfolios.filter(p => p.accessLevel);
        }
        return [];
      }),
      catchError(error => {
        console.error('Error fetching portfolios:', error);
        return of([]);
      })
    );
  }
  
}
