import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-layer-dialog',
  templateUrl: './upload-layer-dialog.component.html',
  styleUrls: ['./upload-layer-dialog.component.scss']
})
export class UploadLayerDialogComponent {
  selectedType: 'design' | 'thermal' | 'geojson' | null = null;
  selectedFile: File | null = null;
  isDragging = false;

  constructor(
    private dialogRef: MatDialogRef<UploadLayerDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  selectFileType(type: 'design' | 'thermal' | 'geojson'): void {
    this.selectedType = type;
    this.selectedFile = null;
  }

  getAcceptTypes(): string {
    if (this.selectedType === 'geojson') {
      return '.geojson,application/geo+json,application/json';
    }
    return '.tiff,.tif,image/tiff';
  }

  isAcceptedFile(file: File): boolean {
    if (this.selectedType === 'geojson') {
      return file.name.endsWith('.geojson') || file.type === 'application/geo+json' || file.type === 'application/json';
    }
    return file.name.endsWith('.tiff') || file.name.endsWith('.tif') || file.type === 'image/tiff';
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      if (this.isAcceptedFile(file)) {
        this.selectedFile = file;
        this.cdr.markForCheck();
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    const file = event.dataTransfer?.files?.[0];
    if (file && this.isAcceptedFile(file)) {
      this.selectedFile = file;
      this.cdr.detectChanges();
    }
  }

  removeFile(event: Event): void {
    event.stopPropagation();
    this.selectedFile = null;
    this.cdr.detectChanges();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpload(): void {
    if (this.selectedFile && this.selectedType) {
      this.dialogRef.close({ file: this.selectedFile, type: this.selectedType });
    }
  }
}
