import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { THERMAL_PALETTES } from 'src/app/pages/files/pv-inspection/model/pv-module.model';


@Component({
  selector: 'app-histogram-range-slider',
  templateUrl: './histogram-range-slider.component.html',
  styleUrls: ['./histogram-range-slider.component.scss']
})
export class HistogramRangeSliderComponent implements OnInit {

  @Input() histogramData: { floor: number, ceil: number, count: number }[] = [];
  @Input() selectedColorGradient: string[] = THERMAL_PALETTES[0].value;

  @Output() paletteChangeEvent = new EventEmitter<{ palette: string[], minTemp: number, maxTemp: number }>();
  @Output() rangeChange = new EventEmitter<{ minTemp: number, maxTemp: number }>();
  
  thermalPalettesList = THERMAL_PALETTES; 

  minValue: number = 0;
  maxValue: number = 0;
  containerHeight: number = 170; // Default height of the histogram container

  // ngx-slider options
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    showTicks: false,
    showTicksValues: false, // Show tick values
    tickStep: 10,
    tickValueStep: 10,
    translate: (value: number): string => {
      return `${value}°C`; // Custom label format
    },
    getLegend: (value: number): string => {
      return `${value}°C`; // Custom legend format
    },
    ticksTooltip: (value: number): string => {
      return `${value}°C`; // Custom tooltip for ticks
    },
    ticksValuesTooltip: (value: number): string => {
      return `${value}°C`; // Custom tooltip for tick values
    },
    vertical: false, // Ensure the slider is horizontal
    rightToLeft: false, // Ensure the slider is left-to-right
    showSelectionBar: true, // Show the selection bar between the thumbs
    selectionBarGradient: {
      from: '#FF0000', // Gradient start color
      to: '#00FF00' // Gradient end color
    }
  };

  ngOnInit(): void {
    this.calculateInitialRange();
    this.initializeSlider();
    this.initializeSelectionBarGradient();
  }

  initializeSelectionBarGradient(): void {
    if (this.selectedColorGradient.length >= 2) {
      this.options.selectionBarGradient = {
        from: this.selectedColorGradient[0], // First color in the gradient
        to: this.selectedColorGradient[this.selectedColorGradient.length - 1] // Last color in the gradient
      };
    } else {
      // Fallback to default colors if the gradient array has fewer than 2 colors
      this.options.selectionBarGradient = {
        from: '#FF0000', // Red
        to: '#00FF00' // Green
      };
    }
  }

  calculateInitialRange(): void {
    if (this.histogramData.length > 0) {
      this.minValue = Math.min(...this.histogramData.map(bin => bin.floor));
      this.maxValue = Math.max(...this.histogramData.map(bin => bin.ceil));
    }
  }

  initializeSlider(): void {
    this.options.floor = this.minValue;
    this.options.ceil = this.maxValue;
    this.minValue = this.minValue;
    this.maxValue = this.maxValue;
  }

  updateHistogram(): void {
    console.log('updateHistogram');
    this.rangeChange.emit({ minTemp: this.minValue, maxTemp: this.maxValue });
  }

  getColor(index: number): string {
    const bin = this.histogramData[index];
  
    if (bin.ceil < this.minValue || bin.floor > this.maxValue) {
      return '#ccc';
    }
  
    // Apply gradient for in-range bins
    const totalBins = this.histogramData.length;
    const gradientIndex = (index / (totalBins - 1)) * (this.selectedColorGradient.length - 1);
    const color1 = this.selectedColorGradient[Math.floor(gradientIndex)];
    const color2 = this.selectedColorGradient[Math.ceil(gradientIndex)];
    const blendFactor = gradientIndex % 1;
  
    return this.interpolateColor(color1, color2, blendFactor);
  }

  interpolateColor(color1: string, color2: string, factor: number): string {
    const hexToRgb = (color: string) => {
      const hex = color.replace(/^#/, '');
      const bigint = parseInt(hex, 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    const result = rgb1.map((channel, i) =>
      Math.round(channel + (rgb2[i] - channel) * factor)
    );

    return `rgb(${result.join(',')})`;
  }

  getBarHeight(count: number): number {
    const maxCount = Math.max(...this.histogramData.map(bin => bin.count));
    return (count / maxCount) * this.containerHeight; // Scale height based on container height
  }

  onPaletteChange(newPalette: string[]): void {
    this.selectedColorGradient = newPalette;
    this.updateSelectionBarGradient();
    this.paletteChangeEvent.emit({
      palette: newPalette, 
      minTemp: this.minValue, 
      maxTemp: this.maxValue 
    });
  }

  updateSelectionBarGradient(): void {
    this.options.selectionBarGradient = {
      from: this.selectedColorGradient[0], 
      to: this.selectedColorGradient[this.selectedColorGradient.length - 1]
    };
  }
}
