<mat-form-field appearance="outline" class="palette-selector">
  <mat-label>Select Thermal Palette</mat-label>
  <mat-select [(value)]="selectedColorGradient" (selectionChange)="onPaletteChange($event.value)">
    <mat-option *ngFor="let palette of thermalPalettesList" [value]="palette.value">
      <div>

      </div>
      {{ palette.label }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="histogram-container">
  <!-- Histogram Bars -->
  <div class="histogram" [ngStyle]="{ height: containerHeight + 'px' }">
    <div
      *ngFor="let bin of histogramData; let i = index"
      class="bar"
      [ngStyle]="{
        'height.px': getBarHeight(bin.count),
        'background-color': getColor(i)
      }"
    ></div>
  </div>

  <!-- Range Slider -->
  <div class="slider-container">
    <ngx-slider
      [(value)]="minValue"
      [(highValue)]="maxValue"
      [options]="options"
      (userChangeEnd)="updateHistogram()">
    </ngx-slider>
  </div>
</div>
