import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '../../../environments/environment';
import { LazyLoadService } from '../../shared/helpers/lazy-load.service';
import { Chart, ChartConfiguration, ChartData, Legend } from 'chart.js';
import { MatTableDataSource } from '@angular/material/table';
import { SiteAdderModalComponent } from '../../shared/site-adder-modal/site-adder-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PortfolioService } from '../../shared/portfolio/portfolio.service';
import { LoginStateService } from '../login/login-state.service';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { AccountType, User} from '../users/models/user.model';
import { HeaderService } from '../../shared/header/header.service';
import { TranslateService } from '@ngx-translate/core';
import { Portfolio, PortfolioStats, ResponseData, Site, SITE_COLOR_BY_SEVERITY, SITE_TYPE_LABELS } from '../../shared/portfolio/portfolio.model';
import MarkerClusterer from "../maps/markerclustererplus";
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AddModifyPortfolioDialogComponent } from 'src/app/shared/add-modify-portfolio-dialog/add-modify-portfolio-dialog.component';
import { Collaboration } from '../files/file/sidebar/collaborations/collaboration.model';
import { CollaborationsService } from '../files/file/sidebar/collaborations/collaborations.service';
import { Direction } from '@angular/cdk/bidi';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

Chart.register(Legend)
interface Coord {
  lat: number;
  lng: number;
}

const markerPath = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, AfterViewInit, OnDestroy {
  private loggedUser!: User;
  private currentPortfolioId: string = undefined
  public sites: Site[] = [];
  public collaborations: Collaboration[] = []
  private ngDestroy$ = new Subject();
  public markerClustererOrders?: MarkerClusterer;
  public portfolio!: Portfolio;
  public stats!: PortfolioStats;
  public dataSource = new MatTableDataSource(this.sites);
  public lng: number = 0;
  public lat: number = 0;
  public labels = SITE_TYPE_LABELS;
  public displayedColumns: string[] = [
    'name',
    'address',
    'score'
  ];
  public collaborationsDisplayedColumns: string[] = ['user', 'role', 'actions'];
  public collaboratoionsDataSource = new MatTableDataSource<Collaboration>(this.collaborations);

  @Input() dir: Direction;
  @ViewChild(MatSort) sort: MatSort;
  
  public viewId= '';
  public theme: string = this.themeService.changed$.value;
  public googleMapStyles: google.maps.MapTypeStyle[] =
    [
      {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'poi',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'transit',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      }
  ];
  private chartLabels: string[] = [
    'PORTFOLIO.MINOR',
    'PORTFOLIO.IMPORTANT',
    'PORTFOLIO.CRITICAL'
  ];
  public googleMapsOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    mapTypeControl: false,
    minZoom: 2,
    styles: this.googleMapStyles,
    scaleControl: false,
  };
  public googleMapIsLoaded = false;
  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @ViewChild('pie') pie: ElementRef<HTMLCanvasElement>;
  public googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;
  public isListView = true;
  private chart;

  public dataPie: ChartData<'doughnut', number[], string> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: SITE_COLOR_BY_SEVERITY,
      hoverOffset: 4,
      borderWidth: 0,
    }],
  };

  public configPie: ChartConfiguration<'doughnut', number[], string> = {
    type: 'doughnut',
    data: this.dataPie,
    options: {
      cutout: '70%',
      responsive: true,
      plugins: {
        legend: {
          display: true,
          title: {
            display: true,
            padding: 10,
          },
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rectRounded',
            color: this.theme.includes('dark')?"#e0e0e0":"rgb(110, 110, 110)",
          }
        },
        datalabels: {
          display: false
        }
      }
    },
  };

  constructor(
    private readonly lazyLoadService: LazyLoadService,
    private readonly headerService: HeaderService,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly portfolioService: PortfolioService,
    private readonly collaborationsService: CollaborationsService,
    public readonly permissionsService: PermissionsService,
    private readonly loginStateService: LoginStateService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private loginService: LoginStateService,
    private themeService: ThemeService,
    private router: Router
  ) {
      this.themeService.changed$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(theme => {
        this.theme = theme;
        this.configPie.options.plugins.legend.labels.color = theme.includes('dark')?"#e0e0e0":"gray";
        this.updateChart()
      });

      this.loginStateService.loggedUser$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((loggedUser) => {
        this.loggedUser = loggedUser;
      });

      this.router.events
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(data => {
        if (data instanceof NavigationEnd) {
          this.currentPortfolioId = this.route.snapshot.parent.children[0].params.portfolioID;

          // We use forkJoin because drawSites needs both responses
          forkJoin({
            portfolio: this.portfolioService.getPortfolioById(this.currentPortfolioId),
            sites: this.portfolioService.getPortfolioSites(this.currentPortfolioId),
            stats: this.portfolioService.getPortfolioStats(this.currentPortfolioId)
          })
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(({portfolio, sites, stats }) => {

            if (portfolio.data) {
              this.portfolio = portfolio.data
              this.headerService.breadCrumbs$.next([{label: this.portfolio?.name || 'PORTFOLIO.TITLE', link: ['portfolios', this.portfolio?._id]}])
            }

            if (sites?.data) {
              this.sites = sites.data;
              this.dataSource.data = this.sites;
              setTimeout(() => {
                this.drawSites();
              }, 500);
            }
          
            if (stats?.data) {
              this.stats = stats.data;
              this.stats.averageScore = this.correctAvg(this.stats);
              setTimeout(() => {
                this.updateChart();                
              }, 500);
            }
          });

          // get collaborators
          const filter = this.collaborationsService.constructFilter('portfolio',this.currentPortfolioId)
          this.collaborationsService.getCollaborations(filter).pipe(takeUntil(this.ngDestroy$))
          .subscribe( (collaborationsResponse) => {
            if (collaborationsResponse.data) {
              this.collaborations = this.collaborationsService.enrichCollaborationsWithUsersData(collaborationsResponse.data);
              this.collaboratoionsDataSource.data = this.collaborations
            }
          })
        }
      })
  }

  public ngOnInit(): void {
    this.translateService.onLangChange.subscribe(()=> this.updateChart())
    this.lazyLoadService.loadScript(this.googleMapsURL).subscribe(_ => {
      this.googleMapIsLoaded = true;
      this.viewId = google.maps.MapTypeId.ROADMAP
      setTimeout(()=> this.drawSites());
    });
  }

  public drawSites(): void {
    if (!this.googleMapIsLoaded || !this.googleMap) return;

    if (this.markerClustererOrders) {
      this.markerClustererOrders.clearMarkers();
    }

    const markers: google.maps.Marker[] = [];
    const coords: Coord[] = [];
    for (const site of this.sites) {
      const geocoder = new google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({'address': site.address}, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            let coord: Coord = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
            coords.push(coord);
            let color = this.getColorOfSite(site._id);

            const svgMarker = {
              path: markerPath,
              fillColor: color,
              fillOpacity: 1,
              strokeColor: '#000',
              strokeWeight: 1,
              scale: 1,
            };
            const mapMarker = new google.maps.Marker({
              position: coord,
              title: site.name,
              icon: svgMarker,
            });

            mapMarker.addListener('click', () => {
              this.openSite(site);
            });

            markers.push(mapMarker);

          } else {
            markers.push(null);
          }

          if (markers.length === this.sites.length) {
            const bounds = new google.maps.LatLngBounds();
            for (const coord of coords) {
              bounds.extend(coord);
            }
            const coordFunc = bounds.getCenter();

            this.lat = coordFunc.lat();
            this.lng = coordFunc.lng();

            // Fit the map to the bounds containing all markers
            this.googleMap.fitBounds(bounds);

            const notNullMarkers = markers.filter(marker => !!marker);

            // Initialize MarkerClusterer
            this.markerClustererOrders = new MarkerClusterer(this.googleMap.googleMap, notNullMarkers, {
              imagePath: '/assets/images/icons/marker_cluster6',
              imageExtension: 'svg',
              gridSize: 5, // Increase or decrease this value to control how close markers need to be to cluster
              maxZoom: 15,  // Set the maximum zoom level where clustering occurs
              minimumClusterSize: 5, // Set the minimum number of markers required to form a cluster
              zoomOnClick: true // Allow zooming in when clicking on a cluster
            });

            // Custom image mapper function to determine cluster color based on the most critical site
            this.markerClustererOrders.imageMapper = (clusterMarkers) => {
              let mostCriticalColor = SITE_COLOR_BY_SEVERITY[0]; // Default to the least critical color

              for (const marker of clusterMarkers) {
                const color = ((marker.getIcon() as unknown) as any).fillColor;
                if (color === SITE_COLOR_BY_SEVERITY[2]) { // Assuming '#D00000' is the color for the most critical sites
                  mostCriticalColor = SITE_COLOR_BY_SEVERITY[2];
                  break; // No need to continue if we found the most critical color
                } else if (color === SITE_COLOR_BY_SEVERITY[1]) { // Assuming '#F3C41C' is the color for important sites
                  mostCriticalColor = SITE_COLOR_BY_SEVERITY[1];
                }
              }

              // Return the appropriate cluster icon based on the most critical color found
              if (mostCriticalColor === SITE_COLOR_BY_SEVERITY[2]) {
                return '/assets/images/icons/marker_cluster3';
              } else if (mostCriticalColor === SITE_COLOR_BY_SEVERITY[1]) {
                return '/assets/images/icons/marker_cluster2';
              } else {
                return '/assets/images/icons/marker_cluster6';
              }
            };
          }
        });
      }
    }
  }
    
  public openSite(site){
    this.router.navigate(['./portfolios/', this.portfolio._id, 'sites',site._id]);
  }

  public onOptionClick(action: string, element: any): void {
    console.log(`Action: ${action}, Element:`, element);
  }

  public onDelete(collaborationId: string): void {
    const dialogRef = this.dialogService.showDialog(
      'Delete Confirmation',
      null,
      'Are you sure you want to delete this collaboration?',
      null,
      true,
      false) as Observable<boolean>;

    dialogRef.subscribe((confirm) => {
      if (confirm) {
            this.collaborationsService
              .deleteOne(collaborationId.toString())
              .pipe(takeUntil(this.ngDestroy$))
              .subscribe(
                () => {
                  const removeIndex = this.collaborations.findIndex((collaboration) => {
                    return collaboration._id.toString() == collaborationId;
                  });                 
                  if(removeIndex > -1) {
                    this.collaborations.splice(removeIndex, 1)
                  }
                },
                (error) => {
                  this.dialogService.showDialog(
                    "FILE.DELETE",
                    error.status,
                    error.url,
                    error.error
                  );
                }
              );
        }
      });

  }

  public openSiteDialog(): void {
    const modal = this.dialog.open<SiteAdderModalComponent, { portfolio: any }>(SiteAdderModalComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '350px',
      closeOnNavigation: true,
      data: {
        portfolio: this.portfolio
      }
    });
  
    modal.afterClosed()
      .pipe(
        filter(value => !!value),
        take(1),
        switchMap(value => this.addSite(value))
      )
      .subscribe((value) => {
        if (!value) return;
        this.sites = [
          ...this.sites,
          value
        ];
        this.dataSource.data = this.sites;
        this.drawSites();
      });
  }

  public correctAvg(stats){
    return ((stats.sitesStats.minor*1)+(stats.sitesStats.important*2)+(stats.sitesStats.critical*3))/stats.sitesStats.total
  }

  public addSite(site: Site): Observable<Site> {
    if (this.loggedUser.accountType === AccountType.CLIENT){
      site['userId']= this.portfolio.userId
    }
    let created: Site;
    return this.portfolioService.createPortfolioSites(undefined, site.name, site.address, this.portfolio.userId, this.portfolio._id, 'building')
      .pipe(switchMap(value => {
        created = value.data
        var linkedSites = []
        if (this.sites){
          this.sites.forEach((site)=>{
            linkedSites.push(site._id)
          })
        }
        linkedSites.push(value.data._id)
        this.stats.sitesStats.total+=1
        this.stats.sitesStats.minor+=1
        this.stats.averageScore = this.correctAvg(this.stats)
        this.updateChart()
        return this.portfolioService.linkSiteToPortfolio(this.portfolio._id.toString(), linkedSites)
      }), map(() => created))
  }

  public ngAfterViewInit(): void {
    this.updateChart();
    this.collaboratoionsDataSource.sort = this.sort;

  }
  
  public toggleListView(value: boolean): void {
    this.isListView = value;
  }

  private getPortfolio(): Observable<Portfolio> {

    // logged
    return this.loginService.loggedUser$.pipe(
      switchMap(user => {
        if (!user) return EMPTY;
        return this.portfolioService.getPortfolioById(this.currentPortfolioId);
      }),
      // This switch become unnecessary
      switchMap((response: any) => {
        if (!response?.data) {
          return this.createPortfolio('Portfolio').pipe(
            map(response => response.data)
          );
        }
        return of(response.data);
      }),

      switchMap(portfolio => {
        if (!portfolio) return EMPTY;
        this.portfolio = portfolio;
        const id = portfolio._id.toString();
        const filter = this.collaborationsService.constructFilter('portfolio',id)
        return combineLatest([
          this.portfolioService.getPortfolioStats(id),
          this.portfolioService.getPortfolioSites(id),
          this.collaborationsService.getCollaborations(filter)
        ]);
      }),
      map(([statsResponse, sitesResponse, collaborationsResponse]) => {
        // Process stats
        this.stats = statsResponse?.data;
        if (this.stats) {
          this.stats.averageScore = this.correctAvg(this.stats);
        }
  
        // Process sites
        this.sites = sitesResponse.data;
        if (collaborationsResponse.data) {
          this.collaborations = this.collaborationsService.enrichCollaborationsWithUsersData(collaborationsResponse.data);
        }
        this.drawSites();
        this.dataSource.data = this.sites;
        this.collaboratoionsDataSource.data = this.collaborations
  
        // Update chart
        this.updateChart();
  
        return this.portfolio;
      })
    );
  }

  public getColorOfSite(siteId: string): string {
    const score = this.stats.sitesStats.scores[siteId];

    return this.portfolioService.getColorOfScore(score)
  }

  public getLabelOfSite(siteId: string): string {
    const score = this.stats.sitesStats.scores[siteId];

    return this.portfolioService.getLabelsOfScore(score)
  }

  public switchView(): void {
    if (this.viewId === google.maps.MapTypeId.ROADMAP) {
      this.viewId = google.maps.MapTypeId.SATELLITE
    } else {
      this.viewId = google.maps.MapTypeId.ROADMAP
    }
  }

  public indicator(siteId: string): number {
    const score = this.stats.sitesStats.scores[siteId];
    return this.portfolioService.getScoreIndicator(score);
  }

  private updateChart(): void {
    if (!this.pie) return;
    this.configPie.data.labels = this.chartLabels.map(value => this.translateService.instant(value))
    this.configPie.data.datasets[0].data = [
      this.stats.sitesStats.minor,
      this.stats.sitesStats.important,
      this.stats.sitesStats.critical
    ];
    if (!this.chart) {
      this.chart = new Chart(this.pie.nativeElement, this.configPie);
    } else {
      this.chart.update()
    }
  }

  private createPortfolio(name: string): Observable<ResponseData<Portfolio>> {
    return this.portfolioService.createPortfolio(this.loggedUser._id.toString(), name)
  }

  public openPortfolioDialog(): void {
    const modal = this.dialog.open<AddModifyPortfolioDialogComponent, Portfolio>(AddModifyPortfolioDialogComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '350px',
      closeOnNavigation: true
    });

    modal.afterClosed()
      .pipe(
        take(1),
        filter(value => !!value),
        switchMap(value =>  this.createPortfolio(value.name)),
        switchMap(() => this.getPortfolio())
      ).subscribe(() => {
        this.headerService.breadCrumbs$.next([{label: this.portfolio?.name || 'PORTFOLIO.TITLE', link: ['portfolios', this.portfolio?._id]}])
      })
  }

  public ngOnDestroy(): void {
    this.headerService.breadCrumbs$.next([]);
  }

}
