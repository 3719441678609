<div class="feature-details">
  <div class="sidenav-header">
    <div class="left">
      <span class="title">{{ 'SOLAR_SITE.LAYERS_MANAGEMENT.LAYERS_MANAGEMENT'| translate }}</span>
    </div>
    <div class="right">
      <mat-icon class="material-icons-outlined" (click)="closeSidenav()">close</mat-icon>
    </div>
  </div>

  <div cdkDropList (cdkDropListDropped)="drop($event)" class="layers-list">
    <div class="example-box" *ngFor="let layerObject of layers" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <ng-container>
        <span>
          {{ layerObject.label }}
        </span>
        <div class="layers-actions">
          <mat-icon *ngIf="layerObject.olLayer.getClassName() === 'geojson'" class="layers-action-icon"
                    (click)="convertGeojsonToSavableModules(layerObject.olLayer)">
            upload
          </mat-icon>
          <mat-icon class="layers-action-icon" (click)="toggleLayerVisibility(layerObject)">
            {{ layerObject?.isVisible ? 'visibility' : 'visibility_off' }}
          </mat-icon>
          <mat-icon class="layers-action-icon">delete</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="sidenav-footer">
    <button
      color="accent"
      mat-raised-button
      title="Generate PDF"
      type="button"
      (click)="toggleUploadLayerDialog()">
      <span>{{ 'SOLAR_SITE.LAYERS_MANAGEMENT.ADD_LAYER'| translate }}</span>
    </button>
  </div>
</div>

