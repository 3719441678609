import { User } from "../../users/models/user.model";

export type ResourceType = "order" | "site" | "portfolio";
export type Role = "viewer" | "editor" | "coordinator";

export interface Resource {
  id: string;
  label: string;
  type: ResourceType;
}

export interface Team {
  _id?: string;
  name?: string;
  managerID?: string;
  manager?: User;
  members?: User[];
  resources?: Resource[];
  shareAllResources?: boolean;
  createdAt?: Date;
}

export interface TeamMemberRequest {
  id: string;
  role: Role;
}

export interface ResourceRequest {
  id: string;
  type: ResourceType;
}

export interface TeamRequest {
  name: string;
  members: TeamMemberRequest[];
  resources: ResourceRequest[];
  shareAllResources: boolean;
}

export interface TeamsFilter {
  managerID?: string;
  shareAllResources?: boolean;
  memberID?: string;
  role?: Role;
  resource?: Resource;
}

export function createEmptyTeam(): Team {
  return {
    _id: undefined,
    name: '',
    manager: {
      _id: undefined,
      email:undefined,
      name:undefined,
      surname:undefined
    },
    members: [],
    resources: [],
    shareAllResources: false,
    createdAt: undefined
  };
}
