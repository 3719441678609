<header>
  <mat-toolbar
    class="user-toolbar"
    role="toolbar">

    <button
      class="close-button"
      (click)="close()"
      mat-icon-button
      title="{{ 'CLOSE' | translate }}"
      type="button">
      <mat-icon class="rtl-mirror">close</mat-icon>
    </button>

    <h2 *ngIf="legacyOrder" class="header-title" [title]="legacyOrder.address">
      {{ legacyOrder.address }}
    </h2>

    <span class="fill-remaining"></span>
  </mat-toolbar>
</header>

<section class="scrollable-section">
  <div class="buttons-section" >
    <button
    *ngIf="order?.siteId && permissionsService.permissions$.value?.canRead.sites"
    mat-raised-button
    color="accent"
    class="primary-button"
    (click)="navigateToSite()">
      {{ 'COLLABORATION.SIDEBAR.GO_TO_SITE' | translate }}
    </button>

    <button
    *ngIf="!order?.siteId && isInspection && permissionsService.permissions$.value?.canCreate.sites"
    mat-raised-button
    color="accent"
    class="primary-button"
    (click)="createSite()">
      {{ 'PORTFOLIO.ADD_SITE' | translate }}
    </button>
  </div>
  <div #googleMap class="map"></div>

  <mat-tab-group *ngIf="clientOrder" mat-stretch-tabs>

    <mat-tab *ngIf="permissionsService.permissions$.value?.canCollaborateOn.orders && permissionsService.permissions$.value?.canRead.collaborations">
      <ng-template mat-tab-label>
        <mat-icon>person_add</mat-icon>
        <span class="tab-label">{{ 'COLLABORATION.USER.PLURAL' | translate | uppercase }}</span>
      </ng-template>
      <app-collaborations [fileModel]="undefined" [collaborations]="collaborations" ></app-collaborations>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>info</mat-icon>
        <span class="tab-label">{{ 'ORDER.INFO' | translate | uppercase }}</span>
      </ng-template>

      <div class="tab-content tab-values">
        <div class="ro-field" title="ID">
          <strong>FF{{ clientOrder.id }}</strong>
        </div>

        <div class="ro-field" *ngIf="clientOrder.address">
          <p class="label">
            <mat-icon matSuffix>location_on</mat-icon>
            <span>{{ 'ADDRESS' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.address }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.projectName">
          <p class="label">
            <mat-icon matSuffix>description</mat-icon>
            <span>{{ 'ORDER.PROJECT_NAME' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.projectName }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.typeOfService">
          <p class="label">
            <mat-icon matSuffix>shopping_bag</mat-icon>
            <span>{{ 'ORDER.SERVICE_TYPE' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.typeOfService }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.services">
          <p class="label">
            <mat-icon matSuffix>shopping_bag</mat-icon>
            <span>{{ 'ORDER.SERVICES' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.services }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.netFixedQuotePrice">
          <p class="label">
            <mat-icon matSuffix>label</mat-icon>
            <span>{{ 'ORDER.PRICE_NET' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.netFixedQuotePrice | number : '1.2-2' }} {{ clientOrder.priceCurrency }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.status">
          <p class="label">
            <mat-icon matSuffix>traffic</mat-icon>
            <span>{{ 'ORDER.STATUS' | translate }}</span>
          </p>
          <p class="value">{{ 'ORDER.STATES.' + clientOrder.status.toUpperCase() | translate }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.comment">
          <p class="label">
            <mat-icon matSuffix>feedback</mat-icon>
            <span>{{ 'ORDER.COMMENT' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.comment }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.contactPerson">
          <p class="label">
            <mat-icon matSuffix>person_outline</mat-icon>
            <span>{{ 'ORDER.CONTACT' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.contactPerson }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.contactPersonEmail">
          <p class="label">
            <mat-icon matSuffix>email</mat-icon>
            <span>{{ 'ORDER.CONTACT_EMAIL' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.contactPersonEmail }}</p>
        </div>

        <div class="ro-field" *ngIf="clientOrder.contactPersonPhone">
          <p class="label">
            <mat-icon matSuffix>phone</mat-icon>
            <span>{{ 'ORDER.CONTACT_PHONE' | translate }}</span>
          </p>
          <p class="value">{{ clientOrder.contactPersonPhone }}</p>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>calendar_view_day</mat-icon>
        <span class="tab-label">{{ 'ORDER.TIMELINE' | translate | uppercase }}</span>
      </ng-template>

      <div class="tab-content">
        <mat-stepper #stepper orientation="vertical" [linear]="true" [selectedIndex]="currentStep">
          <ng-template matStepperIcon="done">
            <mat-icon>check</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="number">
            <mat-icon>more_vert</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="edit">
            <mat-icon>circle</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="error">
            <mat-icon>clear</mat-icon>
          </ng-template>

          <mat-step [completed]="false" [state]="getState(0, !!clientOrder.createdAt)" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.CREATED_AT' | translate }}
            </ng-template>
            <div>
              {{ clientOrder.createdAt * 1000 | date:'dd.MM.yyyy HH:mm:ss' }}
            </div>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(1, dateNotNull(clientOrder.bookingDate))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.BOOKING_DATE' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.bookingDate)">
              {{ clientOrder.bookingDate | date:'dd.MM.yyyy HH:mm:ss' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.bookingDate)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(2, dateNotNull(clientOrder.preferredDate))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.PREFERRED_DATE' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.preferredDate)">
              {{ clientOrder.preferredDate | date:'dd.MM.yyyy' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.preferredDate)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(3, dateNotNull(clientOrder.flightDateBegin))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.FLIGHT_BEGIN' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.flightDateBegin)">
              {{ clientOrder.flightDateBegin | date:'dd.MM.yyyy' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.flightDateBegin)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(4, dateNotNull(clientOrder.flightDateEnd))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.FLIGHT_END' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.flightDateEnd)">
              {{ clientOrder.flightDateEnd | date:'dd.MM.yyyy' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.flightDateEnd)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(5, dateNotNull(clientOrder.deadline))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.DEADLINE' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.deadline)">
              {{ clientOrder.deadline | date:'dd.MM.yyyy' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.deadline)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>

          <mat-step [completed]="false" [state]="getState(6, dateNotNull(clientOrder.verificationDate))" [editable]="false">
            <ng-template matStepLabel>
              {{ 'ORDER.VERIFICATION_DATE' | translate }}
            </ng-template>
            <div *ngIf="dateNotNull(clientOrder.verificationDate)">
              {{ clientOrder.verificationDate | date:'dd.MM.yyyy' }}
            </div>
            <i *ngIf="!dateNotNull(clientOrder.verificationDate)">
              {{ 'ORDER.NONE' | translate }}
            </i>
          </mat-step>
        </mat-stepper>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-tab-group *ngIf="pilotOrder" mat-stretch-tabs>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>info</mat-icon>
          <span class="tab-label">{{ 'ORDER.INFO' | translate }}</span>
        </ng-template>

        <div class="tab-content tab-values">
          <div class="ro-field" title="ID">
            <strong>FF{{ pilotOrder.id }}</strong>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.address">
            <p class="label">
              <mat-icon matSuffix>location_on</mat-icon>
              <span>{{ 'ADDRESS' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.address }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.netPrice">
            <p class="label">
              <mat-icon matSuffix>label</mat-icon>
              <span>{{ 'ORDER.PRICE_NET' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.netPrice | number : '1.2-2' }} {{ pilotOrder.priceCurrency }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.status">
            <p class="label">
              <mat-icon matSuffix>traffic</mat-icon>
              <span>{{ 'ORDER.STATUS' | translate }}</span>
            </p>
            <p class="value">{{ 'ORDER.STATES.' + pilotOrder.status.toUpperCase() | translate }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.notes">
            <p class="label">
              <mat-icon matSuffix>feedback</mat-icon>
              <span>{{ 'ORDER.COMMENT' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.notes }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.contactPerson">
            <p class="label">
              <mat-icon matSuffix>person_outline</mat-icon>
              <span>{{ 'ORDER.CONTACT' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.contactPerson }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.contactPersonEmail">
            <p class="label">
              <mat-icon matSuffix>email</mat-icon>
              <span>{{ 'ORDER.CONTACT_EMAIL' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.contactPersonEmail }}</p>
          </div>

          <div class="ro-field" *ngIf="pilotOrder.contactPersonPhone">
            <p class="label">
              <mat-icon matSuffix>phone</mat-icon>
              <span>{{ 'ORDER.CONTACT_PHONE' | translate }}</span>
            </p>
            <p class="value">{{ pilotOrder.contactPersonPhone }}</p>
          </div>

          <div class="ro-field" *ngIf="dateNotNull(pilotOrder.flightDateBegin) && dateNotNull(pilotOrder.flightDateEnd)">
            <p class="label">
              <mat-icon matSuffix>calendar_view_day</mat-icon>
              <span>{{ 'ORDER.FLIGHT_DATE' | translate }}</span>
            </p>
            <p class="value" *ngIf="pilotOrder.flightDateBegin === pilotOrder.flightDateEnd">
              {{ pilotOrder.flightDateBegin | date:'dd.MM.yyyy' }}
            </p>
            <p class="value" *ngIf="pilotOrder.flightDateBegin !== pilotOrder.flightDateEnd">
              {{ pilotOrder.flightDateBegin | date:'dd.MM.yyyy' }} - {{ pilotOrder.flightDateEnd | date:'dd.MM.yyyy' }}
            </p>
          </div>

          <div class="ro-field" *ngIf="dateNotNull(pilotOrder.preferredDate)">
            <p class="label">
              <mat-icon matSuffix>calendar_view_day</mat-icon>
              <span>{{'ORDER.PREFERRED_DATE' | translate}}</span>
            </p>
            <p class="value">{{ pilotOrder.preferredDate | date:'dd.MM.yyyy' }}</p>
          </div>

          <div class="ro-field">
            <p class="label">
              <mat-icon matSuffix>description</mat-icon>
              <span>{{ 'ORDER.JOB_DESCRIPTION' | translate }}</span>
            </p>
            <p class="value" [innerHTML]="getDescriptionHtml()"></p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
</section>
