import { AnnotationTypesObj } from "src/app/shared/annotations/annotation-types";
import { Inspection, PVModule } from "../model/pv-module.model";

export function getAnnotationType(annotation: string, language: string): string {
  if (annotation?.includes('-')) {
    const annotationParts = annotation.split('-');
    try {
      return AnnotationTypesObj[annotationParts[0]][annotationParts[1]][language];
    } catch (e) {
      console.error(e);
      return 'noFeature';
    }
  } else {
    try {
      return AnnotationTypesObj['old'][annotation][language];
    } catch (e) {
      console.error(e);
      return 'noFeature';
    }
  }
}

export function  mercatorToWGS84(mercator) {
  let lon = (mercator[0] / 20037508.34) * 180;
  let lat = (mercator[1] / 20037508.34) * 180;
  lat = (180 / Math.PI) * (2 * Math.atan(Math.exp(lat * (Math.PI / 180))) - (Math.PI / 2));
  return [lon, lat];
}

export function hexToRgb(hex: string): [number, number, number] {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
}

export function deg2rad(deg: number): number {
  return deg * Math.PI / 180;
}

export function getSeverityLabel(score: number): string {
  if (score >= 100) return 'Urgent';
  if (score >= 80) return 'Critical';
  if (score >= 60) return 'Important';
  if (score >= 40) return 'Medium';
  if (score >= 20) return 'Low';
  return 'Advisory Note';
}

export function toValidNumber(value: any): number | undefined {
  if (value === undefined || value === null) return undefined;
  if (typeof value === 'number') return value;
  if (typeof value === 'string' && /^[\d.]+$/.test(value.trim())) {
    return Number(value);
  }
  return undefined;
}

export function getActiveInspection(module: PVModule, activeOrderId: string): Inspection | undefined {
  return module.inspections?.find(i => i.orderId === activeOrderId);
}

export function deepMerge(target: any, source: any) {
  Object.keys(source).forEach((key) => {
    if (typeof source[key] === 'object' && source[key] !== null) {
      if (!target[key] || typeof target[key] !== 'object') {
        target[key] = {};
      }
      this.deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  });
}