<div cdkDrag class="mark-annotation-dialog-container">
  <div class="sidenav-header" cdkDragHandle>
    <div class="left">
      <span class="title">{{ 'FILE.ANNOTATIONS.NEW_ANNOTATIONS' | translate }}</span>
    </div>
    <div class="right">
      <mat-icon class="material-icons-outlined" (click)="close()">close</mat-icon>
    </div>
  </div>

  <div class="newComment">
    <ng-form [formGroup]="annotationForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'SIDEBAR.ANNOTATIONS.FEATURE_LABEL' | translate }}</mat-label>
        <mat-select formControlName="feature" [disableOptionCentering]="true">
          <mat-optgroup *ngFor="let type of annotationTypes | keyvalue"
            [label]="'ANNOTATION.INSPECTION.ASSET_TYPE.' + type.key | translate">
            <mat-option *ngFor="let annotationType of type.value" [value]="type.key+'-'+annotationType.id">
              {{ annotationType[currentLang] }}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup [label]="'ANNOTATION.INSPECTION.ASSET_TYPE.general' | translate">
            <mat-option *ngFor="let annotationType of annotationTypesGeneral" [value]="'general-'+annotationType.id">
              {{ annotationType[currentLang] }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <div class="selection">
        <div [class.selected]="stateDimension.value === 20" (click)="selectDimension(20)">
          <span class="circle advisory"></span>
          <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.ADVISORY' | translate }}</div>
        </div>
        <div [class.selected]="stateDimension.value === 40" (click)="selectDimension(40)">
          <span class="circle low"></span>
          <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.LOW' | translate }}</div>
        </div>
        <div [class.selected]="stateDimension.value === 60" (click)="selectDimension(60)">
          <span class="circle middle"></span>
          <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.MIDDLE' | translate }}</div>
        </div>
        <div [class.selected]="stateDimension.value === 80" (click)="selectDimension(80)">
          <span class="circle high"></span>
          <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.HIGH' | translate }}</div>
        </div>
        <div [class.selected]="stateDimension.value === 100" (click)="selectDimension(100)">
          <span class="circle urgent"></span>
          <div>{{ 'SIDEBAR.ANNOTATIONS.FEATURE.URGENT' | translate }}</div>
        </div>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'SIDEBAR.ANNOTATIONS.COMMENT' | translate }}</mat-label>
        <textarea cdkTextareaAutosize formControlName="comment" matInput></textarea>
      </mat-form-field>

      <div class="actions">
        <button mat-button
        (click)="createNewAnnotation()"
        [disabled]="!annotationForm.valid">
          <mat-icon class="rtl-mirror">send</mat-icon> {{ 'SAVE' | translate }}
        </button>
        <button mat-button (click)="close()">{{ 'CANCEL' | translate }}</button>
      </div>
    </ng-form>
  </div>
</div>
